import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, Grid, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, memo, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import BXModal from "src/components/BXUI/Modal";
import { setViewBuilder } from "src/features/builder/builderSlice";
import normalizeFormBuilder from "src/features/builder/normalizeFormBuilder";
import { selectAllComponentsArray } from "src/features/builder/selectors";
import store from "src/store/store";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import ImportIcons from "../../../../assets/images/icons/Import.svg";
import UploadIcons from "../../../../assets/images/icons/Upload Minimalistic.svg";
import CreateTemplateModal from "../ManageTemplates/CreateTemplateModal";
import TemplatesTable from "../ManageTemplates/TemplatesTable";
import OASSelector from "../OASSelector";
import { LayoutToggleButtons } from "./components/LayoutToggleButtons";
import { changeChildren, recursiveRemove } from "./utils";

const denormalizeFormBuilder = () => {
  const normalizedState = selectAllComponentsArray(store.getState());

  return normalizedState?.filter((item: any) => !item.parentId).map(changeChildren);
};

const ViewBuilderHeader: FC<any> = ({
  handleBackButton,
  templateData,
  isEditingTemplate,
  handleChangeLayout,
  template,
  addTemplate,
  handleBuilderSave,
  isHistory,
  isSaving,
  viewName,
  appBuilderMode,
}) => {
  const dispatch = useDispatch();
  const pathParams = useParams();
  const { templateId } = pathParams;

  const [openTemplateList, setOpenTemplateList] = useState(false);
  const [openExportTemplate, setOpenExportTemplate] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [isIconRotated, setIsIconRotated] = useState(false);
  const [hasLayoutReset, setHasLayoutReset] = useState<boolean>(false);
  const layoutBreak = useSelector((state: any) => state.builder.layoutBreak);

  const { saveAsTemplate } = useBXBuilderContext();

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsIconRotated(!isIconRotated);

    if (!isIconRotated) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsIconRotated(!isIconRotated);
  };

  const handleSelectOption = (option: any) => {
    handleCloseMenu();
    if (option === "Upload") {
      setOpenExportTemplate(true);
      setIsIconRotated(!isIconRotated);
    } else if (option === "Import") {
      setOpenTemplateList(true);
      setIsIconRotated(!isIconRotated);
    }
    handleCloseMenu();
  };

  const handleSynchronizeLayout = () => {
    const currentLayoutBreakpoint = layoutBreak;

    const builderElements: any[] = selectAllComponentsArray(store.getState());
    const elements = _.cloneDeep(builderElements);

    elements.forEach(el => recursiveRemove(el, currentLayoutBreakpoint));

    setViewBuilder({ builderElements: elements });

    // setHasLayoutReset(prev => !prev);
  };

  const url = window.location.pathname;
  let defaultType;
  if (appBuilderMode) {
    defaultType = "APPLICATION";
  } else {
    if (url.startsWith("/buildx/page-builder/")) {
      defaultType = "PAGE";
    } else {
      defaultType = "VIEW";
    }
  }

  const { mutateAsync: saveTemplate, isLoading } = useMutation({ mutationFn: (d: any) => saveAsTemplate(d, templateId) });

  async function onUpdateTemplate(data: any) {
    try {
      const { isDynamicHeight, isFlexCanvasEnabled, isCanvasFullHeight } = store.getState().builder;
      await saveTemplate({
        ...data,
        config: {
          formBuilder: denormalizeFormBuilder(),
          dynamicHeight: Boolean(isDynamicHeight),
          flexCanvas: Boolean(isFlexCanvasEnabled),
          canvasFullHeight: Boolean(isCanvasFullHeight),
        },
      });
      enqueueSnackbarRef?.("Posted Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbarRef?.("Save Failed Due To missing Component Key or Id", {
        variant: "error",
      });
    }
  }

  async function onExportTemplate(data: any, _: any) {
    try {
      const { isDynamicHeight, isFlexCanvasEnabled, isCanvasFullHeight } = store.getState().builder;
      await saveTemplate({
        type: data?.type,
        name: data?.name,
        visibility: data?.visibility,
        config: {
          formBuilder: denormalizeFormBuilder(),
          dynamicHeight: Boolean(isDynamicHeight),
          flexCanvas: Boolean(isFlexCanvasEnabled),
          canvasFullHeight: Boolean(isCanvasFullHeight),
        },
      });
      enqueueSnackbarRef?.("Exported Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbarRef?.("Export Failed due to missing Component Key or ID", {
        variant: "error",
      });
    }
  }

  return (
    <>
      <LoadingButton loading={isLoading} variant='outlined' onClick={handleBackButton}>
        {isEditingTemplate ? "Back" : "Back to config"}
      </LoadingButton>
      {!isHistory && (
        <>
          {isEditingTemplate ? (
            <LoadingButton
              variant='contained'
              loading={isSaving}
              onClick={() => {
                onUpdateTemplate(templateData?.data);
              }}
            >
              Update Template
            </LoadingButton>
          ) : (
            <LoadingButton variant='contained' loading={isSaving} onClick={handleBuilderSave}>
              Save
            </LoadingButton>
          )}
        </>
      )}
      {!isHistory && (
        <>
          <Button
            onClick={event => {
              handleOpenMenu(event);
            }}
            aria-controls='template-menu'
            aria-haspopup='true'
            endIcon={
              <ArrowDropUpIcon
                style={{
                  fontSize: 28,
                  transform: isIconRotated ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            }
          >
            Template
          </Button>
          <Menu id='template-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {!isEditingTemplate && (
              <MenuItem onClick={() => handleSelectOption("Upload")}>
                <ListItemIcon>
                  <img src={UploadIcons} alt='Upload Icon' width='24' height='24' />
                </ListItemIcon>
                Export
              </MenuItem>
            )}
            <MenuItem onClick={() => handleSelectOption("Import")}>
              <ListItemIcon>
                <img src={ImportIcons} alt='Import Icon' width='24' height='24' />
              </ListItemIcon>
              Import
            </MenuItem>
          </Menu>
        </>
      )}
      <LayoutToggleButtons activeLayout={layoutBreak} onLayoutChange={handleChangeLayout} />

      {!isHistory && (
        <Grid display='flex' item xs={12} alignItems='center' gap={"1px"}>
          <OASSelector
            swaggerProps={{
              type: "form-builder",
              onlyModels: true,
              formBuilder: denormalizeFormBuilder(),
              fillFormBuilder: true,
              onSuccess: (values: any) => {
                const normalized = normalizeFormBuilder(values.fromBuilder) as any;
                const formBuilderArray = normalized?.entities ? Object.values(normalized.entities) : [];

                dispatch(setViewBuilder({ builderElements: formBuilderArray }));
              },
            }}
          />
        </Grid>
      )}

      {!isHistory && (
        <>
          <BXModal
            title={"Import template"}
            open={!!openTemplateList}
            onClose={() => {
              setOpenTemplateList(false);
            }}
          >
            <TemplatesTable
              isImport={true}
              setOpenTemplateList={setOpenTemplateList}
              setSelectedRow={setSelectedRow}
              setOpenReplaceModal={setOpenReplaceModal}
              addTemplate={addTemplate}
              templateId={template}
              defaultType={defaultType}
            />
          </BXModal>
          <Dialog
            open={openReplaceModal}
            onClose={() => setOpenReplaceModal(false)}
            closeAfterTransition
            keepMounted={false}
            BackdropProps={{
              timeout: 500,
            }}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle display={"flex"} alignItems='center'>
              <Box flex={1} display={"flex"} alignItems='center'>
                <Typography marginInlineStart={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
                  Do you want to merge or replace?
                </Typography>
              </Box>
              <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => setOpenReplaceModal(false)}>
                <IconX />
              </Box>
            </DialogTitle>
            <Divider />

            <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
              <Button
                autoFocus
                variant='contained'
                onClick={() => {
                  addTemplate(selectedRow, false);
                  setOpenReplaceModal(false);
                }}
              >
                Merge
              </Button>
              <Button
                variant='outlined'
                autoFocus
                onClick={() => {
                  addTemplate(selectedRow, true);
                  setOpenReplaceModal(false);
                }}
              >
                Replace
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {!isEditingTemplate && (
        <>
          <CreateTemplateModal
            open={openExportTemplate}
            isExport={true}
            templateID={template}
            defaultVisibility={"PUBLIC"}
            defaultType={defaultType}
            onSubmit={onExportTemplate}
            onClose={() => {
              setOpenExportTemplate(false);
            }}
          />
        </>
      )}

      {layoutBreak !== "lg" && (
        <Button variant='outlined' onClick={handleSynchronizeLayout}>
          Synchronize Layout
        </Button>
      )}
    </>
  );
};

export default memo(ViewBuilderHeader);
