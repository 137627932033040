import { normalize } from "normalizr";
import { component } from "./schemas";

const normalizeFormBuilder = formBuilder => {
  // Assign unique IDs if not present
  if (!formBuilder) return;
  const assignIds = (items, parentId = null) => {
    return items?.map(item => {
      const id = item.id;
      const children = item.children ? assignIds(item.children, id) : [];
      return {
        ...item,
        id,
        parentId,
        children,
      };
    });
  };

  const dataWithIds = assignIds(formBuilder);

  // Normalize the data
  const normalizedData = normalize(dataWithIds, [component]);

  // Extract entities and top-level IDs
  const { entities, result } = normalizedData;

  return {
    entities: entities.components,
    result, // Array of top-level component IDs
  };
};

export default normalizeFormBuilder;
