import { Box, BoxProps, Divider, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { IconBackspace } from "@tabler/icons-react";
import { NestedMenuItem } from "mui-nested-menu";
import React from "react";
import { v4 as uuid } from "uuid";
import CustomContainerImage from "../../../../../assets/images/icons/custom.svg";
import GridContainerImage from "../../../../../assets/images/icons/GridContainer.svg";
import FlexContainerImage from "../../../../../assets/images/icons/rectangle.svg";
import { ComponentItemType } from "../types";
import { ActionPopoverProps } from "./TreeTypes";

const ActionPopover: React.FC<ActionPopoverProps> = ({
  onClose,
  open,
  handleDeleteItem,
  handleCopyItems,
  activeComponent,
  view,
  handlePasteItems,
  selectedItemsId,
  contextMenuPosition,
  setView,
}) => {
  const handleDelete = () => {
    if (selectedItemsId?.length > 0) {
      if (handleDeleteItem) {
        handleDeleteItem(selectedItemsId);
      }
    } else if (activeComponent) {
      if (handleDeleteItem) {
        handleDeleteItem([activeComponent?.id]);
      }
    }
    onClose();
  };

  const handleCopy = () => {
    if (handleCopyItems) {
      const activeComponentId = activeComponent?.id;
      handleCopyItems(activeComponentId, selectedItemsId);
    }
    onClose();
  };

  const handlePaste = () => {
    if (handlePasteItems) {
      handlePasteItems();
    }
    onClose();
  };

  const getRandomValue = (min, max) => Math.random() * (max - min) + min;

  const findSelectedItemsRecursively = (elements, selectedItemsId) => {
    let foundItems: any = [];

    elements?.forEach((element: any) => {
      if (selectedItemsId?.includes(element?.id)) {
        foundItems?.push(element);
      }

      if (element.children && element.children.length > 0) {
        const foundInChildren = findSelectedItemsRecursively(element.children, selectedItemsId);
        foundItems = foundItems.concat(foundInChildren);
      }
    });

    return foundItems;
  };

  const findParentComponent = (elements, selectedItemsId) => {
    for (const element of elements) {
      if (element.children && element.children.some(child => selectedItemsId.includes(child.id))) {
        return element;
      }

      if (element.children) {
        const parent = findParentComponent(element.children, selectedItemsId);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  };

  const getLevel = (element, elements) => {
    let level = 0;
    let current = element;
    while (current) {
      const parent = findParentComponent(elements, [current.id]);
      if (parent) {
        level += 1;
        current = parent;
      } else {
        break;
      }
    }
    return level;
  };

  const areAllSelectedItemsAtSameLevel = (elements, selectedItemsId) => {
    const selectedItems = findSelectedItemsRecursively(elements, selectedItemsId);
    if (selectedItems?.length === 0) return false;

    const levels = selectedItems?.map(item => getLevel(item, elements));
    return levels.every(level => level === levels[0]);
  };

  const handlePlaceInContainer = (containerType: string) => {
    const newId = uuid();

    const images = {
      FlexContainer: FlexContainerImage,
      GridContainer: GridContainerImage,
      CustomContainer: CustomContainerImage,
    };

    const typeMappings = {
      FlexContainer: ComponentItemType.FlexContainer,
      GridContainer: ComponentItemType.GridContainer,
      CustomContainer: ComponentItemType.CustomContainer,
    };

    const randomWidthPx = getRandomValue(100, 300);
    const randomHeightPx = getRandomValue(50, 200);
    const randomTop = getRandomValue(50, 500);
    const randomLeft = getRandomValue(50, 500);

    const containerParent = {
      id: newId,
      type: typeMappings[containerType] || ComponentItemType.FlexContainer,
      props: {
        sx: {
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          padding: "10px",
          flexDirection: "column",
        },
        id: `${containerType}-${newId}`,
        key: `${containerType}-${newId}`,
        testId: "",
      } as BoxProps,
      config: {
        defaultWidth: 300,
        defaultHeight: 200,
        minWidth: 0,
        minHeight: 0,
        fixedWidth: true,
        isPercentageHeight: false,
        sideMenuSx: {
          border: "1px dashed #57585c",
          minHeight: "100px",
        },
        placeholderConfig: {
          title: "Flex",
          image: images[containerType] || FlexContainerImage,
          group: "container",
        },
        parent: null,
        widthPx: {
          xs: 300,
          lg: 300,
        },
        heightPx: {
          xs: 200,
          lg: 200,
        },
        widthPercentage: {
          xs: `${(randomWidthPx / 1182) * 100}%`,
          lg: `${(randomWidthPx / 1182) * 100}%`,
        },
        heightPercentage: {
          xs: `${(randomHeightPx / 485) * 100}%`,
          lg: `${(randomHeightPx / 485) * 100}%`,
        },
      },
      top: {
        xs: randomTop,
        lg: randomTop,
      },
      left: {
        xs: randomLeft,
        lg: randomLeft,
      },
      leftPercentage: {
        xs: `${(randomLeft / 1182) * 100}%`,
        lg: `${(randomLeft / 1182) * 100}%`,
      },
      index: 1,
      boxPosition: {
        width: 1182,
        height: 485,
        y: randomTop,
        x: randomLeft,
      },
    };

    setView((prev: any) => {
      let selectedItems = findSelectedItemsRecursively(prev?.dataSource?.formBuilder, selectedItemsId);

      if (selectedItems.length === 0 && activeComponent) {
        selectedItems = findSelectedItemsRecursively(prev?.dataSource?.formBuilder, [activeComponent.id]);
      }

      if (!selectedItems || selectedItems.length === 0) {
        return prev;
      }

      const parentComponent = findParentComponent(
        prev?.dataSource?.formBuilder,
        selectedItemsId?.length === 0 ? activeComponent?.id : selectedItemsId
      );
      const idsToRemove = selectedItemsId?.length > 0 ? selectedItemsId : activeComponent ? [activeComponent.id] : [];

      if (parentComponent) {
        parentComponent.children = parentComponent.children.filter((el: any) => !idsToRemove.includes(el.id));
        parentComponent.children.push({
          ...containerParent,
          children: selectedItems.map((el: any) => ({
            ...el,
            config: {
              ...el?.config,
              parent: { id: newId, type: containerType },
            },
          })),
        });
      } else {
        const filteredFormBuilder = prev?.dataSource?.formBuilder?.filter((el: any) => !idsToRemove.includes(el.id));
        return {
          ...prev,
          dataSource: {
            ...prev?.dataSource,
            formBuilder: [
              ...filteredFormBuilder,
              {
                ...containerParent,
                children: selectedItems.map((el: any) => ({
                  ...el,
                  config: {
                    ...el?.config,
                    parent: { id: newId, type: containerType },
                  },
                })),
              },
            ],
          },
        };
      }
      return { ...prev };
    });

    onClose();
  };

  const actionMenuItems = [
    { label: "Copy", shortcut: "Ctrl + C", action: handleCopy },
    { label: "Paste", shortcut: "Ctrl + V", action: handlePaste },
    {
      label: "Delete",
      shortcut: "",
      action: handleDelete,
      icon: <IconBackspace width={18} height={18} />,
      divider: true,
    },
  ];

  const nestedActionMenuItems = [
    { label: "Custom Container", type: "CustomContainer" },
    { label: "Grid Container", type: "GridContainer" },
    { label: "Flex Container", type: "FlexContainer" },
  ];

  const getNestedActionMenuItems = (actionMenuItems: Array<any>, name: string) => {
    return (
      <NestedMenuItem parentMenuOpen={true} label={name}>
        {actionMenuItems &&
          actionMenuItems.map((option, index) =>
            option && option.items && option.items.length > 0 ? (
              getNestedActionMenuItems(option.items, option.name)
            ) : (
              <MenuItem key={index} onClick={() => handlePlaceInContainer(option.type)}>
                {option.label}
              </MenuItem>
            )
          )}
      </NestedMenuItem>
    );
  };

  return (
    <>
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={null}
        sx={{
          position: "fixed",
          top: contextMenuPosition?.top ?? 0,
          left: contextMenuPosition?.left ?? 0,
          transform: "none",
        }}
      >
        <Stack spacing={1} sx={{ padding: "4px" }}>
          {actionMenuItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.divider && <Divider />}
              <MenuItem onClick={item.action} sx={{ marginBottom: item.divider ? "8px" : "0px" }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: "100%" }}>
                  <Typography sx={{ marginRight: 10 }}>{item.label}</Typography>
                  <Stack direction='row' alignItems='center'>
                    {item.shortcut && <Typography sx={{ opacity: 0.6, fontSize: "10px" }}>{item.shortcut}</Typography>}
                    {item.icon && (
                      <>
                        <Box sx={{ opacity: 0.6 }}>{item.icon}</Box>
                      </>
                    )}
                  </Stack>
                </Stack>
              </MenuItem>
            </React.Fragment>
          ))}
          {areAllSelectedItemsAtSameLevel(
            view?.dataSource?.formBuilder,
            selectedItemsId?.length === 0 ? activeComponent?.id : selectedItemsId
          ) && <React.Fragment>{getNestedActionMenuItems(nestedActionMenuItems, "Place in Container")}</React.Fragment>}
        </Stack>
      </Popover>
    </>
  );
};

export default ActionPopover;
