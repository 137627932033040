// selectors.js
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

// Select all components
export const selectAllComponents = state => state.builder.entities;

//Select all Ids
export const selectAllIds = state => state.builder.ids;

// Select all components values
export const selectAllComponentsArray = state => Object.values(state.builder.entities);

// Select top-level component IDs (parentId === null)
export const selectTopLevelComponents = createSelector([selectAllComponents], entities =>
  Object.values(entities)
    .filter((component: any) => component.parentId === null)
    .map((component: any) => component.id)
);

// Select component by ID
export const selectComponentById = (state, id) => state.builder?.entities?.[id] || null;

// Select component property by path
export const selectComponentPropertyByPath = (state, id, propertyPath) => {
  const component = state.builder?.entities?.[id] || null;
  return _.get(component, propertyPath);
};

// Create a selector to get the full item object by component ID
export const selectFullComponentItemById = componentId => createSelector([selectAllComponents], entities => entities[componentId]);

export const selectComponentsByIds = (state, ids) => ids.map(id => selectComponentById(state, id));

export const selectComponentParent = (state, id) => state.builder?.entities?.[id].parentId;

export const selectBuilderSettings = state => {
  const { entities, ...builderSettings } = state.builder;
  return builderSettings;
};
