import { FC, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { setViewBuilder } from "src/features/builder/builderSlice";
import normalizeFormBuilder from "src/features/builder/normalizeFormBuilder";
import { ILayout } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { addContainerCanvas, preprocessView } from "./utils";

interface FormBuilderSideEffectProps {
  pageBuilderMode?: boolean;
  appBuilderMode?: boolean;
  params?: any;
  layout?: ILayout;
  page?: BXPageType;
}
const FormBuilderSideEffect: FC<FormBuilderSideEffectProps> = ({ params, pageBuilderMode, appBuilderMode, layout, page }) => {
  const paramsValues = useParams();
  const { appId, collectionId, pageId, viewId, templateId, historyId, layoutId } = params || paramsValues;
  // const { currentApp, selectedAppId, allowedApps, stripeEndpoints, setStripeEndpoints } = useBXContext();
  const { apps, editView, getTemplateById, getTemplateByHistoryId } = useBXBuilderContext();
  const hasAddedToHistory = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const app = apps?.find(app => app?.id === appId);
    const layout = app?.templateConfig?.layouts?.find(layout => layout?.id == layoutId);
    const collection = app?.templateConfig?.collections?.find(collection => collection?.id == collectionId);
    const page = collection?.pages?.find(page => page?.id === pageId);
    let view = page?.views?.find(view => view?.id === viewId);

    const viewConfig = preprocessView(app, collectionId, pageId, viewId, layoutId, pageBuilderMode, appBuilderMode);

    if (apps?.length && appId && collectionId && pageId && viewId) {
      if (view) {
        const viewWithParents = {
          ...view,
          dataSource: {
            ...view?.dataSource,
            formBuilder: view?.dataSource?.formBuilder,
            formBuilderConfig: {
              ...view?.dataSource?.formBuilderConfig,
              isSyncTreeWithCanvasEnabled: true,
            },
          },
        };

        const normalized = normalizeFormBuilder(viewWithParents?.dataSource?.formBuilder) as any;

        // Prepare the formBuilder array for setAll
        const formBuilderArray = normalized?.entities ? Object.values(normalized.entities) : [];
        const shouldAddToHistory = !!app && !hasAddedToHistory.current;

        //Process the current view configuration and store it

        dispatch(
          setViewBuilder({
            builderElements: formBuilderArray,
            isFlexCanvasEnabled: view?.dataSource?.formBuilderConfig?.isFlexCanvasEnabled,
            isDynamicHeight: view?.dataSource?.formBuilderConfig?.isDynamicHeight,
            isCanvasFullHeight: view?.dataSource?.formBuilderConfig?.isCanvasFullHeight,
            isStepperEnabled: view?.dataSource?.formBuilderConfig?.isStepperEnabled,
            isCanvasGrid: view?.dataSource?.formBuilderConfig?.isCanvasGrid,
            varsConfig: view?.dataSource?.varsConfig,
            viewConfiguration: viewConfig,
            addToHistory: shouldAddToHistory,
          })
        );
        if (shouldAddToHistory) {
          hasAddedToHistory.current = true;
          const customContainerCanvas = formBuilderArray.filter((el: any) => el.isCustomCanvas);

          if (customContainerCanvas?.length == 0) {
            addContainerCanvas(formBuilderArray);
          }
        }
      }
    }

    if (pageBuilderMode) {
      const viewWithParents = {
        ...page,
        type: "form-builder",
        dataSource: {
          ...page?.dataSource,
          formBuilder: page?.dataSource?.formBuilder,
          formBuilderConfig: {
            ...page?.dataSource?.formBuilderConfig,
            isSyncTreeWithCanvasEnabled: true,
          },
        },
      };
      const normalized = normalizeFormBuilder(viewWithParents?.dataSource?.formBuilder) as any;
      const app = apps?.find(app => app?.id === appId);

      // Prepare the formBuilder array for setAll
      const formBuilderArray = normalized?.entities ? Object.values(normalized.entities) : [];
      dispatch(
        setViewBuilder({
          builderElements: formBuilderArray,
          viewConfiguration: viewConfig,
        })
      );
      const customContainerCanvas = formBuilderArray.filter((el: any) => el.isCustomCanvas);

      if (customContainerCanvas?.length == 0) {
        addContainerCanvas(formBuilderArray);
      }
    }

    if (appBuilderMode) {
      const viewWithParents = {
        ...layout,
        type: "form-builder",
        dataSource: {
          ...layout?.dataSource,
          formBuilder: layout?.dataSource?.formBuilder,
          formBuilderConfig: {
            ...layout?.dataSource?.formBuilderConfig,
            isSyncTreeWithCanvasEnabled: true,
          },
        },
      };
      const normalized = normalizeFormBuilder(viewWithParents?.dataSource?.formBuilder) as any;

      // Prepare the formBuilder array for setAll
      const formBuilderArray = normalized?.entities ? Object.values(normalized.entities) : [];
      dispatch(
        setViewBuilder({
          builderElements: formBuilderArray,
          viewConfiguration: viewConfig,
        })
      );
      const customContainerCanvas = formBuilderArray.filter((el: any) => el.isCustomCanvas);

      if (customContainerCanvas?.length == 0) {
        addContainerCanvas(formBuilderArray);
      }
    }
  }, [apps]);

  return null;
};

export default FormBuilderSideEffect;
