import { Autocomplete, Box, Button, Checkbox, Grid, IconButton, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import _, { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXIcon } from "src/components/BXUI/Icon";
import { updateComponent } from "src/features/builder/builderSlice";
import { selectAllComponentsArray, selectComponentById, selectComponentPropertyByPath } from "src/features/builder/selectors";
import store from "src/store/store";
import DraggableRow from "../AppBuilder/forms/DraggableRow";

const EMPTY_VIEW = { pageReference: "", customPageSelected: false };

interface ViewProps {
  onSwitchChange: (e: any) => void;
  isSelected: boolean;
  onDelete: () => void;
  disabled: boolean;
  control: any;
  name: string;
  stepperId: string;
  showCheckbox: boolean;
  setValue: any;
  stepperKey: string;
}

const Page = ({
  control,
  name,
  onSwitchChange,
  isSelected,
  onDelete,
  disabled,
  showCheckbox,
  stepperId,
  stepperKey,
  setValue,
}: ViewProps) => {
  const [prevComponentId, setPrevComponentId] = useState(null);
  const componentsArray = useSelector(state => selectAllComponentsArray(state));
  const dispatch = useDispatch();

  return (
    <Grid container item spacing={1} alignItems='center' justifyContent='center' minWidth='326px'>
      {showCheckbox && (
        <Grid item xs={1} container alignItems='center' justifyContent='center'>
          <Controller
            name={`${name}.customPageSelected`}
            control={control}
            defaultValue={false}
            render={({ field }) => <Checkbox {...field} checked={field.value} size='small' />}
          />
        </Grid>
      )}
      <Grid item xs={showCheckbox ? 1 : 2} container alignItems='center' justifyContent='center'>
        <BXIcon icon='Menu' height={20} width={20} style={{ cursor: "grab" }} />
      </Grid>

      <Grid item xs={6} container alignItems='center' justifyContent='center'>
        {disabled ? (
          <BXInput
            name={`${name}.pageReference`}
            control={control}
            label='Page reference'
            fullWidth
            disabled
            size='small'
            InputLabelProps={{ style: { fontSize: "12px" } }}
            InputProps={{ style: { fontSize: "12px" } }}
          />
        ) : (
          <Controller
            name={`${name}.componentId`}
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                value={componentsArray.find((component: any) => component?.id === field.value) || null}
                options={componentsArray?.filter((component: any) => stepperId !== component?.id) || []}
                getOptionLabel={(component: any) => component?.props?.key || ""}
                isOptionEqualToValue={(option: any, value) => option?.id === value?.id}
                onChange={(_event, value) => {
                  const componentId = value?.id || null;
                  const componentKey = value?.props?.key;
                  field.onChange(componentId);

                  if (componentId) {
                    const item = selectComponentById(store.getState(), componentId);
                    if (item) {
                      setPrevComponentId(componentId);
                      setValue(`${name}.componentId`, componentId);
                      setValue(`${name}.pageReference`, componentKey);
                      const newItem = {
                        ...item,
                        config: {
                          ...item.config,
                          stepperParent: stepperKey,
                        },
                      };

                      dispatch(
                        updateComponent({
                          id: componentId,
                          changes: newItem,
                        })
                      );
                    }
                  } else if (prevComponentId) {
                    const item = selectComponentById(store.getState(), prevComponentId);
                    if (item) {
                      setPrevComponentId(null);
                      setValue(`${name}.componentId`, null);
                      setValue(`${name}.pageReference`, null);

                      const newItem = {
                        ...item,
                        config: {
                          ...item.config,
                          stepperParent: null,
                        },
                      };

                      dispatch(
                        updateComponent({
                          id: prevComponentId,
                          changes: newItem,
                        })
                      );
                    }
                  }
                }}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Page reference'
                    size='small'
                    fullWidth
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: "12px" },
                    }}
                  />
                )}
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={2} container alignItems='center' justifyContent='center'>
        <Switch checked={isSelected} onChange={e => onSwitchChange(e.target.checked)} size='small' />
      </Grid>
      <Grid item xs={2} container alignItems='center' justifyContent='center'>
        <IconButton onClick={onDelete}>
          <BXIcon icon={"Trash"} width={16} height={16} color={"red"} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

interface ViewsType {
  [groupName: string]: {
    pages: {
      pageReference: string;
      componentId?: string;
      isChildren?: boolean;
      customPageSelected?: boolean;
    }[];
    worksWith?: string;
    enableLooping?: boolean;
    selectedPage?: number | string;
    previewIndex?: number;
  };
}

const Group = ({ control, groupName, setValue, watch, deleteComponent, stepperId, getValues, stepperKey }) => {
  const dispatch = useDispatch();
  const stepperChildren = useSelector(state => selectComponentPropertyByPath(state, stepperId, "children"));

  useEffect(() => {
    if (!stepperChildren) return;

    const updatedPages = (pages || [])
      .filter(page => !page.isChildren || stepperChildren.includes(page.componentId))
      .map(page => {
        if (stepperChildren.includes(page.componentId)) {
          return { ...page, isChildren: true };
        }
        return page;
      });

    stepperChildren.forEach(childId => {
      if (!updatedPages.some(page => page.componentId === childId)) {
        const childItem = selectComponentById(store.getState(), childId);
        if (childItem) {
          updatedPages.push({
            pageReference: childItem.props?.key,
            componentId: childItem.id,
            isChildren: true,
            id: childItem.id,
          });
        }
      }
    });

    setValue(`${groupName}.pages`, updatedPages);
  }, [stepperChildren]);

  const {
    fields: pages,
    append: addView,
    remove,
    move,
  } = useFieldArray<ViewsType>({
    control,
    name: `${groupName}.pages`,
  });

  const setPreviewIndex = index => {
    const prevSelectedPreviewIndex = getValues(`${groupName}.previewIndex`) || 0;
    const prevSelectedPage = getValues(`${groupName}.pages[${prevSelectedPreviewIndex}]`);
    const selectedPage = getValues(`${groupName}.pages[${index}]`);
    if (selectedPage?.componentId) {
      const item = selectComponentById(store.getState(), selectedPage?.componentId);
      if (item) {
        const newItem = {
          ...item,
          config: {
            ...item.config,
            isVisiblePreviewByStepper: true,
          },
        };
        dispatch(
          updateComponent({
            id: selectedPage?.componentId,
            changes: newItem,
          })
        );
      }
    }

    if (prevSelectedPage?.componentId) {
      const item = selectComponentById(store.getState(), prevSelectedPage.componentId);
      if (item) {
        const newItem = {
          ...item,
          config: {
            ...item.config,
            isVisiblePreviewByStepper: false,
          },
        };
        dispatch(
          updateComponent({
            id: prevSelectedPage.componentId,
            changes: newItem,
          })
        );
      }
    }
    setValue(`${groupName}.previewIndex`, index);
  };

  const onPageAdd = () => {
    addView(EMPTY_VIEW);
  };

  const previewIndex = watch(`${groupName}.previewIndex`) || 0;
  const worksWith = watch(`${groupName}.worksWith`) || "currentPage"; // Watch the 'worksWith' field

  return (
    <Grid container item spacing={1} gap={2} minWidth='326px'>
      <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
        <Grid>
          <Typography fontSize='12px'>Enable Looping</Typography>
        </Grid>
        <Grid>
          <BXSwitch name={`${groupName}.enableLooping`} control={control} label={""} size='small' />
        </Grid>
      </Grid>
      <Grid item container xs={12} gap={1}>
        <Grid item xs={12}>
          <BXInput
            name={`${groupName}.selectedPage`}
            control={control}
            label='index or reference'
            fullWidth
            size='small'
            InputLabelProps={{ style: { fontSize: "12px" } }}
            InputProps={{ style: { fontSize: "12px" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={`${groupName}.worksWith`}
            control={control}
            defaultValue='currentPage'
            render={({ field }) => (
              <TextField
                {...field}
                select
                size='small'
                fullWidth
                label='works with'
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              >
                <MenuItem value='currentPage'>Current Page</MenuItem>
                <MenuItem value='allPages'>All Pages</MenuItem>
                <MenuItem value='customPages'>Custom Pages</MenuItem>
              </TextField>
            )}
          />
        </Grid>
      </Grid>

      {pages.map((view, index) => (
        <DraggableRow component={Box} key={view.id} id={view.id} index={index} name='stepper-pages' moveElement={move}>
          <Page
            setValue={setValue}
            control={control}
            stepperId={stepperId}
            name={`${groupName}.pages[${index}]`}
            isSelected={previewIndex === index}
            disabled={!!view?.isChildren}
            stepperKey={stepperKey}
            onSwitchChange={isSelected => {
              if (isSelected) {
                setPreviewIndex(index);
              }
            }}
            onDelete={() => {
              if (view?.componentId) {
                const item = selectComponentById(store.getState(), view?.componentId);
                if (item) {
                  const newItem = {
                    ...item,
                    config: {
                      ...item.config,
                      stepperParent: null,
                    },
                  };

                  dispatch(
                    updateComponent({
                      id: view?.componentId,
                      changes: newItem,
                    })
                  );
                }
              }
              if (previewIndex === index) {
                setPreviewIndex(index === 0 ? 0 : index - 1);
              }
              if (view?.isChildren) {
                deleteComponent(view?.componentId);
              }
              remove(index);
            }}
            showCheckbox={worksWith === "customPages"}
          />
        </DraggableRow>
      ))}
      <Grid item alignItems='center'>
        <Button onClick={() => onPageAdd()} variant='contained' style={{ height: "30px" }}>
          Add Page
        </Button>
      </Grid>
    </Grid>
  );
};

const StepperConfiguration = ({ componentId, deleteComponent }) => {
  const dispatch = useDispatch();
  const stepperGroup = useSelector(state => selectComponentPropertyByPath(state, componentId, "config.stepperGroup"));
  const stepperKey = useSelector(state => selectComponentPropertyByPath(state, componentId, "props.key"));
  const { control, getValues, setValue, watch } = useForm({
    defaultValues: {
      stepperGroup: stepperGroup ?? {
        previewIndex: 0,
        selectedPage: 0,
        pages: [],
        worksWith: "currentPage",
        enableLooping: false,
      },
    },
  });

  useEffect(() => {
    const updateView = debounce(() => {
      const formValues = getValues("stepperGroup");
      const item = selectComponentById(store.getState(), componentId);

      const newItem = {
        ...item,
        config: {
          ...item.config,
          stepperGroup: _.cloneDeep(formValues),
        },
      };

      dispatch(
        updateComponent({
          id: componentId,
          changes: newItem,
        })
      );
    }, 150);

    updateView();

    const subscription = watch(updateView);

    return () => {
      subscription?.unsubscribe();
    };
  }, [watch, getValues]);

  return (
    <Grid container spacing={2} p={2} gap={2} overflow='auto'>
      <Group
        control={control}
        groupName={`stepperGroup`}
        setValue={setValue}
        watch={watch}
        deleteComponent={deleteComponent}
        stepperId={componentId}
        stepperKey={stepperKey}
        getValues={getValues}
      />
    </Grid>
  );
};
export default StepperConfiguration;
