import { IconButton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  IconApps,
  IconCopy,
  IconEditCircle,
  IconFileImport,
  IconLanguage,
  IconLayoutBoardSplit,
  IconPlus,
  IconRefresh,
  IconTrashX,
  IconUsers,
  IconWorld,
} from "@tabler/icons-react";
import axios from "axios";
import update from "immutability-helper";
import _, { debounce } from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import { appVersion } from "src/config";
import { resetBuilderState } from "src/features/builder/builderSlice";
import useAuth from "src/hooks/useAuth";
import { BXApp } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { compressData, decompressData } from "src/utils/services";
import { v4 as uuid } from "uuid";
import { useBXBuilderContext } from "../../../../BXEngine/BXBuilderContext";
import BuilderColumn from "./components/BuilderColumn";
import CollectionColumn from "./components/CollectionColumn";
import LayoutTable from "./components/LayoutTable";
import PageColumn from "./components/PageColumn";
import ViewColumn from "./components/ViewColumn";
import { ConnectAppUsers } from "./forms/ConnectAppUsers";
import { CreateAppForm } from "./forms/CreateAppForm";
import ManageDNSForm from "./forms/ManageDNSForm";
import { ManageLanguages } from "./forms/ManageLanguages";
import MissingIconsModal from "./forms/MissingIconsModal";
import { SyncApp } from "./forms/SyncApp";

const fieldIds: string[] = [
  "id",
  "userInputFormBuilderId",
  "viewBuilderId",
  "viewBuilderHeaderId",
  "componentId",
  "layoutId",
  "customView",
  "emptyStateView",
  "loadingView",
];

const configFields = ["templateConfig", "appConfig", "upTemplateConfig"];

// Custom function to collect all fields that have iconConfig to resolve media url issue when importing/exporting apps
export const collectFieldsConfigs = (data: any) => {
  const result: any[] = [];
  const iconSet = new Set();
  const findIconConfigs = (obj: any) => {
    _.forOwn(obj, (value, key) => {
      if (key === "iconConfig" && _.isObject(value)) {
        const iconConfigArray = _.isArray(value) ? value : [value];
        iconConfigArray.forEach(iconConfig => {
          if (!_.isEmpty(iconConfig) && iconConfig.icon && iconConfig.url && !iconSet.has(iconConfig.icon)) {
            iconSet.add(iconConfig.icon);
            result.push(iconConfig);
          }
        });
      } else if (_.isArray(value)) {
        value.forEach(item => {
          if (_.isObject(item)) findIconConfigs(item);
        });
      } else if (_.isObject(value)) {
        findIconConfigs(value);
      }
    });
  };

  findIconConfigs(data);

  return result;
};

// Custom function to update icon URLs recursively in the app data after importing/exporting apps
const updateIconConfigs = (data: any, responseData: any): any => {
  if (Array.isArray(data)) {
    return data.map(item => updateIconConfigs(item, responseData));
  }

  if (data && typeof data === "object") {
    if (data.iconConfig && data.iconConfig.icon) {
      const iconKey = data.iconConfig.icon;
      const iconData = responseData[iconKey];
      if (iconData) {
        data.iconConfig.url = iconData.url;
        data.iconConfig.visibility = iconData.visibility;
      } else {
        data.iconConfig.url = "";
        data.iconConfig.visibility = "";
      }
    }
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = updateIconConfigs(data[key], responseData);
      }
    }
  }

  return data;
};

const transformKeyToOriginal = (key: string): string => {
  const [type, ...rest] = key.split("_");
  const originalName = rest.join("_");
  const originalKey = `${type}://${originalName}`;
  return originalKey;
};

const collectLinkObjects = (app, missingIconsBack, appId) => {
  const linkObjects = {};

  const findMatchingIcons = (iconKey, collectionId, pageId, viewId, path, componentId) => {
    const matchingIcons = missingIconsBack.filter(icon => icon === iconKey);

    if (matchingIcons.length > 0) {
      matchingIcons.forEach(matchingIcon => {
        linkObjects[matchingIcon] = {
          linkConfig: {
            appId,
            collectionId,
            pageId,
            viewId,
            componentId,
          },
          path,
        };
      });
    }
  };

  const findLinksInConfig = (item, collectionId, pageId, viewId, basePath) => {
    const currentPath = `${basePath}${viewId ? `.views[${viewId}]` : ""}`;

    if (item.config && item.config.actions) {
      item.config.actions.forEach(action => {
        if (action.iconConfig && action.iconConfig.icon) {
          findMatchingIcons(action.iconConfig.icon, collectionId, pageId, viewId, `${currentPath}.config.actions`, null);
        }
      });
    }

    if (item.iconConfig && item.iconConfig.icon) {
      findMatchingIcons(item.iconConfig.icon, collectionId, pageId, viewId, `${basePath}.iconConfig`, null);
    }

    if (item.dataSource && item.dataSource.formBuilder) {
      item.dataSource.formBuilder.forEach(formItem => {
        if (formItem.props && formItem.props.iconConfig && formItem.props.iconConfig.icon) {
          findMatchingIcons(
            formItem.props.iconConfig.icon,
            collectionId,
            pageId,
            viewId,
            `${currentPath}.dataSource.formBuilder`,
            formItem.id
          );
        }
      });
    }

    if (item.children) {
      item.children.forEach(child => {
        findLinksInConfig(child, collectionId, pageId, viewId, `${currentPath}.children`);
      });
    }
  };

  const traverseCollections = collections => {
    collections.forEach(collection => {
      const collectionId = collection.id;
      const collectionPath = `templateConfig.collections[${collections.indexOf(collection)}]`;

      if (collection.pages) {
        collection.pages.forEach((page, pageIndex) => {
          const pageId = page.id;
          const pagePath = `${collectionPath}.pages[${pageIndex}]`;

          if (page.views) {
            page.views.forEach((view, viewIndex) => {
              const viewId = view.id;
              const viewPath = `${pagePath}.views[${viewIndex}]`;
              findLinksInConfig(view, collectionId, pageId, viewId, viewPath);
            });
          }

          findLinksInConfig(page, collectionId, pageId, null, pagePath);

          if (page.children) {
            page.children.forEach(childPage => {
              traverseCollections(childPage);
            });
          }
        });
      }

      if (collection.collections) {
        traverseCollections(collection.collections);
      }
    });
  };

  if (app.templateConfig && app.templateConfig.collections) {
    traverseCollections(app.templateConfig.collections);
  }

  return linkObjects;
};

// Custom function to update icon URLs in the app data after importing/exporting apps
export const handleUpdateFieldsConfigs = async (iconConfigs: any[], app: any, enableChangeAppIcon, selectedApp) => {
  if (!iconConfigs || iconConfigs.length === 0) return;

  const iconDetails = iconConfigs
    .map(config => {
      const match = config.icon.match(/^(.*):\/\/(.+)$/);
      if (match) {
        const type = match[1];
        const name = match[2];
        return { type, name };
      }
      return null;
    })
    .filter(Boolean) as { type: string; name: string }[];

  const iconMap = new Map<string, string>();
  iconDetails.forEach(detail => {
    if (!iconMap.has(detail.name)) {
      iconMap.set(detail.name, detail.type);
    }
  });

  const appId = selectedApp?.id;
  const iconNames = Array.from(iconMap.keys());
  const iconTypes = Array.from(iconMap.values());

  try {
    const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/media/bulk-fetch`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      params: {
        names: iconNames.join(","),
        types: iconTypes.join(","),
        strategy: "summary",
      },
    });

    const transformedResponseData: any = {};
    const missingIconsBackKeys: any = [];
    const missingIconsBack: any = [];

    for (const key in response.data) {
      const originalKey = transformKeyToOriginal(key);
      if (response.data[key] === null) {
        missingIconsBackKeys.push(originalKey);
      } else {
        const [type, name] = key.split("_");
        const transformedKey = `${type}://${name}`;
        transformedResponseData[transformedKey] = response.data[key];
      }
    }

    const missingIconsConfig: any = collectLinkObjects(app, missingIconsBackKeys, appId);
    Object.entries(missingIconsConfig).forEach(([icon, details]: any) => {
      missingIconsBack.push({
        name: icon,
        path: details?.path,
        link: details?.linkConfig,
      });
    });

    if (app.upTemplateConfig) {
      app.upTemplateConfig = updateIconConfigs(app.upTemplateConfig, transformedResponseData);
    }
    if (app.templateConfig) {
      app.templateConfig = updateIconConfigs(app.templateConfig, transformedResponseData);
    }
    if (app.appConfig) {
      app.appConfig = updateIconConfigs(app.appConfig, transformedResponseData);
      if (enableChangeAppIcon) {
        if (app.appConfig.iconConfig) {
          app.icon = app.appConfig.iconConfig.url || "";
        }
      }
    }

    return { app, missingIconsBack };
  } catch (error) {
    return { app, missingIconsBack: [] };
  }
};

export const changeIdFields = (data: any, oldIds: any = {}) => {
  if (_.isObject(data) as any) {
    fieldIds.forEach(field => {
      if (data[field] && !(Number(data[field]) < 1)) {
        if (oldIds[data[field]]) {
          data[field] = oldIds[data[field]];
        } else {
          const generatedId = uuid();
          oldIds[data[field]] = generatedId;
          data[field] = generatedId;
        }
      }
    });
    _.forOwn(data, (value, key) => {
      if (_.isArray(value)) {
        value?.forEach((item, index) => {
          if (_.isObject(item) as any) {
            changeIdFields(data[key][index], oldIds);
          }
        });
      } else if (_.isObject(value)) {
        changeIdFields(data[key], oldIds);
      }
    });
    return data;
  }
};

export const renameKeysOfObject = (data, oldIds) => {
  if (_.isArray(data)) {
    return data.map(item => renameKeysOfObject(item, oldIds));
  }

  if (_.isObject(data)) {
    const newData = {};

    _.forOwn(data, (value, key) => {
      // Determine the new key
      const newKey = oldIds[key] || key;

      // Recursively process nested objects/arrays
      newData[newKey] = renameKeysOfObject(value, oldIds);
    });

    return newData;
  }

  // Return data as is if it's neither an object nor an array
  return data;
};

export const changeIdsOfObject = (data: any, oldIds: any = {}) => {
  // Step 1: Update ID fields
  const updatedData = changeIdFields(_.cloneDeep(data), oldIds);

  // Step 2: Rename object keys based on oldIds
  const finalData = renameKeysOfObject(updatedData, oldIds);

  return finalData;
};

const findCustomComponentIds = (obj: any, ids: any) => {
  if (typeof obj !== "object" || obj === null) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach(item => findCustomComponentIds(item, ids));
  } else {
    Object.keys(obj).forEach(key => {
      if (key === "customComponentId") {
        ids.push(obj[key]);
      } else {
        findCustomComponentIds(obj[key], ids);
      }
    });
  }
};

const replaceComponentIds: any = (obj: any, componentIdsMap: any) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => replaceComponentIds(item, componentIdsMap));
  }

  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === "string" && componentIdsMap.hasOwnProperty(value)) {
        newObj[key] = componentIdsMap[value];
      } else {
        newObj[key] = replaceComponentIds(value, componentIdsMap);
      }
    }
  }
  return newObj;
};

type AppBuilderProps = {
  children?: React.ReactNode;
};

let isUpdateApps = true;

export const AppBuilder: FC<AppBuilderProps> = ({ children }) => {
  const { apps, addApp, reorderApps, deleteApp, editApp } = useBXBuilderContext();
  const {
    currentApp,
    loadingApps,
    logoutOfApp,
    setAppDescriptor,
    setEnvs,
    selectedAppId,
    selectedCollectionId,
    selectedPageId,
    selectedViewId,
    setSelectedViewId,
    setSelectedAppId,
    setSelectedCollectionId,
    setSelectedPageId,
    setIsAdministrationMode,
    isAdministrationMode,
    leftDrawerOpened,
    unprotectedPages,
    setCurrentApp,
  } = useBXContext();

  const { isSuperAdmin } = useAuth();
  const [localApps, setLocalApps] = useState(apps.sort((a, b) => a?.order! - b?.order!));
  const [description, setDescription] = useState<any>();
  const [multiLingual, setMultiLingual] = useState<any>();
  const [isUser, setIsUser] = useState(false);
  const [isSSO, setIsSSO] = useState(false);
  const [isSyncMedia, setIsSyncMedia] = useState<boolean>(false);
  const [isLanguage, setIsLanguage] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [isTrash, setIsTrash] = useState(false);
  const [isConfig, setIsConfig] = useState(false);
  const [isDuplicateAppModalOpen, setIsDuplicateAppModalOpen] = useState<boolean>(false);
  const [isImportAppModalOpen, setIsImportAppModalOpen] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSync, setIsSync] = useState<boolean>(false);
  const [missingIconsState, setMissingIconsState] = useState<string[]>([]);
  const [isMissingIconsModalOpen, setMissingIconsModalOpen] = useState(false);
  const [savedApp, setSavedApp] = useState<any>();

  const [selectedLayoutID, setSelectedLayoutID] = useState<string>("");

  // const [selectedAppId, setSelectedAppId] = useState<any>();
  // const [selectedCollectionId, setSelectedCollectionId] = useState<any>();
  // const [selectedPageId, setSelectedPageId] = useState<any>();
  const [withAppInfo, setWithAppInfo] = useState<any>();
  const [withAuthConfig, setWithAuthConfig] = useState<any>();
  const { palette } = useTheme();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const appId = searchParams.get("appId");
  const collectionId = searchParams.get("collectionId");
  const pageId = searchParams.get("pageId");
  const isLayoutBuilder = searchParams.get("layoutBuilder");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAppSyncSave = async (data: any, selectedApp) => {
    const combinedIconConfigs = [
      ...(data?.templateConfig?.iconConfigCollection || []),
      ...(data?.appConfig?.iconConfigCollection || []),
      ...(data?.upTemplateConfig?.iconConfigCollection || []),
    ];

    let updatedApp;
    if (combinedIconConfigs.length > 0) {
      const { app: updatedAppBack, missingIconsBack }: any = await handleUpdateFieldsConfigs(combinedIconConfigs, data, false, selectedApp);
      updatedApp = updatedAppBack;
      editApp(data.id, {
        ...data,
      } as BXApp);
      setMissingIconsState(missingIconsBack);
      setSavedApp(updatedApp);
      if (missingIconsBack.length > 0) {
        setMissingIconsModalOpen(true);
      }
    } else {
      updatedApp = data;
      editApp(data.id, {
        ...data,
      } as BXApp);
    }
  };

  const handleAppSave = async (data: any, selectedApp) => {
    const combinedIconConfigs = [
      ...(data?.templateConfig?.iconConfigCollection || []),
      ...(data?.appConfig?.iconConfigCollection || []),
      ...(data?.upTemplateConfig?.iconConfigCollection || []),
    ];

    let updatedApp;
    if (combinedIconConfigs.length > 0) {
      const { app: updatedAppBack, missingIconsBack }: any = await handleUpdateFieldsConfigs(combinedIconConfigs, data, true, selectedApp);
      updatedApp = updatedAppBack;
      addApp(updatedApp);
      setMissingIconsState(missingIconsBack);
      setSavedApp(updatedApp);
      if (missingIconsBack.length > 0) {
        setMissingIconsModalOpen(true);
      }
    } else {
      updatedApp = data;
      addApp(updatedApp);
    }
  };

  const handleSyncMediaClick = async (value: any) => {
    const selectedApp = app;
    const combinedIconConfigs = [
      ...(selectedApp?.templateConfig?.iconConfigCollection || []),
      ...(selectedApp?.appConfig?.iconConfigCollection || []),
      ...(selectedApp?.upTemplateConfig?.iconConfigCollection || []),
    ];

    let updatedApp;
    if (combinedIconConfigs.length > 0) {
      const { app: updatedAppBack, missingIconsBack }: any = await handleUpdateFieldsConfigs(
        combinedIconConfigs,
        selectedApp,
        true,
        selectedApp
      );
      updatedApp = updatedAppBack;
      setMissingIconsState(missingIconsBack);
      setSavedApp(updatedApp);
      setDescription(value);
      if (missingIconsBack.length > 0) {
        setMissingIconsModalOpen(true);
      }
    } else {
      updatedApp = data;
      setDescription(value);
    }
  };

  const groupedIcons = missingIconsState.reduce((acc, iconObject: any) => {
    const type = iconObject?.name?.split("://")[0];
    const nameWithoutPrefix = iconObject?.name?.split("://")[1];
    const path = iconObject?.link?.path || iconObject?.path;

    if (!acc[type]) acc[type] = [];

    acc[type].push({
      name: nameWithoutPrefix,
      path,
      link: iconObject.link,
    });

    return acc;
  }, {} as Record<string, { name: string; path: string; link?: any }[]>);

  useEffect(() => {
    dispatch(resetBuilderState());
  }, []);

  const selectDefaultApp = (app?: BXApp) => {
    const defaultApp = app;
    const defaultCollection = defaultApp?.templateConfig?.collections?.[0];
    const defaultPage = defaultCollection?.pages?.[0];
    setSelectedAppId((prev: any) => (!prev ? defaultApp?.id : prev));
    setSelectedCollectionId((prev: any) => (!prev ? defaultCollection?.id : prev));
    setSelectedPageId((prev: any) => (!prev ? defaultPage?.id : prev));
  };
  const { loadApps } = useBXContext();

  useEffect(() => {
    if (appId && collectionId && pageId) {
      setSelectedAppId(appId);
      setSelectedCollectionId(collectionId);
      setSelectedPageId(pageId);
      axiosServices.get("/application/" + appId).then(({ data }) => {
        if (data?.appConfig) {
          data.appConfig = decompressData(data?.appConfig);
        }
        if (data?.templateConfig) {
          data.templateConfig = decompressData(data?.templateConfig);
        }
        setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
      });
    }

    if (appId && isLayoutBuilder) {
      setSelectedAppId(appId);
      axiosServices.get("/application/" + appId).then(({ data }) => {
        if (data?.appConfig) {
          data.appConfig = decompressData(data?.appConfig);
        }
        if (data?.templateConfig) {
          data.templateConfig = decompressData(data?.templateConfig);
        }
        setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
        setDescription(data);
        setSelectedLayoutID(isLayoutBuilder);
        setIsLayoutEdit(true);
        navigate(`/buildx/app?appId=${appId}`);
      });
    }
  }, [loadApps]);

  useEffect(() => {
    axiosServices.get("/admin/env").then(envs => {
      setEnvs(envs.data?.items);
    });
  }, []);

  useEffect(() => {
    if (!isUpdateApps) isUpdateApps = true;
    else setLocalApps(apps.sort((a, b) => a?.order! - b?.order!));

    if (!selectedAppId && !loadingApps && currentApp) {
      selectDefaultApp(currentApp);
      //Append shared collection for the first app if do not have one already
      if (!currentApp?.templateConfig?.collections?.some(col => col.isShared)) {
        editApp(
          currentApp?.id as string,
          currentApp as BXApp,
          () => {},
          () => {},
          true
        );
      }
    }
  }, [currentApp, loadingApps, apps]);

  const moveCard = (dragIndex: any, hoverIndex: number) => {
    return setLocalApps(prev => {
      const apps = update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      });
      apps[hoverIndex] = { ...apps[hoverIndex], order: hoverIndex };
      apps[dragIndex] = { ...apps[dragIndex], order: dragIndex };

      return apps;
    });
  };

  const updateCardDebounce = useCallback(
    debounce((hoverIndex, localApps) => {
      if (localApps.length === 0 || hoverIndex < 0 || hoverIndex >= localApps.length) {
        return;
      }

      const previousIndex = hoverIndex - 1 < 0 ? hoverIndex + 1 : hoverIndex - 1;

      reorderApps(localApps[hoverIndex]?.id, localApps[previousIndex]?.id, hoverIndex - 1 < 0 ? "BEFORE" : "AFTER");
    }, 1000),
    []
  );

  const updateCard = (_, hoverIndex) => {
    updateCardDebounce(hoverIndex, localApps);
  };

  const handleConfigClick = (value: any) => {
    setIsConfig(true);
    setDescription(value);
    setWithAppInfo(false);
    setWithAuthConfig(true);
  };
  const handleEditClick = (value: any) => {
    setIsEdit(true);

    setDescription(value);
    setWithAppInfo(true);
    setWithAuthConfig(false);

    axiosServices.get("/application/" + value?.id).then(({ data }) => {
      if (data?.appConfig) {
        data.appConfig = decompressData(data?.appConfig);
      }
      if (data?.templateConfig) {
        data.templateConfig = decompressData(data?.templateConfig);
      }
      setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? { ...data } : app)));
      setDescription(data);
    });
  };

  const handleLayoutClick = (value: any) => {
    setIsLayoutEdit(true);

    setDescription(value);

    axiosServices.get("/application/" + value?.id).then(({ data }) => {
      if (data?.appConfig) {
        data.appConfig = decompressData(data?.appConfig);
      }
      if (data?.templateConfig) {
        data.templateConfig = decompressData(data?.templateConfig);
      }
      setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? { ...data } : app)));
      setDescription(data);
    });
  };

  const handleTrashClick = (value: any) => {
    setIsTrash(true);
    setDescription(value);
  };

  const onSSOClick = (value: any) => {
    setIsSSO(true);
    setDescription(value);
  };

  const createDefaultMultilingualObject = appId => ({
    selectedLanguage: "english",
    languages: [
      {
        id: uuid(),
        name: "english",
        locale: "en",
        rtl: false,
        defaultLanguage: true,
      },
    ],
    appId: appId,
  });

  const onLanguageClick = (value: any) => {
    setIsLanguage(true);
    setDescription(value);

    if (selectedAppId) {
      axiosServices
        .get(`/multilingual/app/${selectedAppId}`)
        .then(({ data }) => {
          if (!data) {
            const defaultMultilingualData = createDefaultMultilingualObject(selectedAppId);
            setMultiLingual(defaultMultilingualData);
          } else {
            setMultiLingual(data);
          }
        })
        .catch(error => {
          console.error("Error fetching multilingual data:", error);
        });
    }
  };

  const handleUserClick = (value: any) => {
    setIsUser(true);
    setDescription(value);
  };

  const handleDuplicateClick = (value: any) => {
    const duplicatedApp = {
      ..._.cloneDeep(value),
      name: value.name + " copy",
      slug: value.slug + "-copy",
      title: value.title + " copy",
    };
    delete duplicatedApp.id;
    delete duplicatedApp.fqdn;
    duplicatedApp.cfMetadata = {};
    duplicatedApp.userInfo = {};
    duplicatedApp.appConfig.isSingleSignOn = false;
    const changedTemplateConfigIds = changeIdsOfObject(_.cloneDeep(app?.templateConfig));
    duplicatedApp.templateConfig = changedTemplateConfigIds;

    setIsDuplicateAppModalOpen(true);
    setDescription(duplicatedApp);
  };

  const handleFileSelect = type => (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = async event => {
        const jsonString = event.target?.result?.toString();
        const parsedJson = JSON.parse(jsonString || "") || {};

        if (parsedJson?.app?.appConfig) {
          parsedJson.app.appConfig = decompressData(parsedJson?.app?.appConfig);
        }
        if (parsedJson?.app?.templateConfig) {
          parsedJson.app.templateConfig = decompressData(parsedJson?.app?.templateConfig);
        }

        let customComponents = parsedJson?.customComponents;

        const componentIdsMap: any = {};

        if (customComponents) {
          const componentNames = customComponents?.map((data: any) => data?.name);

          const res = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/admin/component/list?names=${componentNames?.join(",")}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
          });
          const existComponentsNames = res?.data?.items?.map((data: any) => data?.name);

          if (existComponentsNames?.length) {
            customComponents = customComponents?.map((data: any) =>
              existComponentsNames.includes(data?.name) ? { ...data, name: `${data?.name}-${uuid()}` } : data
            );
          }

          await Promise.all(
            customComponents?.map(async (data: any) => {
              try {
                const res = await axios.post(process.env.REACT_APP_HOST_API_KEY + "/api/admin/component", data, {
                  headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                });
                componentIdsMap[data.id] = res?.data?.id;
              } catch (error) {
                console.error(`Error for component with id ${data.id}:`, error);
                // Handle error for this particular axios call
              }
            })
          );
        }

        const updatedApp = replaceComponentIds(parsedJson?.app || parsedJson, componentIdsMap);

        delete updatedApp.fqdn;
        updatedApp.cfMetadata = {};
        updatedApp.userInfo = {};
        updatedApp.appConfig.isSingleSignOn = false;

        setDescription(updatedApp);
        if (type == "import") {
          setIsImportAppModalOpen(true);
        } else if (type == "sync") {
          setIsSync(true);
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleSelectClick = type => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".json";
    fileInput.addEventListener("change", handleFileSelect(type));
    fileInput.click();
  };

  const handleExportClick = (item: any) => {
    axiosServices.get("/application/" + item?.id).then(async ({ data }) => {
      if (data?.appConfig) {
        data.appConfig = decompressData(data?.appConfig);
      }
      if (data?.templateConfig) {
        data.templateConfig = decompressData(data?.templateConfig);
      }
      setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));

      const app = { ...data, name: data.name, slug: data.slug, title: data.title };
      delete app.id;
      delete app.fqdn;
      const changedTemplateConfigIds = changeIdsOfObject(_.cloneDeep(app?.templateConfig));
      app.templateConfig = changedTemplateConfigIds;

      configFields.forEach(configKey => {
        app[configKey] = {
          ...app[configKey],
          iconConfigCollection: collectFieldsConfigs(app?.[configKey]),
        };
      });

      const filename = app.name;

      // Inside your handleExportClick function
      const componentsIds: any = [];
      findCustomComponentIds(app, componentsIds);

      const res = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${componentsIds}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });

      const componentsData = res.data?.items;

      const exportData: any = { app };

      if (componentsData?.length) {
        exportData.customComponents = componentsData;
      }

      if (exportData?.app?.appConfig) {
        exportData.app.appConfig = compressData(exportData?.app?.appConfig);
      }
      if (exportData?.app?.templateConfig) {
        exportData.app.templateConfig = compressData(exportData?.app.templateConfig);
      }
      const jsonStr = JSON.stringify(exportData, null, 2);
      const blob = new Blob([jsonStr], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    });
  };

  const handleAppSelect = (item: any) => {
    setSelectedAppId(item?.id);
    if (item?.templateConfig == null) {
      axiosServices.get("/application/" + item?.id).then(({ data }) => {
        if (data?.appConfig) {
          data.appConfig = decompressData(data?.appConfig);
        }
        if (data?.templateConfig) {
          data.templateConfig = decompressData(data?.templateConfig);
          //Append shared collection for apps that do not have one already
          if (!data.templateConfig.collections?.some(col => col.isShared)) {
            editApp(
              item?.id,
              data,
              () => {},
              () => {},
              true
            );
          }
        }
        setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
      });
    }
  };

  const data = localApps.map((item: any) => ({
    ...item,
    title: item?.name,
    subTitle: item?.slug,
    image:
      (item?.appConfig?.iconConfig?.visibility === "PUBLIC" && item?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
        ? item?.appConfig?.iconConfig?.url
        : item?.appConfig?.iconConfig?.icon) || item?.icon,
    fallBack: "Apps",
  }));

  const app = apps?.find(app => app?.id == selectedAppId);
  let collections = app?.templateConfig?.collections;
  let sharedCollections: any[] = collections?.filter(col => col.isShared) || [];
  let normalCollections: any[] = collections?.filter(col => !col.isShared) || [];
  collections = [...normalCollections, sharedCollections[0]];
  const collection = collections?.find(collection => collection?.id == selectedCollectionId) || collections?.[0];
  const page = collection?.pages?.find(page => page?.id == selectedPageId) || collection?.pages[0];
  const view = page?.views?.find(view => view?.id == selectedViewId) || page?.views?.[0];
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const appId = urlParams.get("appIdModal");
    const collectionId = urlParams.get("collectionId");
    const pageId = urlParams.get("pageId");
    const viewId = urlParams.get("viewId");

    if (appId || collectionId || pageId || viewId) {
      const app = apps?.find(app => app?.id === appId);
      if (app) {
        setSelectedAppId(app.id);
        setCurrentApp(app);
        const collections = app.templateConfig?.collections || [];
        if (collectionId) {
          const matchedCollection = collections.find(col => col.id === collectionId);
          if (matchedCollection) {
            setSelectedCollectionId(matchedCollection.id);
            if (pageId) {
              const matchedPage = matchedCollection.pages.find(page => page.id === pageId);
              if (matchedPage) {
                setSelectedPageId(matchedPage.id);

                if (viewId) {
                  const matchedView = matchedPage.views.find(view => view.id === viewId);
                  if (matchedView) {
                    setSelectedViewId(matchedView.id);
                  } else {
                    setSelectedViewId(matchedPage.views[0]?.id);
                  }
                } else {
                  setSelectedViewId(matchedPage.views[0]?.id);
                }
              } else {
                setSelectedPageId(matchedCollection.pages[0]?.id);
                setSelectedViewId(matchedCollection.pages[0]?.views[0]?.id);
              }
            } else {
              setSelectedPageId(matchedCollection.pages[0]?.id);
              setSelectedViewId(matchedCollection.pages[0]?.views[0]?.id);
            }
          }
        }
      }
    }
  }, [location.search, apps]);

  if (!isSuperAdmin()) return <></>;

  return (
    <>
      {description && (
        <>
          <BXConfirmationDialog
            open={!!isTrash}
            title={"Are you sure you want to delete this app?"}
            iconButton
            buttonProps={{ color: "error", children: <IconTrashX /> }}
            onConfirm={() => {
              deleteApp(description);
              setIsTrash(false);
            }}
            onCancel={() => {
              setIsTrash(false);
            }}
          />
          <BXModal
            open={!!isUser}
            label={"Users"}
            icon={<IconUsers />}
            buttonProps={{ variant: "text", color: "secondary", startIcon: <IconUsers /> }}
            title={"Manage connected users"}
            onClose={() => setIsUser(false)}
          >
            {(handleClose: Function) => {
              return (
                <ConnectAppUsers
                  app={description}
                  onSave={() => {}}
                  onCancel={() => {
                    setIsUser(false);
                    handleClose();
                  }}
                />
              );
            }}
          </BXModal>
          <BXModal
            open={!!isLayoutEdit}
            label={"Layouts"}
            icon={<IconLayoutBoardSplit />}
            buttonProps={{ variant: "text", color: "secondary", startIcon: <IconLayoutBoardSplit /> }}
            title={"Layouts"}
            onClose={() => {
              setIsLayoutEdit(false);
            }}
          >
            {(handleClose: Function) => {
              return (
                <LayoutTable
                  app={description}
                  onSave={app => setDescription(app)}
                  onCancel={() => {
                    setIsLayoutEdit(false);
                    handleClose();
                  }}
                  selectedLayoutID={selectedLayoutID}
                />
              );
            }}
          </BXModal>
          <BXModal
            open={!!isEdit || !!isConfig}
            label={"Edit"}
            icon={<IconEditCircle />}
            buttonProps={{ variant: "text", color: "secondary", startIcon: <IconEditCircle /> }}
            title={`Edit App : ${app?.name}`}
            onClose={() => {
              setIsEdit(false);
              setIsConfig(false);
            }}
            isDirty={isDirty}
          >
            {(handleClose: Function) => {
              return (
                <CreateAppForm
                  localApps={localApps}
                  withAppInfo={withAppInfo}
                  withAuthConfig={withAuthConfig}
                  appId={selectedAppId}
                  app={description}
                  onSave={(data: any) => {
                    const { authApi, deviceApi } = app?.appConfig?.auth || {};
                    const { authApi: _authApi, deviceApi: _deviceApi } = data?.appConfig?.auth || {};
                    if (authApi?.uri != _authApi?.uri || app?.baseUrl != data?.baseUrl || deviceApi?.uri != _deviceApi?.uri) {
                      logoutOfApp?.(data?.id);
                    }
                    editApp(description.id, {
                      ...data,
                      icon:
                        (data?.appConfig?.iconConfig?.visibility === "PUBLIC" &&
                        data?.appConfig?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                          ? data?.appConfig?.iconConfig?.url
                          : data?.appConfig?.iconConfig?.icon) || data?.icon,
                      env: {
                        ...data.env,
                        id: data.env?.id || null,
                      },
                      userInfo: {
                        ...data.userInfo,
                        type: data?.appConfig?.auth?.type === "Buildx" ? "BUILDX" : "OTHER",
                        body: data?.userInfo?.method === "GET" ? undefined : data?.userInfo?.body,
                        headers: _.isObject(data?.userInfo?.headers)
                          ? data?.userInfo?.headers
                          : {
                              Authorization: "Bearer {this.login.token}",
                            },
                      },
                      ssoEnabled: data?.appConfig?.isSingleSignOn,
                    } as BXApp);
                    handleClose?.(true);
                  }}
                  onCancel={() => {
                    handleClose(false, () => {
                      setIsEdit(false);
                    });
                  }}
                  setIsDirty={setIsDirty}
                />
              );
            }}
          </BXModal>

          <BXModal
            open={!!isSync}
            label={`Sync With App: ${description?.name}`}
            icon={<IconRefresh />}
            buttonProps={{ variant: "text", color: "secondary", startIcon: <IconCopy /> }}
            title={`Sync With App: ${description?.name}`}
            onClose={() => {
              setIsSync(false);
            }}
            maxWidth='xs'
          >
            {(handleClose: Function) => {
              return (
                <SyncApp
                  app={description}
                  selectedApp={app}
                  onCancel={() => {
                    handleClose(false, () => {
                      setIsSync(false);
                    });
                  }}
                  onSave={async data => {
                    handleAppSyncSave(data as BXApp, app);
                  }}
                />
              );
            }}
          </BXModal>

          <MissingIconsModal
            open={isMissingIconsModalOpen || isSyncMedia}
            groupedIcons={groupedIcons}
            onClose={() => {
              setMissingIconsModalOpen(false);
              setIsSyncMedia(false);
            }}
            onContinue={() => {
              setMissingIconsModalOpen(false);
            }}
          />

          <BXModal
            open={!!isDuplicateAppModalOpen || !!isImportAppModalOpen}
            label={isDuplicateAppModalOpen ? `Duplicate App: ${app?.name}` : `Import App: ${description?.name}`}
            icon={isDuplicateAppModalOpen ? <IconCopy /> : <IconFileImport />}
            buttonProps={{ variant: "text", color: "secondary", startIcon: <IconCopy /> }}
            title={isDuplicateAppModalOpen ? `Duplicate App: ${app?.name}` : `Import App: ${description?.name}`}
            onClose={() => {
              setIsDuplicateAppModalOpen(false);
              setIsImportAppModalOpen(false);
            }}
            isDirty={isDirty}
          >
            {(handleClose: Function) => {
              return (
                <CreateAppForm
                  localApps={localApps}
                  withAuthConfig={false}
                  app={description}
                  onSave={async data => {
                    handleAppSave(data as BXApp, app);
                    handleClose?.(true);
                  }}
                  onCancel={() => {
                    handleClose(false, () => {
                      setIsDuplicateAppModalOpen(false);
                      setIsImportAppModalOpen(false);
                    });
                  }}
                  setIsDirty={setIsDirty}
                />
              );
            }}
          </BXModal>

          <BXModal maxWidth={"sm"} title={"Manage DNS"} open={isSSO} onClose={() => setIsSSO(false)} icon={<IconWorld />}>
            {(handleClose: Function) => {
              return <ManageDNSForm app={description} handleClose={handleClose}></ManageDNSForm>;
            }}
          </BXModal>
          <BXModal
            maxWidth={"lg"}
            title={"Manage Languages"}
            open={isLanguage}
            onClose={() => setIsLanguage(false)}
            icon={<IconLanguage />}
          >
            {(handleClose: Function) => {
              return (
                <ManageLanguages
                  withAppInfo={withAppInfo}
                  withAuthConfig={withAuthConfig}
                  appId={selectedAppId}
                  multiLingual={multiLingual}
                  onSave={async data => {
                    if (data.languages.length === 1 && data.languages[0].name === "english") {
                      handleClose?.(true);
                      return;
                    }

                    try {
                      await axiosServices.put(`/admin/multilingual/app/${selectedAppId}`, data).then(({ data: updatedData }) => {
                        setMultiLingual(updatedData);
                      });

                      handleClose?.(true);
                    } catch (error) {
                      console.error("Error updating data:", error);
                    }
                  }}
                  onCancel={() => {
                    handleClose(false, () => {
                      setIsEdit(false);
                    });
                  }}
                  setIsDirty={setIsDirty}
                />
              );
            }}
          </BXModal>
        </>
      )}

      <Box marginX={1}>
        <Typography fontSize={"20px"} fontWeight={600} marginBottom={1} lineHeight={2} color='textPrimary'>
          Manage Apps
        </Typography>
        <Card style={{ height: leftDrawerOpened ? "calc(100vh - 136px)" : "calc(100vh - 176px)", paddingBottom: 20, overflowX: "auto" }}>
          <Grid xs container height='100%' padding={3} minWidth='1005px'>
            <BuilderColumn
              headerName='Apps'
              rows={data}
              selectedData={app}
              handleSyncClick={() => {
                handleSelectClick("sync");
              }}
              handleSyncMediaClick={handleSyncMediaClick}
              moveApp={moveCard}
              updateApp={updateCard}
              onSelect={handleAppSelect}
              // onConfigClick={handleConfigClick}
              onEditClick={handleEditClick}
              onLayoutClick={handleLayoutClick}
              onSSOClick={onSSOClick}
              onLanguageClick={onLanguageClick}
              onTrashClick={handleTrashClick}
              onUserClick={handleUserClick}
              onDuplicateClick={handleDuplicateClick}
              onExportClick={handleExportClick}
              modal={
                <Box display='flex'>
                  <IconButton
                    onClick={() => {
                      handleSelectClick("import");
                    }}
                    sx={{ marginInlineEnd: 1 }}
                  >
                    <IconFileImport size={18} />
                  </IconButton>

                  <BXModal
                    icon={<IconApps />}
                    label={"App"}
                    buttonProps={{
                      variant: "contained",
                      style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                      startIcon: <IconPlus />,
                      fullWidth: true,
                    }}
                    title={"New Application"}
                  >
                    {(handleClose?: Function) => {
                      return (
                        <CreateAppForm
                          localApps={localApps}
                          withAuthConfig={false}
                          onSave={data => {
                            const app: BXApp = {
                              id: uuid(),
                              slug: data.slug,
                              name: data.name,
                              icon:
                                (data?.appConfig?.iconConfig?.visibility === "PUBLIC" &&
                                data?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                  ? data?.appConfig?.iconConfig?.url
                                  : data?.appConfig?.iconConfig?.icon) || data?.icon,
                              baseUrl: data.baseUrl,
                              templateConfig: {
                                appVersion: appVersion,
                                collections: [],
                              },
                              appConfig: data?.appConfig,
                              authDisabled: data.authDisabled,
                              cfMetadata: {},
                            };

                            addApp(app);
                            handleClose?.();
                          }}
                          onCancel={() => {
                            handleClose?.();
                          }}
                        />
                      );
                    }}
                  </BXModal>
                </Box>
              }
            />
            <CollectionColumn
              app={app}
              collections={collections}
              selectedData={collection}
              onSelect={item => setSelectedCollectionId(item?.id)}
            />
            <PageColumn app={app} collection={collection} selectedData={page} onSelect={item => setSelectedPageId(item?.id)} />
            <ViewColumn app={app} collection={collection} page={page} selectedData={view} onSelect={item => setSelectedViewId(item?.id)} />
          </Grid>

          {/* <PerfectScrollbar>
              {localApps.map((app, index) => (
                <AppAccordion index={index} key={app.id} description={app} moveApp={moveCard} updateApp={updateCard} />
              ))}
            </PerfectScrollbar> */}
        </Card>
      </Box>
    </>
  );
};
