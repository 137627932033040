import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

export let validationRegistry: Record<string, { [key: string]: (value: any) => true | string }> = {};

// Register a validation function for a specific field
export const registerValidation = (name: string, validate: { [key: string]: (value: any) => true | string }) => {
  if (_.get(validationRegistry, name)) {
    return;
  }
  _.set(validationRegistry, name, validate);
};

interface AppState {
  values: Record<string, any>;
}

const initialState: AppState = {
  values: {},
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    initializeAppField: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      setNestedValue(state.values, name, value);
    },
    mergeAppValue: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      const existingValue = getNestedValue(state.values, name);
      if (_.isObject(existingValue) && _.isObject(value)) {
        setNestedValue(state.values, name, { ...existingValue, ...value });
      } else {
        setNestedValue(state.values, name, value);
      }
    },
    setAppValue: (
      state: any,
      action: PayloadAction<{
        name: string;
        value: any;
        isDisabledDirtyField?: boolean;
        reEvaluateErrorsAndDirty?: boolean;
      }>
    ) => {
      const { name, value, isDisabledDirtyField, reEvaluateErrorsAndDirty } = action.payload;

      setNestedValue(state.values, name, value);

      if (!isDisabledDirtyField) {
        const nameParts = name.split(".");
        const [pageId, viewName, ...rest] = nameParts;
        const componentStateArrPath = nameParts.slice(0, nameParts.lastIndexOf("state"));
        const fieldName = componentStateArrPath[componentStateArrPath.length - 1];
        const componentPath = componentStateArrPath.join(".");

        const initialValue = getNestedValue(state.values, `${componentPath}.initialValue`);
        const isDirty = !_.isEqual(value, initialValue);

        const updateDirtyFields = (dirtyPath: string) => {
          const dirtyState = getNestedValue(state.values, dirtyPath) || { isDirty: false, fields: [] };
          let fields = dirtyState.fields;

          if (isDirty && !fields.includes(fieldName)) {
            fields = [...fields, fieldName];
          } else if (!isDirty && fields.includes(fieldName)) {
            fields = fields.filter(field => field !== fieldName);
          }
          const result = { isDirty: fields.length > 0, fields };
          setNestedValue(state.values, dirtyPath, result);
        };

        const traverseKey = rest.length > 1 && rest[rest.length - 1] === "state" ? rest.slice(0, -1).join(".") : rest.join(".");

        traverseOnParents(state, `${pageId}.${viewName}`, traverseKey, (_node, nodeKey) => {
          updateDirtyFields(`${pageId}.${viewName}.${nodeKey}.dirty`);
        });

        updateDirtyFields(`${pageId}.${viewName}.dirty`);
        updateDirtyFields(`${pageId}.dirty`);
      }

      if (reEvaluateErrorsAndDirty) {
        const [pageId, viewName, ...rest] = name.split(".");

        traverseOnChildren(state, `${pageId}`, undefined, (_node, nodeKey, _nodeValue, nodePath, prevValues) => {
          const initialValuePath = `${nodePath}.initialValue`;
          const nodeValuePath = `${nodePath}.state`;
          const containerPath = `${nodePath}.props.placeholderConfig.group`;
          const errorsPath = `${nodePath}.errors`;
          const initialValue = getNestedValue(state.values, initialValuePath);
          const nodeValue = getNestedValue(state.values, nodeValuePath);
          const isContainer = getNestedValue(state.values, containerPath) === "container";
          const error = getNestedValue(state.values, `${errorsPath}.error`);

          let nodeDirty = { isDirty: false, fields: [] as string[] };
          let nodeError = { hasError: !!error, list: error ? [error] : [], error: error };

          if (!_.isEqual(nodeValue, initialValue) && nodeKey !== viewName && !isContainer) {
            nodeDirty.isDirty = true;
            nodeDirty.fields.push(nodeKey);
          }

          if (prevValues) {
            prevValues?.forEach((prevValue: any) => {
              const { dirty: prevDirty, errors: prevErrors } = prevValue || {};
              if (prevDirty) {
                nodeDirty = {
                  isDirty: nodeDirty.isDirty || prevDirty?.isDirty,
                  fields: _.uniq([...nodeDirty.fields, ...(prevDirty?.fields || [])]),
                };
              }
              if (prevErrors) {
                const errorsList = _.uniq([...nodeError.list, ...(prevErrors?.list || [])]);
                nodeError = {
                  hasError: errorsList.length > 0,
                  list: errorsList,
                  error: nodeError.error,
                };
              }
            });
          }

          setNestedValue(state.values, `${nodePath}.errors`, nodeError);
          setNestedValue(state.values, `${nodePath}.dirty`, nodeDirty);

          return { dirty: nodeDirty, errors: nodeError };
        });
      }
    },
    setAppError: (
      state: any,
      action: PayloadAction<{
        name: string;
        error: string;
      }>
    ) => {
      const { name, error } = action.payload;
      const oldError = getNestedValue(state.values, `${name}.errors.error`, "");

      const [pageId, viewName, ...rest] = name.split(".");

      const updateErrorFields = (errorsPath: string) => {
        const errors = getNestedValue(state.values, errorsPath) || { hasError: false, list: [], error: "" };
        const errorList = Array.isArray(errors.list) ? [...errors.list] : [];

        if (error && !errorList.includes(error)) {
          errorList.push(error);
        } else if (!error && oldError) {
          const index = errorList.indexOf(oldError);
          if (index !== -1) {
            errorList.splice(index, 1);
          }
        }

        const hasError = errorList.length > 0;
        const errorValue = errorsPath === `${name}.errors` ? error : "";

        setNestedValue(state.values, errorsPath, {
          hasError,
          list: errorList,
          error: errorValue,
        });
      };

      traverseOnParents(state, `${pageId}.${viewName}`, rest.join("."), (_node, nodeKey) => {
        updateErrorFields(`${pageId}.${viewName}.${nodeKey}.errors`);
      });

      updateErrorFields(`${pageId}.${viewName}.errors`);
      updateErrorFields(`${pageId}.errors`);
    },
    cleanDirtyFields: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      const nameParts = name.split(".");
      const [pageId, viewName, ..._rest] = nameParts;
      const stateIndex = nameParts.lastIndexOf("state");
      const componentStateArrPath = stateIndex >= 0 ? nameParts.slice(0, stateIndex) : nameParts;
      const fieldName = componentStateArrPath[componentStateArrPath.length - 1];

      const cleanDirty = (_node, _nodeKey, state, nodePath) => {
        const dirtyPath = `${nodePath}.dirty`;
        const initialValuePath = `${nodePath}.initialValue`;
        const statePath = `${nodePath}.state`;
        setNestedValue(state.values, initialValuePath, getNestedValue(state.values, statePath, undefined));
        setNestedValue(state.values, dirtyPath, {
          isDirty: false,
          fields: [],
        });
      };

      if (fieldName) {
        traverseOnChildren(state, `${pageId}.${viewName}.${fieldName}`, cleanDirty);
      } else if (viewName) {
        traverseOnChildren(state, `${pageId}.${viewName}`, cleanDirty);
      } else {
        traverseOnChildren(state, `${pageId}`, cleanDirty);
      }
      traverseOnChildren(state, `${pageId}`, undefined, (_node, nodeKey, _nodeValue, nodePath, prevValues) => {
        const initialValuePath = `${nodePath}.initialValue`;
        const nodeValuePath = `${nodePath}.state`;
        const containerPath = `${nodePath}.props.placeholderConfig.group`;
        const initialValue = getNestedValue(state.values, initialValuePath);
        const nodeValue = getNestedValue(state.values, nodeValuePath);
        const isContainer = getNestedValue(state.values, containerPath) === "container";

        let nodeDirty = { isDirty: false, fields: [] as string[] };

        if (!_.isEqual(nodeValue, initialValue) && nodeKey !== viewName && !isContainer) {
          nodeDirty.isDirty = true;
          nodeDirty.fields.push(nodeKey);
        }

        if (prevValues) {
          prevValues?.forEach((prevValue: any) => {
            if (prevValue) {
              nodeDirty = {
                isDirty: nodeDirty.isDirty || prevValue?.isDirty,
                fields: _.uniq([...nodeDirty.fields, ...(prevValue?.fields || [])]),
              };
            }
          });
        }

        setNestedValue(state.values, `${nodePath}.dirty`, nodeDirty);

        return nodeDirty;
      });
    },
    removeAppField: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      deleteNestedValue(state.values, name);
      deleteNestedValue(validationRegistry, name);
    },
    resetAppState: (state, action: PayloadAction<{ name?: string }>) => {
      const { name } = action.payload;
      if (name) {
        // Reset a specific field if name is provided
        const initialValue = getNestedValue(state.values, name);
        setNestedValue(state.values, name, initialValue);
        traverseOnChildren(state, name, (_node, _nodeKey, _state, nodePath) => {
          setNestedValue(state.values, `${nodePath}.dirty`, { isDirty: false, fields: [] });
          setNestedValue(state.values, `${nodePath}.errors`, { hasError: false, list: [], error: "" });
          setNestedValue(state.values, `${nodePath}.state`, getNestedValue(state.values, `${nodePath}.initialValue`));
        });
      } else {
        // Reset all fields
        state.values = _.cloneDeep(initialState.values);
        traverseOnChildren(state, "", (_node, _nodeKey, _state, nodePath) => {
          setNestedValue(state.values, `${nodePath}.dirty`, { isDirty: false, fields: [] });
          setNestedValue(state.values, `${nodePath}.errors`, { hasError: false, list: [], error: "" });
          setNestedValue(state.values, `${nodePath}.state`, getNestedValue(state.values, `${nodePath}.initialValue`));
        });
      }
    },
    clearErrors: (state: any, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      const [pageId, viewName, fieldName, ...rest] = name.split(".");

      const removeErrors = (_node, _nodeKey, state, nodePath) => {
        const errorsPath = `${nodePath}.errors`;
        setNestedValue(state.values, errorsPath, { error: "", list: [], hasError: false });
      };

      traverseOnChildren(state, `${pageId}.${viewName}.${fieldName}`, removeErrors);
    },

    /**
     * Produce an event trigger for a specific field or view.
     * When a "trigger" is produced for a field,
     * it sends a signal to the field (or view) to perform a certain action.
     *
     * @param name - The field or view that this trigger is associated with.
     * @param type - The type of event that is being triggered (e.g., 'refetch', 'update', 'delete', 'fetchNextPage).
     * @param eventPayload - Any additional data that needs to be passed along with the trigger event.
     *
     * Example:
     * If a table view needs to fetch new data, you could trigger a 'refetch' event with relevant payload.
     */
    produceTrigger: (state, action: PayloadAction<{ name?: string; type?: string; eventPayload?: any }>) => {
      const { name } = action.payload;
      const signalEventPayload = {
        type: action.payload.type, // Event type (e.g., 'refetch', 'reset')
        payload: action.payload.eventPayload, // Any additional data needed for handling the event
      };

      if (name) {
        setNestedValue(state.values, `${name}.trigger`, signalEventPayload);
      }
    },
    clearTrigger: (state, action) => {
      const { name } = action.payload;

      if (name) {
        setNestedValue(state.values, `${name}.trigger`, "");
      }
    },
  },
});

export const {
  setAppValue,
  setAppError,
  mergeAppValue,
  initializeAppField,
  removeAppField,
  resetAppState,
  produceTrigger,
  clearErrors,
  cleanDirtyFields,
  clearTrigger,
} = appStateSlice.actions;
export default appStateSlice.reducer;

// Parsing function to convert the path into an array of keys
function parsePath(path: string): (string | number)[] {
  const regex = /([^[.\]]+)|\[(\d+)\]/g; // Match keys or indices
  const matches: (string | number)[] = [];

  let match;
  while ((match = regex.exec(path)) !== null) {
    if (match[1]) {
      // Dot-separated key
      matches.push(match[1]);
    } else if (match[2]) {
      // Numeric index in square brackets
      matches.push(Number(match[2]));
    }
  }

  return matches;
}

export function traverseOnChildren(
  state: any,
  _path: string,
  callback?: (node: any, nodeKey: string, state: any, nodePath: string) => boolean | void,
  postCallBack?: (node: any, nodeKey: string, state: any, nodePath: string, prevValue: any) => void
) {
  const componentPath = _path.split(".");
  const visitedNodes = new Set();
  function traverse(nodeKey, basePath) {
    if (visitedNodes.has(nodeKey)) {
      return;
    }
    visitedNodes.add(nodeKey);

    let nodePath = `${basePath}.${nodeKey}`;
    if (basePath === "") {
      nodePath = nodeKey;
    }
    let newBasePath = basePath;

    if (basePath.split(".").length < 2) {
      if (basePath === "") {
        newBasePath = nodeKey;
      } else {
        newBasePath = `${basePath}.${nodeKey}`;
      }
    }

    const node = getNestedValue(state.values, nodePath);

    if (!node) {
      return;
    }

    const shouldContinue = callback?.(node, nodeKey, state, nodePath);

    if (shouldContinue === false) {
      return;
    }
    let prevValue: any[] = [];
    if (node.state?.pages) {
      const worksWith = node.state?.worksWith;
      if (worksWith === "customPages") {
        for (let page of node.state.pages) {
          let pageRef = page.pageReference;
          if (page.customPageSelected) {
            prevValue.push(traverse(pageRef, newBasePath));
          }
        }
      } else if (worksWith === "currentPage") {
        const group = node.state;
        const selectedPage = group?.selectedPage;
        const pages = node.state.pages;
        let currentPageIndex = _.toNumber(selectedPage || 0);
        if (_.isNaN(currentPageIndex)) {
          currentPageIndex = _.findIndex(group.pages, (page: any) => page.pageReference === group.selectedPage);
        }
        currentPageIndex = _.clamp(currentPageIndex, 0, pages?.length - 1);
        for (let i = 0; i < pages.length; i++) {
          const page = pages[i];
          if (i === currentPageIndex) {
            const key = page.pageReference;
            prevValue.push(traverse(key, newBasePath));
          }
        }
      } else if (worksWith === "allPages") {
        for (let page of node.state.pages) {
          let pageRef = page.pageReference;
          prevValue.push(traverse(pageRef, newBasePath));
        }
      }
    } else if (node.mainRepeated && node.props?.repeated?.enabled) {
      for (let index = 0; index < node?.state?.length; index++) {
        for (let key in node.state[index]) {
          if (node.state[index].hasOwnProperty(key)) {
            let componentBasePath = "";
            if (node.state[index][key].props.basePath) {
              componentBasePath = node.state[index][key].props.basePath;
            }
            const compKey = componentBasePath ? `${componentBasePath}.${key}` : key;
            prevValue.push(traverse(compKey, `${newBasePath}`));
          }
        }
      }
    } else if (node.children?.length > 0) {
      for (let childKey of node.children) {
        // if (!_.isNil(node?.repeated?.index)) {
        //   prevValue.push(traverse(`${childKey}-${node.repeated.index}`, newBasePath));
        // } else {
        let componentBasePath = "";
        if (node.props?.basePath) {
          componentBasePath = node.props.basePath;
        }
        const compKey = componentBasePath ? `${componentBasePath}.${childKey}` : childKey;
        prevValue.push(traverse(compKey, newBasePath));
        // }
      }
    } else if (basePath === "") {
      if (node && typeof node === "object") {
        for (let key in node) {
          if (node.hasOwnProperty(key) && key !== "dirty" && key !== "errors") {
            prevValue.push(traverse(key, newBasePath));
          }
        }
      }
    }
    return postCallBack?.(node, nodeKey, state, nodePath, prevValue);
  }

  traverse(componentPath[componentPath.length - 1], componentPath.slice(0, -1).join("."));
}

function traverseOnParents(state, path, startKey, callback) {
  const visitedNodes = new Set();

  function traverse(nodeKey) {
    if (visitedNodes.has(nodeKey)) {
      return;
    }
    visitedNodes.add(nodeKey);

    const nodePath = `${path}.${nodeKey}`;
    const node = getNestedValue(state.values, nodePath);
    if (!node) return;

    const shouldContinue = callback(node, nodeKey, state, nodePath);
    if (shouldContinue === false) {
      return;
    }

    const parentNode = getNestedValue(state.values, nodePath);

    if (parentNode.parent) traverse(parentNode.parent);
  }

  traverse(startKey);
}

// Helper functions to set/get nested values
function setNestedValue(object: any, path: string | null, value: any, override = false) {
  if (path === null) return;
  const keys = parsePath(path);
  let current = object;

  keys.slice(0, -1)?.forEach((key, index) => {
    const nextKey = keys[index + 1];
    if (typeof nextKey === "number") {
      // Next key is a number, so current key should be an array
      if (!Array.isArray(current[key])) {
        current[key] = [];
      }
    } else {
      // Next key is not a number, current key should be an object
      if (!current[key] || typeof current[key] !== "object" || Array.isArray(current[key])) {
        // Check if current[key] is an array and needs to be converted to an object
        if (Array.isArray(current[key])) {
          current[key] = [...current[key]]; // Convert array to object
        } else {
          current[key] = {};
        }
        if (!current[key] || typeof current[key] !== "object" || Array.isArray(current[key])) {
          // Check if current[key] is an array and needs to be converted to an object
          if (Array.isArray(current[key])) {
            current[key] = [...current[key]]; // Convert array to object
          } else {
            current[key] = {};
          }
        }
      }
    }
    current = current[key];
  });

  if (override && _.isObject(current[keys[keys.length - 1]])) {
    return;
  }

  const finalKey = keys[keys.length - 1];
  if (Object.isFrozen(current)) {
    current = { ...current };
  }
  current[finalKey] = value;
}

function getNestedValue(object: any, path: string, defaultValue: any = undefined) {
  const keys = path.replace(/\[(\w+)\]/g, ".$1").split(".");
  let current = object;

  for (let key of keys) {
    if (current[key] === undefined) {
      return defaultValue;
    }
    current = current[key];
  }

  return current;
}

function deleteNestedValue(object: any, path: string | null) {
  if (path === null) return;
  const keys = parsePath(path);
  let current = object;
  let parents: {
    parent: any;
    key: string | number;
  }[] = [];

  // Traverse to the desired nested object
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (current[key] === undefined) {
      return; // Exit if the path doesn't exist
    }
    parents.push({ parent: current, key });
    current = current[key];
  }

  const finalKey = keys[keys.length - 1];

  if (current[finalKey] === undefined) {
    return;
  }

  if (Array.isArray(current)) {
    // If current is an array and finalKey is a number, use splice
    if (typeof finalKey === "number") {
      current.splice(finalKey, 1);
    } else {
      delete current[finalKey];
    }
  } else {
    if (typeof finalKey === "number" && Array.isArray(current[finalKey])) {
      current[finalKey].splice(finalKey, 1);
    } else {
      delete current[finalKey];
    }
  }

  // Clean up empty parent objects or arrays
  for (let i = parents.length - 1; i >= 0; i--) {
    const { parent, key } = parents[i];
    const value = parent[key];
    if (
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
    ) {
      if (Array.isArray(parent)) {
        // If parent is an array, remove the empty array element
        parent.splice(Number(key), 1);
      } else {
        delete parent[key];
      }
    } else {
      break; // Stop if we find a non-empty parent
    }
  }
}
