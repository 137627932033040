import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import React, { memo, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useDrag } from "react-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BXEngine } from "../../../../BXEngine";
import { MUI_COMPONENTS } from "./FormBuilderEditor";

import axios from "axios";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useQuery } from "react-query";
import { ResizeElement } from "src/components/ResizeElement";
import { updateComponent } from "src/features/builder/builderSlice";
import { selectComponentById } from "src/features/builder/selectors";
import { StripeContainer } from "./components/StripeComponents/StripeContainer";
import ParentDragElement from "./ParentDragElement";
import { ComponentItemType } from "./types";
import {
  chartsNames,
  componentsHaveChildren,
  getBuilderElementById,
  getItemClosestProp,
  isParentFlex,
  isParentGrid,
  mapValuesRecursively,
  resolveResponsiveProperties,
  resolveSxStylingInBuilder,
  stepperCount,
} from "./utils";

interface DragElementProps {
  setActiveComponent?: (item: any) => void;
  // setIsContainerDragging?: (e: boolean) => void;
  itemIndex?: number;
  componentId?: number;
  className?: string;
  active?: boolean;
  canDrag?: boolean;
  resizable?: boolean;
  hideSource?: boolean;
  isSideMenu?: boolean;
  boxHeightRef?: any;
  item?: any;
  boxPosition?: any;
  children?: any;
  path?: any;
  onLayout?: any;
  boxItemRefs?: any;
  canDropItem?: any;
  isOverCurrentComponents?: any;
  $views?: any;
}
export const DragElement = memo((props: DragElementProps) => {
  const {
    itemIndex,
    item: _item,
    componentId,
    className,
    active,
    canDrag,
    boxPosition,
    children,
    setActiveComponent,
    resizable = true,
    hideSource = true,
    isSideMenu,
    // setIsContainerDragging,
    boxHeightRef,
    boxItemRefs,
    path,
    onLayout,
    canDropItem,
    $views,
    isOverCurrentComponents,
  } = props;
  const layoutBreak = useSelector((state: any) => state.builder.layoutBreak, shallowEqual);
  const theme = useTheme();
  const dragItemRef = useRef<any>();
  const location = useLocation();
  const dispatch = useDispatch();

  //The builder item is the element fetched from the store, the item is the dynamic resolved element.
  const builderItem = useSelector(state => selectComponentById(state, componentId)) || _item;
  const item = useMemo(() => {
    const clonedElement = _.cloneDeep(builderItem);
    resolveResponsiveProperties(clonedElement, layoutBreak);
    return clonedElement;
  }, [builderItem, layoutBreak]);

  const activeComponent = useSelector(
    (state: any) => (isSideMenu ? null : item?.id == state.builder.activeComponent ? state.builder.activeComponent : undefined),
    shallowEqual
  );

  const isFlexCanvasEnabled = useSelector((state: any) => state.builder.isFlexCanvasEnabled, shallowEqual);
  const scaleFactor = useSelector((state: any) => state.builder.scaleFactor, shallowEqual);
  const selectedItemsId = useSelector((state: any) => (isSideMenu ? [] : state.builder.selectedItemsId), shallowEqual);
  const isRTL = useSelector((state: any) => state.builder.isRTL, shallowEqual);

  const isActiveComponent = useSelector(
    (state: any) => (state.builder.activeComponent === item?.id && item?.id ? true : false),
    shallowEqual
  );

  const Component = useMemo(() => {
    return MUI_COMPONENTS[item?.type];
  }, [item]);

  const { type, leftPercentage, left, config, top } = item || {};
  const { widthPx, heightPx, widthPercentage, heightPercentage, fixedWidth, isPercentageHeight, disableResizeHeight, minWidth, minHeight } =
    config || {};

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { ...builderItem, index: itemIndex, boxPosition, level: null, isSideMenu },
    canDrag:
      item?.config?.flexCanvas || item?.config?.disableDrag || location.pathname.startsWith("/buildx/form-builder/template/history/")
        ? false
        : canDrag,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      // difference: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: item?.config?.flexCanvas || item?.config?.disableDrag ? false : canDrag });

    // setIsContainerDragging?.(isDragging);
  }, [isDragging]);

  const itemsIds = selectedItemsId?.map(el => ({
    id: el,
    boxPosition: boxItemRefs?.current[item.id]?.getBoundingClientRect(),
    level: null,
  }));

  const [{ isDragging: isDraggingItems }, dragItems, previewItems] = useDrag({
    type: "Group",
    item: itemsIds,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      // difference: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  useLayoutEffect(() => {
    previewItems(getEmptyImage(), { captureDraggingState: false });
  }, [isDraggingItems]);

  const onResize = (size: any) => {
    if (size?.width < minWidth || size?.height < minHeight) return;
    const dx = Number(left) <= boxPosition?.width - size.width;
    const dy = Number(top) <= (boxPosition?.height || getItemClosestProp(boxHeightRef, layoutBreak)) - size.height;

    const width = Math.round(
      dx || isParentFlex(item) || isParentGrid(item)
        ? size.width <= boxPosition?.width
          ? size.width
          : boxPosition?.width
        : boxPosition?.width - (Number((leftPercentage || "")?.replace("%", "")) / 100) * boxPosition?.width
    );

    const height = Math.round(
      dy || isParentFlex(item) || isParentGrid(item)
        ? size.height <= boxPosition?.height
          ? size.height
          : boxPosition?.height
        : (boxPosition?.height || getItemClosestProp(boxHeightRef, layoutBreak)) - top
    );

    const PWidth = Math.round((width / (boxPosition?.width / scaleFactor)) * 100);
    const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

    const PHeight = Math.round((height / (boxPosition?.height / scaleFactor)) * 100);
    const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

    const hasCustom = item?.config?.hasCustom;

    // Build updated configuration after resize
    const updatedConfig = {
      ...builderItem.config,
      widthPx: {
        ...builderItem.config.widthPx,
        [layoutBreak]: width,
        xs: hasCustom ? builderItem.config.widthPx.xs : width,
      },
      widthPercentage: {
        ...builderItem.config.widthPercentage,
        [layoutBreak]: percentageWidth,
        xs: hasCustom ? builderItem.config.widthPercentage?.xs : percentageWidth,
      },
      heightPx: {
        ...builderItem.config.heightPx,
        [layoutBreak]: Number(height),
        xs: hasCustom ? builderItem.config.heightPx.xs : height,
      },
      heightPercentage: {
        ...builderItem.config.heightPercentage,
        [layoutBreak]: percentageHeight,
        xs: hasCustom ? builderItem.config.heightPercentage?.xs : percentageHeight,
      },
      hasCustom: layoutBreak === "xs",
    };

    // Dispatch the update
    dispatch(
      updateComponent({
        id: builderItem?.id,
        changes: {
          config: updatedConfig,
        },
      })
    );
  };

  const itemParent = getBuilderElementById(item?.parentId);

  let getDimensions = {
    position: !resizable || item?.config?.flexCanvas ? "relative" : "absolute",

    top: top,
    left: left,
    ...(resizable && {
      width: item?.config?.isDynamicWidth
        ? "auto"
        : String(itemParent?.type) === ComponentItemType.GridContainer
        ? "100%"
        : !fixedWidth
        ? widthPercentage
        : widthPx,
      height: item?.config?.isDynamicHeight
        ? "fit-content"
        : item?.config?.flexCanvas
        ? "100%"
        : isPercentageHeight
        ? heightPercentage
        : String(itemParent?.type) === ComponentItemType.GridContainer
        ? "100%"
        : heightPx,
      // height: item?.config?.isDynamicHeight ? "auto" : item?.config?.flexCanvas ? "100%" : getItemClosestProp(heightPx, layoutBreak),
      border: item?.config?.flexCanvas
        ? ""
        : resizable && (isActiveComponent || active)
        ? `1px dashed ${theme.palette.text.primary}`
        : "none",
    }),
    // opacity: isDragging && !isSideMenu ? 0 : 1,
    ...(isDragging && { visibility: isSideMenu ? "visible" : "hidden" }),

    // ...(selectedItemsId.length <= 0 && { visibility: isDragging && !isSideMenu ? "hidden" : "visible" }),
    zIndex: 1,
    backgroundColor: "transparent",
  };

  const isActiveComponentResizable = resizable && !item?.config?.isNotResizable && boxPosition && isActiveComponent;
  const ParentComponent: any = isActiveComponentResizable ? ResizeElement : Box;

  let fullInnerWidth = false;
  let parentComponentWidthHeight = {};

  if (item?.parentId) {
    const element = getBuilderElementById(item?.parentId);

    fullInnerWidth = componentsHaveChildren.includes(element?.type);
    parentComponentWidthHeight = { height: getDimensions.height, width: getDimensions.width };
  }

  const parentFlex = isParentFlex(item);
  const parentGrid = isParentGrid(item);
  let stylesFromChild = item?.config?.parentStyle;

  const parentComponentProps: any =
    resizable && isActiveComponent
      ? { height: heightPx, width: widthPx, onResize, stepper: stepperCount, isDragging, scaleFactor, isRTL, boxPosition }
      : {};
  const isParent = item?.parentId === null;

  if ((parentFlex || parentGrid) && !isActiveComponentResizable) {
    getDimensions = _.set(getDimensions, "width", "100%");
    getDimensions = _.set(getDimensions, "height", "100%");
  }

  const fetchCustomComponentData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${item?.config?.customComponentId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
    });
    const ids: any = {};
    data?.items?.forEach((item: any) => {
      ids[item?.id] = item;
    });

    return ids;
  };

  const { data: componentData, isLoading: isCustomComponentLoading } = useQuery(
    ["customComponent", item?.config?.customComponentId],
    fetchCustomComponentData,
    {
      enabled: !!item?.config?.customComponentId, // Only run the query if customComponentId exists
      staleTime: Infinity,
    }
  );

  const resolvedProps = useMemo(() => {
    return resolveSxStylingInBuilder(mapValuesRecursively({ ...item?.props }), layoutBreak);
  }, [item?.props, layoutBreak]);

  const componentProps = _.cloneDeep(item?.props);
  useEffect(() => {
    if (item?.config?.cssPaths) {
      item?.config?.cssPaths?.forEach((path: any) => {
        _.set(componentProps, path, item?.config?.isDynamicHeight ? "unset" : _.get(componentProps, path));
      });
    }
  }, [item?.config?.isDynamicHeight]);

  const isRemovingChildren =
    isDragging &&
    !isSideMenu &&
    (String(item?.type) === ComponentItemType.CustomContainer ||
      String(item?.type) === ComponentItemType.FlexContainer ||
      String(item?.type) === ComponentItemType.GridContainer ||
      String(item?.type) === ComponentItemType.StepperContainer) &&
    false;
  if (item?.config?.hideElement || item?.config?.hideIn?.includes(layoutBreak)) {
    return <></>;
  }

  if (item?.isCustomCanvas && !isFlexCanvasEnabled) {
    return (
      <ParentDragElement
        boxItemRefs={boxItemRefs}
        builderItem={builderItem}
        item={item}
        parentFlex={parentFlex}
        isOverCurrentComponents={isOverCurrentComponents}
        canDropItem={canDropItem}
      >
        <Component {...resolvedProps}>
          {item?.children && (
            <>
              {item?.children
                ?.filter((id: any) => !selectedItemsId?.includes(id))
                ?.map((id: any, index: number) => {
                  return (
                    <StripeContainer element={item} key={`${id}`}>
                      <DragElement
                        {...props}
                        key={`${id}`}
                        itemIndex={index}
                        componentId={id}
                        active={selectedItemsId?.includes(id)}
                        boxItemRefs={boxItemRefs}
                        className={"mouse-select__selectable"}
                        canDropItem={canDropItem}
                        isOverCurrentComponents={isOverCurrentComponents}
                        $views={$views}
                      />
                    </StripeContainer>
                  );
                })}
            </>
          )}
          {!!item?.children?.filter((id: any) => selectedItemsId?.includes(id))?.length && (
            <Box
              sx={{ display: "contents", visibility: isDraggingItems ? "hidden" : "visible" }}
              ref={dragItems}
              className={"mouse-select__selectable"}
            >
              {item?.children
                ?.filter((id: any) => selectedItemsId.includes(id))
                ?.map((id: any, index: number) => {
                  return (
                    <DragElement
                      {...props}
                      key={`${id}`}
                      itemIndex={index}
                      componentId={id}
                      active={selectedItemsId?.includes(id)}
                      canDrag={!(selectedItemsId?.length || item?.children?.some((id: any) => id === activeComponent))}
                      boxItemRefs={boxItemRefs}
                      className={"mouse-select__selectable"}
                      canDropItem={canDropItem}
                      isOverCurrentComponents={isOverCurrentComponents}
                      $views={$views}
                    />
                  );
                })}
            </Box>
          )}
        </Component>
      </ParentDragElement>
    );
  }

  if (!_.isNil(item?.config?.isVisiblePreviewByStepper) && !item?.config?.isVisiblePreviewByStepper) {
    return <></>;
  }

  return (
    <ParentComponent
      isParent={isParent}
      {...parentComponentProps}
      {...joinObjects(
        !isActiveComponentResizable && {
          ...{
            style: stylesFromChild,
            ...parentComponentWidthHeight,
            ...(item?.config?.flexCanvas
              ? {
                  height: "100%",
                }
              : {}),
          },
        }
      )}
    >
      <Box
        onClick={e => {
          e.stopPropagation();
          setActiveComponent?.(item);
        }}
        sx={joinObjects(
          getDimensions,
          (parentFlex || parentGrid) && {
            position: "static",
          },
          String(itemParent?.type) !== ComponentItemType.CustomContainer &&
            String(item?.type) !== ComponentItemType.FlexContainer &&
            String(item?.type) !== ComponentItemType.StepperContainer &&
            String(item?.type) !== ComponentItemType.GridContainer && {
              width: fullInnerWidth && !isActiveComponentResizable ? "100%" : getDimensions.width,
            }
        )}
        data-id={item?.id}
        id={item?.id}
        style={joinObjects(stylesFromChild)}
        className={`${className} ${active ? "active" : ""}`}
      >
        <Box
          height={disableResizeHeight && !isSideMenu ? item?.config?.defaultHeight * scaleFactor : "100%"}
          width={disableResizeHeight && !isSideMenu ? item?.config?.defaultWidth * scaleFactor : "100%"}
          ref={(e: any) => {
            drag(e);
            // drop(e);
            dragItemRef.current = e;
          }}
          sx={{
            position: "relative",
          }}
        >
          {String(item?.type) !== ComponentItemType.CustomContainer &&
            String(item?.type) !== ComponentItemType.FlexContainer &&
            String(item?.type) !== ComponentItemType.StepperContainer &&
            String(item?.type) !== ComponentItemType.GridContainer && (
              <Box sx={{ position: "absolute", top: -2, right: 0, bottom: -2, left: 0, zIndex: itemIndex || 0 + 1 }} />
            )}
          {isRemovingChildren ? (
            React.Children.map(props.children, (child, index) => (index === 0 ? React.cloneElement(child, { children: [] }) : child))
          ) : isSideMenu ? (
            children
          ) : [
              ComponentItemType.CustomContainer,
              ComponentItemType.FlexContainer,
              ComponentItemType.GridContainer,
              ComponentItemType.StepperContainer,
            ].includes(item?.type) ? (
            <ParentDragElement
              boxItemRefs={boxItemRefs}
              builderItem={builderItem}
              item={item}
              parentFlex={parentFlex}
              isOverCurrentComponents={isOverCurrentComponents}
              canDropItem={canDropItem}
            >
              {Component && (
                <Component {...resolvedProps}>
                  {item?.children && (
                    <>
                      {item?.children
                        ?.filter((id: any) => !selectedItemsId?.includes(id))
                        ?.map((id: any, index: number) => {
                          return (
                            <StripeContainer element={item} key={`${id}`}>
                              <DragElement
                                {...props}
                                key={`${id}`}
                                itemIndex={index}
                                componentId={id}
                                active={selectedItemsId?.includes(id)}
                                boxItemRefs={boxItemRefs}
                                className={"mouse-select__selectable"}
                                canDropItem={canDropItem}
                                isOverCurrentComponents={isOverCurrentComponents}
                                $views={$views}
                              />
                            </StripeContainer>
                          );
                        })}
                    </>
                  )}
                  {!!item?.children?.filter((id: any) => selectedItemsId?.includes(id))?.length && (
                    <Box
                      sx={{ display: "contents", visibility: isDraggingItems ? "hidden" : "visible" }}
                      ref={dragItems}
                      className={"mouse-select__selectable"}
                    >
                      {item?.children
                        ?.filter((id: any) => selectedItemsId.includes(id))
                        ?.map((id: any, index: number) => {
                          return (
                            <DragElement
                              {...props}
                              key={`${id}`}
                              itemIndex={index}
                              componentId={id}
                              active={selectedItemsId?.includes(id)}
                              canDrag={!(selectedItemsId?.length || item?.children?.some((id: any) => id === activeComponent))}
                              boxItemRefs={boxItemRefs}
                              className={"mouse-select__selectable"}
                              canDropItem={canDropItem}
                              isOverCurrentComponents={isOverCurrentComponents}
                              $views={$views}
                            />
                          );
                        })}
                    </Box>
                  )}
                </Component>
              )}
            </ParentDragElement>
          ) : item?.config?.customComponent ? (
            <>
              {!isCustomComponentLoading && (
                <BXEngine
                  key={item?.id}
                  path={path}
                  auth={{}}
                  componentName={componentData?.[item?.config?.customComponentId]?.name}
                  componentData={{
                    ...item?.props,
                    config: chartsNames.includes(item?.type) ? item?.configData : componentData?.[item?.config?.customComponentId]?.config,
                    disableApis: true,
                  }}
                  layout={[{ id: item?.id, type: item?.type } as any]}
                  onLayout={(values: any) => onLayout(values, { skip: !!componentData?.[item?.config?.customComponentId]?.config })}
                />
              )}
            </>
          ) : item?.config?.BxComponent ? (
            <BXEngine
              key={item?.id}
              path={path}
              auth={{}}
              layout={[{ id: item?.id, type: item?.type } as any]}
              componentData={{
                ...item?.props,
                config: item?.configData,
                disableApis: true,
              }}
            />
          ) : (
            Component && (
              // <Box // here add new box to add ref
              //   sx={{
              //     position: "relative",
              //     justifyContent: "center",
              //     width: "100%",
              //     height: "100%",
              //     overflow: "hidden",
              //   }}
              //   ref={el => {
              //     boxItemRefs.current[item.id] = el;
              //   }}
              // >
              <Component
                key={`${item?.id}-Comp`}
                {...resolvedProps}
                $config={item?.config}
                $views={$views}
                // stepperGroups={stepperGroups}
              />
              //  </Box>
            )
          )}
        </Box>
      </Box>
    </ParentComponent>
  );
});
