import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useTheme } from "@mui/material";
import { IconAnchor, IconArrowBackUp } from "@tabler/icons-react";
import _ from "lodash";
import moment from "moment";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery } from "react-query";
import { queryClient } from "src/BXEngine/BXContext";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import { decompressData } from "src/utils/services";

const AppHistory = (props: any) => {
  const { app, handleClose } = props;

  const { palette } = useTheme();

  let queryKey = ["appHistory", app?.id];
  const { data, hasNextPage, fetchNextPage, isFetching, isError } = useInfiniteQuery(
    queryKey,
    ({ pageParam, queryKey }) => {
      if (queryKey[1] && queryKey[1].length < 3) {
        return {
          data: {
            items: [],
          },
        } as any;
      }
      return axios.get(process.env.REACT_APP_HOST_API_KEY + `/api/admin/app-history/app-versions/${app?.id}`, {
        params: {
          cursor: pageParam,
          limit: 10,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const handleRestoreApp = (appHistory: any) => {
    axios
      .post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/app-history/${app?.id}/rollback-version/${appHistory?.id}?backup=false`)
      .then(() => {
        queryClient.refetchQueries(["apps"]);
        enqueueSnackbarRef?.("The application has been successfully restored", {
          variant: "success",
        });
        handleClose?.();
      });
  };

  const handleBackupRestoreApp = (appHistory: any) => {
    axios
      .post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/app-history/${app?.id}/rollback-version/${appHistory?.id}?backup=true`)
      .then(() => {
        queryClient.refetchQueries(["apps"]);
        enqueueSnackbarRef?.("The application has been successfully backed up and restored", {
          variant: "success",
        });
        handleClose?.();
      });
  };

  const columns: any[] = [
    { id: "id", label: "ID", minWidth: 270 },
    { id: "createDate", label: "Create Date", minWidth: 270 },
    { id: "templateConfig.appVersion", label: "App Version", minWidth: 270 },
  ];

  const entries = _.flatten(data?.pages?.map((p: any) => p?.data?.items))?.filter?.(Boolean);

  return (
    <TableContainer style={{ backgroundColor: palette.background.paper }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
              >
                {column.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!isFetching && entries?.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align={"center"}>
                No apps yet
              </TableCell>
            </TableRow>
          )}
          {entries?.map((row: any) => {
            return (
              <TableRow hover role='checkbox' tabIndex={-1} key={row?.id}>
                {columns.map(column => {
                  let value;
                  if (column.id === "templateConfig.appVersion") {
                    const decompressedData = decompressData(row?.templateConfig);
                    value = decompressedData?.appVersion || "-";
                  } else {
                    value = _.get(row, column.id);
                  }
                  return (
                    <TableCell key={column.id}>
                      <Grid container alignItems={"center"}>
                        {column.id === "createDate" ? moment(value).format("YYYY-MM-DD HH:mm:ss") : value || "-"}
                      </Grid>
                    </TableCell>
                  );
                })}
                <TableCell sx={{ display: "flex", gap: 1 }}>
                  <Tooltip title={"Restore"}>
                    <IconButton onClick={() => handleRestoreApp(row)}>
                      <IconArrowBackUp size={18} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Backup and Restore"}>
                    <IconButton onClick={() => handleBackupRestoreApp(row)}>
                      <IconAnchor size={18} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
          {isFetching && (
            <TableRow ref={sentryRef}>
              <TableCell colSpan={columns.length || 1}>Loading</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppHistory;
