import { configureStore } from "@reduxjs/toolkit";
import builderReducer from "src/features/builder/builderSlice";
import appStateReducer from "../features/appState/appStateSlice";

const store = configureStore({
  reducer: {
    appState: appStateReducer,
    builder: builderReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
