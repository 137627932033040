import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { joinObjects } from "hd-utils";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectComponentById } from "src/features/builder/selectors";
import {
  isParentFlex,
  mapValuesRecursively,
  resolveResponsiveProperties,
  resolveSxStylingInBuilder,
  stepperGroupComponentState,
} from "src/views/pages/BuildX/FormBuilder/utils";
import { StripeContainer } from "./components/StripeComponents/StripeContainer";
import { DragElement } from "./DragElement";
import { MUI_COMPONENTS } from "./FormBuilderEditor";
import { ComponentItemType, StepperComponentState } from "./types";

const BoxComponent = (props: any) => {
  const {
    componentId,
    index: parentIndex,
    itemIndex,
    setView,
    onLayout = () => {},
    layoutBreak,
    setInnerComponentDropping,
    viewsLength,
    formMoveBoxFunction,
    parentBoxMoveBoxComponent,
    path,
    views,
    isStepperEnabled,
    isRTL,
    stepperGroups,
    scaleFactor,
  } = props;

  const containerItem = useSelector(state => selectComponentById(state, componentId));
  resolveResponsiveProperties(containerItem, layoutBreak);
  const activeComponent = useSelector((state: any) => state.builder.activeComponent);
  const selectedItemsId = useSelector((state: any) => state.builder.selectedItemsId);
  const [boxItemRef, setBoxItemRef] = useState<any>();
  const theme = useTheme();
  const dispatch = useDispatch();

  const Component = MUI_COMPONENTS[containerItem?.type];

  // const moveMultipleBoxes = (data: any) => {
  //   const newElementsId: any = [];
  //   const newElements = data?.map((el: any) => {
  //     const { item, ...rest } = el || {};
  //     if (item?.parentId) {
  //       dispatch(removeChildFromParent({ parentId: item?.parentId, childId: item.id }));
  //     }

  //     const newItem = { ...item, ...rest, parentId: containerItem?.id };

  //     if (newItem?.id) {
  //       newElementsId.push(newItem?.id);

  //       return newItem;
  //     } else {
  //       const newId = uuid();
  //       const camelCaseType = newItem?.type ? toCamelCase(newItem.type) : "";
  //       newElementsId.push(newId);

  //       return {
  //         id: newId,
  //         ...newItem,
  //         props: {
  //           ...newItem?.props,
  //           id: `${camelCaseType}${newId}-${viewsLength || 0}`,
  //           key: `${camelCaseType}${newId}-${viewsLength || 0}`,
  //           testId: "",
  //         },
  //       };
  //     }
  //   });

  //   const containerItemWithNewChildren = {
  //     ...containerItem,
  //     children: [...Array.from(new Set([...(containerItem?.children || []), ...newElementsId]))],
  //   };

  //   dispatch(upsertComponents([...newElements, containerItemWithNewChildren]));
  // };

  // const updateElementConfigAndProps = (el: any, containerItem: any) => {
  //   const isCheckboxOrRadio = el?.type === ComponentItemType.CustomCheckbox || el?.type === ComponentItemType.CustomRadio;
  //   const parentAcceptsType = containerItem?.config?.acceptTypes?.includes(el?.type);
  //   let updatedProps;
  //   if (isCheckboxOrRadio && parentAcceptsType) {
  //     updatedProps = {
  //       ...el?.props,
  //       groupName: "{$comp.parent}",
  //       singleValue: "{this.{$comp.parent}.props.singleValue}",
  //       required: "{this.{$comp.parent}.props.required}",
  //       customRequiredMessageContent: "{this.{$comp.parent}.props.customRequiredMessageContent}",
  //       defaultValue: "{this.{$comp.parent}.props.defaultValue}",
  //       isChildContainerGroup: true,
  //     };
  //   } else {
  //     updatedProps = el?.props;
  //   }

  //   return { updatedProps };
  // };
  // const moveBoxFunction = (movedItem: any, monitor?: any, newPosition?: any, step: number = 1) => {
  //   // if (true) return;
  //   if (!monitor?.isOver()) {
  //     return;
  //   }
  //   const boxSize = boxItemRef?.getBoundingClientRect();

  //   const delta = monitor ? monitor.getSourceClientOffset() : document.getElementById(movedItem?.id)?.getBoundingClientRect();
  //   if (!delta) return undefined;

  //   let translateX = 0;
  //   let translateY = 0;
  //   let top = 0;
  //   let left = 0;

  //   const components = movedItem?.length ? movedItem : [movedItem];
  //   const filterComponents = components.filter(el => {
  //     return el.id;
  //   });

  //   const newComponentMove = components?.map((el: any) => {
  //     const realItem = el?.id && document.getElementById(el?.id)?.getBoundingClientRect();

  //     const itemWidth = realItem?.width / scaleFactor || el?.config?.defaultWidth;
  //     const itemHeight = realItem?.height / scaleFactor || el?.config?.defaultHeight;

  //     const realItemX = realItem?.x / scaleFactor;
  //     const realItemY = realItem?.y / scaleFactor;

  //     const adjustedBoxSizeWidth = boxSize?.width / scaleFactor;
  //     const adjustedBoxSizeHeight = boxSize?.height / scaleFactor;
  //     const adjustedBoxSizeX = boxSize?.x / scaleFactor;
  //     const adjustedBoxSizeY = boxSize?.y / scaleFactor;

  //     if (movedItem?.length) {
  //       translateX = (monitor?.getSourceClientOffset()?.x - monitor?.getInitialSourceClientOffset()?.x) / scaleFactor;
  //       translateY = (monitor?.getSourceClientOffset()?.y - monitor?.getInitialSourceClientOffset()?.y) / scaleFactor;
  //       const leftValue = Number(translateX) - Number(adjustedBoxSizeX) + Number(realItemX);
  //       left = isRTL ? adjustedBoxSizeWidth - itemWidth - leftValue : leftValue;
  //       top = Number(translateY) - Number(adjustedBoxSizeY) + Number(realItemY);
  //     } else if (realItem) {
  //       const diffX = (monitor.getDifferenceFromInitialOffset().x + (newPosition?.x || 0)) / scaleFactor;
  //       const diffY = (monitor.getDifferenceFromInitialOffset().y + (newPosition?.y || 0)) / scaleFactor;

  //       translateX = Math.round(Number(diffX + realItemX));
  //       translateY = Math.round(Number(diffY + realItemY));

  //       const leftValue = Number(translateX) - Number(adjustedBoxSizeX);
  //       left = isRTL ? adjustedBoxSizeWidth - itemWidth - leftValue : leftValue;
  //       top = Number(translateY) - Number(adjustedBoxSizeY) + Number(el?.config?.draggingOffSet || 0);
  //     } else {
  //       translateX = ((Number(delta?.x) || realItemX) + (newPosition?.x || 0)) / scaleFactor;
  //       translateY = ((Number(delta?.y) || realItemY) + (newPosition?.y || 0)) / scaleFactor;
  //       const leftValue = Number(translateX) - Number(adjustedBoxSizeX);
  //       left = isRTL ? adjustedBoxSizeWidth - itemWidth - leftValue : leftValue;
  //       top = Number(translateY) - Number(adjustedBoxSizeY) + Number(el?.config?.draggingOffSet || 0);
  //     }

  //     if (isStepperEnabled) {
  //       [left, top] = snapToStepperGrid(left, top);
  //     }

  //     const realItemWidth = realItem?.width / scaleFactor || el?.config?.defaultWidth;
  //     const realItemHeight = realItem?.height / scaleFactor || el?.config?.defaultHeight;

  //     const PLeft = left < 0 ? 0 : left + realItemWidth > adjustedBoxSizeWidth ? adjustedBoxSizeWidth - realItemWidth : left;
  //     const PTop = top < 0 ? 0 : top + realItemHeight > adjustedBoxSizeHeight ? adjustedBoxSizeHeight - realItemHeight : top;

  //     const leftPercentage = ((PLeft / adjustedBoxSizeWidth) * 100 || 0).toFixed(2) + "%";
  //     const PWidth = (itemWidth / adjustedBoxSizeWidth) * 100;
  //     const percentageWidth = PWidth > 100 ? "100%" : PWidth.toFixed(2) + "%";

  //     const PHeight = (realItemHeight / adjustedBoxSizeHeight) * 100;
  //     const percentageHeight = PHeight > 100 ? "100%" : PHeight.toFixed(2) + "%";

  //     const hasCustom = el?.config?.hasCustom;
  //     const { updatedProps } = updateElementConfigAndProps(el, containerItem);

  //     return {
  //       item: el,
  //       hasCustom: layoutBreak === "xs",
  //       left: {
  //         ...el?.left,
  //         xs: hasCustom ? el.left?.xs : left,
  //         [layoutBreak]: PLeft,
  //       },
  //       top: {
  //         ...el?.top,
  //         xs: hasCustom ? el.top?.xs : top,
  //         [layoutBreak]: PTop,
  //       },
  //       leftPercentage: {
  //         ...el?.leftPercentage,
  //         xs: hasCustom ? el.leftPercentage?.xs : leftPercentage,
  //         [layoutBreak]: leftPercentage,
  //       },
  //       config: {
  //         ...el?.config,
  //         widthPx: {
  //           ...el?.config?.widthPx,
  //           xs: hasCustom ? el?.config?.widthPx?.xs : Math.round(realItemWidth),
  //           [layoutBreak]: Math.round(realItemWidth),
  //         },
  //         heightPx: {
  //           ...el?.config?.heightPx,
  //           xs: hasCustom ? el?.config?.heightPx?.xs : Math.round(realItemHeight),
  //           [layoutBreak]: Math.round(realItemHeight),
  //         },
  //         widthPercentage: {
  //           ...el?.config?.widthPercentage,
  //           xs: el?.config?.widthPercentage?.xs || percentageWidth,
  //           [layoutBreak]: percentageWidth,
  //         },
  //         heightPercentage: {
  //           ...el?.config?.heightPercentage,
  //           xs: el?.config?.heightPercentage?.xs || percentageHeight,
  //           [layoutBreak]: percentageHeight,
  //         },
  //       },
  //       props: { ...updatedProps },
  //     };
  //   });
  //   moveMultipleBoxes(newComponentMove);
  //   return undefined;
  // };
  // const [{ canDrop, isOverCurrent }, drop] = useDrop({
  //   accept: AcceptsTargetTypes,
  //   drop: (draggedItem: any, monitor) => {
  //     if (draggedItem || !draggedItem?.parentId || !draggedItem?.id) {
  //       const item =
  //         draggedItem?.length > 0
  //           ? draggedItem.map(el => {
  //               const itemObj = selectComponentById(store.getState(), el?.id);
  //               return { ...itemObj, boxPosition: el?.boxPosition, level: el?.level };
  //             })
  //           : draggedItem;
  //       moveBoxFunction(item, monitor);
  //       return { changeLevel: "Box" };
  //     }
  //   },

  //   canDrop: (item, monitor) => canDropComponent(monitor),

  //   collect: monitor => ({
  //     canDrop: !monitor.canDrop(),
  //     isOverCurrent: monitor.isOver(),
  //   }),
  // });

  // if (containerItem?.isCustomCanvas) {
  //   dispatch(setViewBuilder({ canDrop: isOverCurrent, addToHistory: false }));
  // }

  // const canDropComponent = (monitor?: DropTargetMonitor<any, unknown>, itemVal?: any, newPosition?: any) => {
  //   const components = monitor?.getItem()?.length ? monitor?.getItem() : [monitor ? monitor?.getItem() : itemVal];

  //   const stripeElementTypes = [
  //     ComponentItemType.StripeCardNumberElement,
  //     ComponentItemType.StripeCardCvcElement,
  //     ComponentItemType.StripeCardExpiryElement,
  //     ComponentItemType.StripeAddressElement,
  //   ];

  //   const hasElementType = (elements, types) => {
  //     return elements?.some(element => types?.includes(element?.type));
  //   };

  //   const hasStripeCardElementsInContainer = hasElementType(containerItem?.children || [], stripeElementTypes);
  //   const hasStripePaymentElementsInContainer = hasElementType(containerItem?.children || [], [ComponentItemType.StripePaymentElements]);
  //   const hasStripeCardElements = hasElementType(components, stripeElementTypes);
  //   const hasStripePaymentElements = hasElementType(components, [ComponentItemType.StripePaymentElements]);

  //   if (hasStripePaymentElementsInContainer && hasStripeCardElements) {
  //     return false;
  //   } else if (hasStripeCardElementsInContainer && hasStripePaymentElements) {
  //     return false;
  //   }
  //   if (containerItem?.config?.acceptTypes?.length > 0) {
  //     const allValidComponents = components.every(component => containerItem.config.acceptTypes.includes(component?.type));
  //     return allValidComponents;
  //   }

  //   const isValid = components?.every((el: any) => {
  //     return el?.id !== containerItem?.id;
  //   });
  //   return isValid;
  // };

  // const itemsIds = selectedItemsId.map(el => ({ id: el, boxPosition: boxItemRef?.getBoundingClientRect(), level: null }));

  // const [{ isDragging, difference }, drag, preview] = useDrag({
  //   type: "Group",
  //   item: itemsIds,
  //   collect: monitor => ({
  //     isDragging: !!monitor.isDragging(),
  //     difference: monitor.getDifferenceFromInitialOffset(),
  //   }),
  // });

  // useLayoutEffect(() => {
  //   preview(getEmptyImage(), { captureDraggingState: true });
  // }, [isDragging, difference]);

  // const fetchCustomComponentData = async () => {
  //   const { data } = await axios.get(
  //     `${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${containerItem?.config?.customComponentId}`,
  //     {
  //       headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
  //     }
  //   );
  //   const ids: any = {};
  //   data?.items?.forEach((item: any) => {
  //     ids[item?.id] = item;
  //   });

  //   return ids;
  // };

  // const { data: componentData, isLoading: isCustomComponentLoading } = useQuery(
  //   ["customComponent", containerItem?.config?.customComponentId],
  //   fetchCustomComponentData,
  //   {
  //     enabled: !!containerItem?.config?.customComponentId, // Only run the query if customComponentId exists
  //     staleTime: Infinity,
  //   }
  // );

  // const componentProps = _.cloneDeep(containerItem?.props);

  // useEffect(() => {
  //   if (containerItem?.config?.cssPaths) {
  //     containerItem?.config?.cssPaths?.forEach((path: any) => {
  //       _.set(componentProps, path, containerItem?.config?.isDynamicHeight ? "unset" : _.get(componentProps, path));
  //     });
  //   }
  // }, [containerItem?.config?.isDynamicHeight]);

  const parentFlex = isParentFlex(containerItem);

  const resolvedProps = resolveSxStylingInBuilder(mapValuesRecursively({ ...containerItem?.props }), layoutBreak);

  if (stepperGroupComponentState(containerItem?.props?.key, stepperGroups, true) === StepperComponentState.FoundButNotSelected) {
    return <></>;
  }

  return (
    <>
      {[
        ComponentItemType.CustomContainer,
        ComponentItemType.FlexContainer,
        ComponentItemType.GridContainer,
        ComponentItemType.StepperContainer,
      ].includes(containerItem?.type) || true ? (
        containerItem?.isCustomCanvas ? (
          <Box
            height={"100%"}
            width={"100%"}
            ref={(e: any) => setBoxItemRef(e)}
            sx={
              joinObjects(
                {
                  position: containerItem?.config?.isDynamicHeight ? "relative" : "absolute",
                  // backgroundColor: isOverCurrent ? "rgba(255, 222, 0, 0.2)" : "unsent",
                },
                parentFlex && {
                  position: "static",
                  // backgroundColor: isOverCurrent ? "rgba(255, 222, 0, 0.2)" : theme.palette.primary.light,
                }
              ) as any
            }
          >
            {boxItemRef && (
              <Box
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
                // ref={drop}
              >
                {Component && (
                  <Component {...resolvedProps}>
                    {containerItem?.children && (
                      <>
                        {containerItem?.children
                          ?.filter((id: any) => !selectedItemsId.includes(id))
                          ?.map((id: any, index: number) => {
                            return (
                              <StripeContainer element={containerItem}>
                                <BoxComponent
                                  {...props}
                                  key={`${id}`}
                                  index={parentIndex}
                                  itemIndex={index}
                                  componentId={id}
                                  active={selectedItemsId?.includes(id)}
                                  selectedItems={containerItem?.children?.filter((id: any) => selectedItemsId.includes(id))}
                                  selectedItemsId={selectedItemsId}
                                  boxPosition={boxItemRef?.getBoundingClientRect()}
                                  className={"mouse-select__selectable"}
                                  layoutBreak={layoutBreak}
                                  setView={setView}
                                  setInnerComponentDropping={setInnerComponentDropping}
                                  activeComponent={activeComponent}
                                  formMoveBoxFunction={formMoveBoxFunction}
                                  // parentBoxMoveBoxComponent={moveBoxFunction}
                                />
                              </StripeContainer>
                            );
                          })}
                      </>
                    )}
                    {!!containerItem?.children?.filter((id: any) => selectedItemsId.includes(id))?.length && (
                      <Box sx={{ display: "contents" }} ref={() => {}} className={"mouse-select__selectable"}>
                        {containerItem?.children
                          ?.filter((id: any) => selectedItemsId.includes(id))
                          ?.map((id: any, index: number) => {
                            return (
                              <BoxComponent
                                {...props}
                                key={`${id}`}
                                index={parentIndex}
                                itemIndex={index}
                                componentId={id}
                                active={selectedItemsId?.includes(id)}
                                canDrag={!(selectedItemsId?.length || containerItem?.children?.some((id: any) => id === activeComponent))}
                                // isDragging={isDragging}
                                selectedItems={containerItem?.children?.filter((id: any) => selectedItemsId.includes(id))}
                                selectedItemsId={selectedItemsId}
                                boxPosition={boxItemRef?.getBoundingClientRect()}
                                className={"mouse-select__selectable"}
                                layoutBreak={layoutBreak}
                                setView={setView}
                                setInnerComponentDropping={setInnerComponentDropping}
                                activeComponent={activeComponent}
                                formMoveBoxFunction={formMoveBoxFunction}
                                // parentBoxMoveBoxComponent={moveBoxFunction}
                              />
                            );
                          })}
                      </Box>
                    )}
                  </Component>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <DragElement
            key={`${parentIndex}`}
            {...props}
            selectedItemsId={selectedItemsId}
            itemIndex={itemIndex}
            formMoveBoxFunction={formMoveBoxFunction}
            parentBoxMoveBoxComponent={parentBoxMoveBoxComponent}
            scaleFactor={scaleFactor}
            parentIndex={parentIndex}
            componentId={componentId}
            // dropParent={drop}
            onLayout={onLayout}
            setBoxItemRef={setBoxItemRef}
            boxItemRef={boxItemRef}
            stepperGroups={stepperGroups}
            $views={views}
            path={path}
          >
            {/* <Box
              height={"100%"}
              width={"100%"}
              ref={(e: any) => setBoxItemRef(e)}
              sx={
                joinObjects(
                  {
                    position: containerItem?.config?.isDynamicHeight ? "relative" : "absolute",
                    backgroundColor: containerItem?.config?.flexCanvas
                      ? "unset"
                      : isOverCurrent
                      ? "rgba(255, 222, 0, 0.2)"
                      : theme.palette.primary.light,
                  },
                  parentFlex && {
                    position: "static",
                    backgroundColor: isOverCurrent ? "rgba(255, 222, 0, 0.2)" : theme.palette.primary.light,
                  }
                ) as any
              }
            >
              {boxItemRef && (
                <Box
                  sx={{
                    position: "relative",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  ref={drop}
                >
                  {Component && (
                    <Component {...resolvedProps}>
                      {containerItem?.children && (
                        <>
                          {containerItem?.children
                            ?.filter((id: any) => !selectedItemsId.includes(id))
                            ?.map((id: any, index: number) => {
                              return (
                                <StripeContainer element={containerItem}>
                                  <BoxComponent
                                    {...props}
                                    key={`${id}`}
                                    index={parentIndex}
                                    itemIndex={index}
                                    componentId={id}
                                    active={selectedItemsId?.includes(id)}
                                    selectedItems={containerItem?.children?.filter((id: any) => selectedItemsId.includes(id))}
                                    selectedItemsId={selectedItemsId}
                                    boxPosition={boxItemRef?.getBoundingClientRect()}
                                    className={"mouse-select__selectable"}
                                    layoutBreak={layoutBreak}
                                    setView={setView}
                                    setInnerComponentDropping={setInnerComponentDropping}
                                    activeComponent={activeComponent}
                                    formMoveBoxFunction={formMoveBoxFunction}
                                    parentBoxMoveBoxComponent={moveBoxFunction}
                                  />
                                </StripeContainer>
                              );
                            })}
                        </>
                      )}
                      {!!containerItem?.children?.filter((id: any) => selectedItemsId.includes(id))?.length && (
                        <Box sx={{ display: "contents" }} ref={drag} className={"mouse-select__selectable"}>
                          {containerItem?.children
                            ?.filter((id: any) => selectedItemsId.includes(id))
                            ?.map((id: any, index: number) => {
                              return (
                                <BoxComponent
                                  {...props}
                                  key={`${id}`}
                                  index={parentIndex}
                                  itemIndex={index}
                                  componentId={id}
                                  active={selectedItemsId?.includes(id)}
                                  canDrag={!(selectedItemsId?.length || containerItem?.children?.some((id: any) => id === activeComponent))}
                                  isDragging={isDragging}
                                  selectedItems={containerItem?.children?.filter((id: any) => selectedItemsId.includes(id))}
                                  selectedItemsId={selectedItemsId}
                                  boxPosition={boxItemRef?.getBoundingClientRect()}
                                  className={"mouse-select__selectable"}
                                  layoutBreak={layoutBreak}
                                  setView={setView}
                                  setInnerComponentDropping={setInnerComponentDropping}
                                  activeComponent={activeComponent}
                                  formMoveBoxFunction={formMoveBoxFunction}
                                  parentBoxMoveBoxComponent={moveBoxFunction}
                                />
                              );
                            })}
                        </Box>
                      )}
                    </Component>
                  )}
                </Box>
              )}
            </Box> */}
          </DragElement>
        )
      ) : (
        // <DragElement
        //   key={`${parentIndex}`}
        //   {...props}
        //   selectedItemsId={selectedItemsId}
        //   boxItemRef={(e: any) => setBoxItemRef(e)}
        //   itemIndex={itemIndex}
        //   formMoveBoxFunction={formMoveBoxFunction}
        //   parentBoxMoveBoxComponent={parentBoxMoveBoxComponent}
        //   scaleFactor={scaleFactor}
        //   parentIndex={parentIndex}
        //   componentId={componentId}
        //   dropParent={drop}
        //   setBoxItemRef={setBoxItemRef}
        //   $views={views}
        //   stepperGroups={stepperGroups}
        // >
        //   {containerItem?.config?.customComponent ? (
        //     <>
        //       {!isCustomComponentLoading && (
        //         <BXEngine
        //           path={path}
        //           auth={{}}
        //           componentName={componentData?.[containerItem?.config?.customComponentId]?.name}
        //           componentData={{
        //             ...containerItem?.props,
        //             config: chartsNames.includes(containerItem?.type)
        //               ? containerItem?.configData
        //               : componentData?.[containerItem?.config?.customComponentId]?.config,
        //             disableApis: true,
        //           }}
        //           layout={[{ id: containerItem?.id, type: containerItem?.type } as any]}
        //           onLayout={(values: any) =>
        //             onLayout(values, { skip: !!componentData?.[containerItem?.config?.customComponentId]?.config })
        //           }
        //         />
        //       )}
        //     </>
        //   ) : containerItem?.config?.BxComponent ? (
        //     <BXEngine
        //       path={path}
        //       auth={{}}
        //       layout={[{ id: containerItem?.id, type: containerItem?.type } as any]}
        //       componentData={{
        //         ...containerItem?.props,
        //         config: containerItem?.configData,
        //         disableApis: true,
        //       }}
        //     />
        //   ) : (
        //     Component && <Component {...resolvedProps} $config={containerItem?.config} $views={views} stepperGroups={stepperGroups} />
        //     //////////////////////////////////////////////////////////////////////////////
        //     // <Component
        //     //   {...containerItem?.props}
        //     //   $config={containerItem?.config}
        //     //   $views={views}
        //     //   $children={containerItem?.children}
        //     //   $builderProps={{
        //     //     ...props,
        //     //     index: parentIndex,
        //     //     selectedItems: containerItem?.children?.filter((el: any) => selectedItemsId.includes(el?.id)),
        //     //     selectedItemsId: selectedItemsId,
        //     //     boxPosition: boxItemRef?.getBoundingClientRect(),
        //     //     className: "mouse-select__selectable_builder",
        //     //     layoutBreak: { layoutBreak },
        //     //     setView: setView,
        //     //     setInnerComponentDropping: setInnerComponentDropping,
        //     //     activeComponent: activeComponent,
        //     //     formMoveBoxFunction: formMoveBoxFunction,
        //     //     parentBoxMoveBoxComponent: moveBoxFunction,
        //     //   }}
        //     // />
        //     //////////////////////////////////////////////////////////////////////////////
        //   )}
        // </DragElement>
        <></>
      )}
    </>
  );
};

export default memo(BoxComponent);
