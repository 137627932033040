import _, { cloneDeep } from "lodash";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";

export const stepperMigration = (collections: any) => {
  collections.forEach(collection => {
    collection.pages.forEach(page => {
      page.views.forEach(view => {
        if (view.type === "form-builder") {
          let stepperComponentMap = {};
          if (view.stepperGroups) {
            stepperComponentMap = cloneDeep(view.stepperGroups);
            delete view.stepperGroups;
          }
          const previewPageMap = {};
          const traverseChildren = (children: any[]) => {
            if (!children) return;
            children.forEach(child => {
              if (_.has(previewPageMap, child.id)) {
                child.config.isVisiblePreviewByStepper = previewPageMap[child.id];
              }

              if (child.type === ComponentItemType.StepperContainer) {
                child.config.stepperGroup = stepperComponentMap[child.id];
                child.config.stepperGroup.pages.forEach((page, index) => {
                  if (child.config.stepperGroup.previewIndex === index) {
                    previewPageMap[child.id] = true;
                  } else {
                    previewPageMap[child.id] = false;
                  }
                });
              }
              if (child.children && child.children.length > 0) {
                traverseChildren(child.children);
              }
            });
          };
          traverseChildren(view?.dataSource?.formBuilder);
        }
      });
    });
  });
};
