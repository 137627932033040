import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IconAlertTriangle } from "@tabler/icons-react";
import React, { FC } from "react";
import BXModal from "src/components/BXUI/Modal";
import useNavigationHandlers from "src/hooks/useNavigationHandlers";

interface MissingIconsModalProps {
  open: boolean;
  groupedIcons?: Record<string, { name: string; path: string; link?: any }[]>;
  onClose: () => void;
  onContinue?: () => void;
}

const MissingIconsModal: FC<MissingIconsModalProps> = ({ open, groupedIcons, onClose, onContinue }) => {
  const hasIcons = groupedIcons && Object.keys(groupedIcons).length > 0;
  const {
    openViewBuilderComponent,
    openLayoutBuilderComponent,
    openPageBuilderComponent,
    openCollectionModal,
    openPageModal,
    openViewModal,
    openTabModalComponent,
    openNestedTabModal,
    openSecondLevelModel,
    openThirdLevelModalsModal,
  } = useNavigationHandlers();

  const handleNavigate = (link: any) => {
    const { appId, collectionId, pageId, viewId, componentId, layoutId, tabName, subTabName, rowConfigId, actionConfigId } = link;

    if (appId && collectionId && pageId && viewId && tabName && rowConfigId && actionConfigId) {
      openThirdLevelModalsModal(appId, collectionId, pageId, viewId, tabName, rowConfigId, actionConfigId);
    } else if (appId && collectionId && pageId && viewId && tabName && rowConfigId) {
      openSecondLevelModel(appId, collectionId, pageId, viewId, tabName, rowConfigId);
    } else if (appId && collectionId && pageId && viewId && tabName && subTabName) {
      openNestedTabModal(appId, collectionId, pageId, viewId, tabName, subTabName);
    } else if (appId && collectionId && pageId && viewId && tabName) {
      openTabModalComponent(appId, collectionId, pageId, viewId, tabName);
    } else if (appId && collectionId && pageId && viewId && componentId) {
      openViewBuilderComponent(appId, collectionId, pageId, viewId, componentId);
    } else if (appId && collectionId && componentId) {
      openPageBuilderComponent(appId, collectionId, componentId);
    } else if (appId && layoutId && componentId) {
      openLayoutBuilderComponent(appId, layoutId, componentId);
    } else if (appId && collectionId && pageId && viewId) {
      openViewModal(appId, collectionId, pageId, viewId);
    } else if (appId && collectionId && pageId) {
      openPageModal(appId, collectionId, pageId);
    } else if (appId && collectionId) {
      openCollectionModal(appId, collectionId);
    }
  };

  return (
    <BXModal
      open={open}
      title={"Missing Media Assets"}
      label='Missing Media Assets'
      icon={<IconAlertTriangle />}
      onClose={onClose}
      maxWidth='md'
    >
      <DialogContent>
        {!groupedIcons ? (
          <CircularProgress />
        ) : hasIcons ? (
          <>
            <p>The following media assets are missing:</p>
            <Table
              sx={{
                border: 1,
                borderColor: "grey.300",
                borderCollapse: "collapse",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: 1, borderColor: "grey.300" }}>Media Type</TableCell>
                  <TableCell sx={{ border: 1, borderColor: "grey.300" }}>Media Name</TableCell>
                  <TableCell sx={{ border: 1, borderColor: "grey.300" }}>Media Path</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(groupedIcons).map(([type, icons]) => (
                  <React.Fragment key={type}>
                    {icons.map((icon, index) => (
                      <TableRow key={`${type}-${index}`}>
                        {index === 0 && (
                          <TableCell sx={{ border: 1, borderColor: "grey.300" }} rowSpan={icons.length}>
                            {type}
                          </TableCell>
                        )}
                        <TableCell sx={{ border: 1, borderColor: "grey.300" }}>{icon.name}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: "grey.300", cursor: "pointer" }}>
                          <Link
                            onClick={e => {
                              e.preventDefault();
                              handleNavigate(icon.link);
                            }}
                            underline='hover'
                            color='primary'
                          >
                            {icon.path}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <p>No media assets are currently missing.</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} color='primary' variant='contained'>
          Continue
        </Button>
      </DialogActions>
    </BXModal>
  );
};

export default MissingIconsModal;
