import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { IconPlaylistAdd, IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, FieldValues, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { selectComponentPropertyByPath } from "src/features/builder/selectors";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import DraggableRow from "../AppBuilder/forms/DraggableRow";
import OASSelector from "../OASSelector";
import GroupForm from "./GroupForm";

type CreateChartFormProps = {
  onSave: SubmitHandler<FieldValues>;
  type?: string;
  componentId: string;
  dataPropPath: string;
};

const dataSourceTypes = ["fixedData", "API"];

function isNumbersSeparatedByComma(str) {
  const regex = /^-?\d+(\.\d+)?(\s*,\s*-?\d+(\.\d+)?)*$/;
  return regex.test(str);
}

const isCharsSeparatedByComma = str => {
  return /^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$/.test(str);
};

function canTypeHaveGroup(type: string) {
  return type === "SplineChart" || type === "ColumnChart";
}

// const separatedDataWithComma = yup
//   .string()
//   .nullable()
//   .test("is-valid-number-list", "invalid data", value => {
//     if (value) return isNumbersSeparatedByComma(value);
//     return false;
//   });

const separatedCharWithComma = yup
  .string()
  .nullable()
  .test("is-valid-number-list", "invalid data", value => {
    if (value) return isCharsSeparatedByComma(value);
    return false;
  });

const groupSeriesSchema = yup.array().of(
  yup.object().shape({
    type: yup.string().test("is-required-when-group-enabled", "Required field", (value, context: any) => {
      const [, parent] = context?.from;
      return !!value || !parent?.value?.enableGroup || parent?.value?.sourceType !== "fixedData";
    }),
    data: yup.array().of(
      yup.object().shape({
        name: yup.string().test("is-required-when-fixedData-enabled", "Required field", (value, context: any) => {
          const [, , parent] = context?.from;
          return !!value || parent?.value?.sourceType !== "fixedData";
        }),
        dataString: yup
          .string()
          .nullable()
          .test("is-required-when-fixedData-enabled", "Required field", (value, context: any) => {
            const [, , parent] = context?.from;
            return (!!value && isNumbersSeparatedByComma(value)) || parent?.value?.sourceType !== "fixedData";
          }),
      })
    ),
  })
);

const defaultSeriesSchema = yup.array().of(
  yup.object().shape({
    name: yup.string().required("Required field"),
    dataString: yup
      .string()
      .nullable()
      .test("is-required-when-fixedData-enabled", "Required field", (value, context: any) => {
        const [, parent] = context?.from;
        return (!!value && isNumbersSeparatedByComma(value)) || parent?.value?.sourceType !== "fixedData";
      }),
  })
);

const schema = yup.object().shape({
  title: yup.string().required(),
  subheader: yup.string(),
  total: yup.string(),
  enableGroup: yup.boolean(),
  type: yup.string().default("Line Chart").required(),
  categoriesString: yup.string().when(["type"], {
    is: type => canTypeHaveGroup(type),
    then: separatedCharWithComma,
    otherwise: yup.string().notRequired(),
  }),
  series: yup.array().when(["type"], {
    is: type => canTypeHaveGroup(type),
    then: groupSeriesSchema,
    otherwise: defaultSeriesSchema,
  }),
  sourceType: yup.string().default("fixedData"),
  apiMode: yup.object().shape({
    apiUrl: yup.string().notRequired(),
    groupTitle: yup.string().notRequired(),
    responseGroupKey: yup.string().notRequired(),
    fixedLines: yup.boolean().notRequired(),
    lineTitle: yup.string().notRequired(),
    responseLineKey: yup.string().notRequired(),
    valuesKey: yup.string().notRequired(),
    payload: yup
      .object()
      .shape({
        uri: yup.string().notRequired(),
        isGraphQL: yup.string().notRequired(),
        method: yup.string().notRequired(),
      })
      .notRequired(),
    chartLineData: yup.array().of(
      yup.object().shape({
        name: yup.string().notRequired().nullable(),
        data: yup.string().notRequired().nullable(),
      })
    ),
  }),
});

const defaultValueObject = {
  title: "",
  subheader: "",
  total: "",
  type: "Line Chart",
  categories: [],
  categoriesString: "",
  defaultWidth: 300,
  defaultHeight: 400,
  enableGroup: true,
  series: [],
  sourceType: "fixedData",
  apiMode: {
    apiUrl: "",
    groupTitle: "",
    responseGroupKey: "",
    fixedLines: false,
    lineTitle: "",
    responseLineKey: "",
    valuesKey: "",
    payload: {
      uri: "",
      isGraphQL: "",
      method: "",
    },
    chartLineData: [],
  },
};

const charts = ["SplineChart", "PieChart", "ColumnChart", "CircularChart"];

const chartsNameMapReversed = new Map([
  ["SplineChart", "Line Chart"],
  ["CircularChart", "Circular Chart"],
  ["PieChart", "Pie Chart"],
  ["ColumnChart", "Bar Chart"],
]);

export const CreateChartForm: FC<CreateChartFormProps> = ({ onSave = _.noop, type, componentId, dataPropPath }) => {
  const data = useSelector(state => selectComponentPropertyByPath(state, componentId, dataPropPath));

  let defaultValues = data && !_.isEmpty(data) ? data : defaultValueObject;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });
  useEffect(() => {
    setValue("type", type || data?.type || "SplineChart");
    setValue("categoriesString", watch("categories")?.toString());
  }, []);
  const canHaveGroup = canTypeHaveGroup(watch("type"));

  useEffect(() => {
    if (canHaveGroup) {
      setValue("defaultWidth", 500);
      setValue("defaultHeight", 370);
    } else {
      setValue("defaultWidth", 300);
      setValue("defaultHeight", 400);
    }
  }, [canHaveGroup, setValue]);

  const apiFixedLinesFormArray = useFieldArray({
    control,
    name: "apiMode.chartLineData",
  });
  const handleAddFixedLinesAPI = () => {
    const newLine = {
      name: "",
      data: null,
      id: uuid(),
    };

    apiFixedLinesFormArray.append(newLine);
  };
  const { palette } = useTheme();

  return (
    <Grid container>
      {!type && (
        <Grid item xs={4} mt={2}>
          <BXInput select label='Type' name={"type"} control={control}>
            {charts.map((item: any, index: any) => (
              <MenuItem key={index} value={item}>
                {chartsNameMapReversed.get(item)}
              </MenuItem>
            ))}
          </BXInput>
        </Grid>
      )}

      <Grid item xs={12} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BXInput variant='outlined' label='Title' name={"title"} control={control} error={errors?.title} />
          </Grid>
          <Grid item xs={6}>
            <BXInput label='Description' name={"subheader"} control={control} />
          </Grid>
        </Grid>
      </Grid>

      {canHaveGroup && (
        <Grid item xs={12} my={2}>
          <Typography variant='h4' gutterBottom ml={1} mb={2}>
            X-Axis
          </Typography>
          <BXInput
            name={"categoriesString"}
            label='X-Axis'
            control={control}
            value={watch("categories")}
            onChange={() => {
              setValue("categories", watch("categoriesString")?.split(","));
            }}
            error={errors?.categoriesString}
            placeholder='X-Axis Values (comma-separated)'
            InputLabelProps={{ style: { fontSize: "12px" } }}
            style={{ backgroundColor: `${palette?.background?.paper}` }}
            variant='outlined'
            fullWidth
          />
        </Grid>
      )}

      <Grid>
        <Grid container xs={12}>
          <Grid item ml={1} xs={6} my={2}>
            <Typography variant='h4' gutterBottom>
              {canHaveGroup ? "Y-Axis" : "Data"}
            </Typography>
          </Grid>
          <Grid item ml={1} xs={6} marginBottom={2}>
            {dataSourceTypes.map(type => (
              <Controller
                key={type}
                control={control}
                name='sourceType'
                render={({ field: { onChange, value } }) => (
                  <ToggleButtonGroup color='primary' value={value} exclusive>
                    <ToggleButton
                      style={{ marginRight: 12 }}
                      value={type}
                      name='sourceType'
                      onClick={() => {
                        onChange(type);
                      }}
                      selected={value === type}
                      key={type}
                    >
                      {type === "fixedData" ? "Fixed Data" : type}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            ))}
          </Grid>
          {watch("sourceType") === "API" && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ApiInput
                    watch={watch}
                    apiLabel='API URL'
                    control={control}
                    path='apiMode.payload'
                    pathURL='apiMode.apiUrl'
                    OASElement={
                      <OASSelector
                        swaggerProps={{
                          template: watch(".template"),
                          formBuilder: watch("apiMode.formBuilder"),
                          onSuccess: (values: any, data: any) => {
                            setValue("apiMode.template", values.template);
                            setValue("apiMode.url", data?.path);
                            setValue("apiMode.apiUrl", data?.path);
                            setValue("apiMode.payload.uri", data?.path);
                            setValue("apiMode.payload.method", (data?.method as string).toUpperCase());
                            setValue("apiMode.payload.body", formatJSON(JSON.stringify(data?.body)) || {});
                            setValue("apiMode.dataEntry", values.dataEntry);
                          },
                        }}
                      />
                    }
                    getValues={getValues}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BXInput name={"apiMode.responseLineKey"} control={control} fullWidth label={"Response line Key"} />
                </Grid>
                {canHaveGroup && (
                  <>
                    <Grid item xs={6}>
                      <BXInput name={"apiMode.groupTitle"} control={control} fullWidth label={"Group Title"} />
                    </Grid>
                    <Grid item xs={6}>
                      <BXInput label='Response Group Key' name={"apiMode.responseGroupKey"} control={control} />
                    </Grid>
                  </>
                )}

                {!watch("apiMode.fixedLines") && (
                  <>
                    <Grid item xs={6}>
                      <BXInput name={"apiMode.lineTitle"} control={control} fullWidth label={canHaveGroup ? "Line Title" : "Title"} />
                    </Grid>

                    <Grid item xs={6}>
                      <BXInput name={"apiMode.valuesKey"} control={control} fullWidth label={"Values Key"} />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid container alignItems='center' mb={1} mt={2} xs={6}>
                <BXSwitch control={control} name='apiMode.fixedLines' label='Fixed lines' />
              </Grid>

              {watch("apiMode.fixedLines") && (
                <Grid>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell width={250}>{canHaveGroup ? "Line Title" : "Title"}</TableCell>
                          <TableCell width={500}>{canHaveGroup ? "Y-Axis Values" : "Data"}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {apiFixedLinesFormArray.fields?.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <Typography textAlign={"center"}>No Lines</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {apiFixedLinesFormArray.fields?.map((line, lineIndex) => (
                          <DraggableRow
                            component={TableRow}
                            key={line.id}
                            id={line.id}
                            index={lineIndex}
                            name='table-columns'
                            moveElement={apiFixedLinesFormArray.move}
                          >
                            <TableCell>{lineIndex + 1}.</TableCell>
                            <TableCell width={250}>
                              <BXInput
                                fullWidth
                                label={canHaveGroup ? "Line Title" : "Title"}
                                error={(errors?.apiMode as any)?.chartLineData?.[lineIndex]?.name}
                                name={`apiMode.chartLineData[${lineIndex}].name`}
                                control={control}
                              />
                            </TableCell>
                            <TableCell width={500}>
                              <BXInput
                                fullWidth
                                label={"Data"}
                                error={(errors?.apiMode as any)?.chartLineData?.[lineIndex]?.data}
                                name={`apiMode.chartLineData[${lineIndex}].data`}
                                control={control}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  apiFixedLinesFormArray.remove(lineIndex);
                                }}
                              >
                                <IconTrashX color='red' />
                              </IconButton>
                            </TableCell>
                          </DraggableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container justifyContent={"center"} alignItems='center' mt={2} mb={2}>
                    <Button
                      variant='outlined'
                      startIcon={<IconPlaylistAdd />}
                      onClick={() => {
                        handleAddFixedLinesAPI();
                      }}
                    >
                      Add Line
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          {watch("sourceType") === "fixedData" && (
            <>
              <Grid item style={{ width: "100%" }}>
                {canHaveGroup && (
                  <Grid container mt={2} mb={2} xs={12}>
                    <Grid container alignItems='center' mb={1} xs={6}>
                      <BXSwitch control={control} name='enableGroup' label='Enable Group' />
                    </Grid>
                  </Grid>
                )}
                <GroupForm
                  control={control}
                  watch={watch}
                  canHaveGroup={canHaveGroup}
                  isGroupEnabled={watch("enableGroup")}
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
              <Button onClick={handleSubmit(onSave)} variant='contained' aria-label='save'>
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
