// src/components/AppController.tsx
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppState } from "src/features/appState/hooks";
import { clearErrors, registerValidation, setAppError } from "../../../features/appState/appStateSlice";
import { useAppValue } from "../../../features/appState/selectors";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { getControllerDefaultValue } from "../FormBuilder/utils";

interface AppControllerProps {
  /*
   * The 'name' should represent the relative path of the field, e.g., 'page -> view -> field'.
   */
  name: string;
  defaultValue?: any;
  validate?: { [key: string]: (value: any) => true | string };
  isDisabledDirtyField?: boolean;
  disabled?: boolean;
  matchValidationField?: string;
  replaceDataPlaceholdersProps?: any;
  viewNameOption?: any;
  element?: any;
  render: (props: { value: any; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; error: string }) => JSX.Element;
}

const AppController: React.FC<AppControllerProps> = ({
  name,
  element,
  viewNameOption,
  defaultValue,
  validate,
  isDisabledDirtyField,
  disabled,
  matchValidationField,
  replaceDataPlaceholdersProps,
  render,
}) => {
  const stateName = `${name}.state`;
  const dispatch = useDispatch();
  const value = useAppValue(stateName) || "";
  const error = useAppValue(`${name}.errors.error`) || "";
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders(viewNameOption);
  const { setValue } = useAppState();
  const { item, viewsState, pageId, __data, currentApp } = replaceDataPlaceholdersProps;
  const replaceValue = !_.isObject(defaultValue)
    ? replaceDataPlaceholders({
        queryString: defaultValue,
        ...replaceDataPlaceholdersProps,
      })
    : replaceDataPlaceholdersRecursively({ obj: defaultValue, ...replaceDataPlaceholdersProps });

  useEffect(() => {
    if (validate && !disabled) {
      registerValidation(name, validate);
    }
  }, []);

  useEffect(() => {
    const resolvedDefaultValue = getControllerDefaultValue(element, item, viewsState, pageId, __data, currentApp, null, null, replaceValue, true);
    setValue(stateName, resolvedDefaultValue, {
      isDisabledDirtyField: true,
    });
  }, [replaceValue]);

  const handleChange = (e: any, disabledDirty?: boolean) => {
    if (disabled) return;
    let newValue;

    // Check if `e` is an event or a direct value
    if (e && e.target && typeof e.target.value !== "undefined") {
      newValue = e.target.value; // It's an event, get the value from the event
      e.stopPropagation();
      e.preventDefault();
    } else {
      newValue = e; // It's a direct value, use it as it is
    }

    // Update the value in the state
    setValue(`${stateName}`, newValue, { isDisabledDirtyField: disabledDirty ?? isDisabledDirtyField });

    // Validate the input field if a validation function is provided
    if (validate) {
      let validationError = "";

      for (const ruleKey in validate) {
        if (validate.hasOwnProperty(ruleKey)) {
          const rule = validate[ruleKey];
          const result = rule(newValue);
          if (result !== true && result !== undefined) {
            validationError = result as string; // Get the error message from the validation
            break; // Stop on the first validation error
          }
        }
      }
      dispatch(setAppError({ name: name, error: validationError }));
    }
  };

  //If the field is a has match validation against another field, rerun validation function on change of target field
  useEffect(() => {
    if (matchValidationField !== undefined) {
      const shouldMatchRule = validate?.shouldMatch;
      const matchResult = shouldMatchRule?.(value);

      if (matchResult !== true && matchResult !== undefined) {
        dispatch(setAppError({ name, error: matchResult as string }));
      } else if (matchResult === true) {
        //If the fields match, remove the error field
        dispatch(clearErrors({ name }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchValidationField]);

  return render({ value, onChange: handleChange, error: typeof error === "object" ? "" : error });
};

export { AppController };
