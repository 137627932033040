import _ from "lodash";
import { BXApp } from "src/types/BXAppType";
import { UIElement } from "src/types/UIElement";
import { compareVersions } from "src/utils/generalUtils";
import { compressData, decompressData } from "src/utils/services";
import { setStepperParents } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { stepperMigration } from "./1.0.3";

export function migrateTo1_0_0(app: BXApp) {
  const iterateValues = (obj: any, path?: string) => {
    _.forEach(obj, (value, key) => {
      const currentPath = path ? path + `[${key}]` : key;
      if (_.isObject(value)) {
        iterateValues(value, currentPath);
      } else {
        if (typeof value === "string") {
          obj[key] = value.replace(/\{(?!\s*\})([^{},$]+)\}/g, (match, p1) => {
            if (p1.includes("#")) {
              const hashes = p1?.split(".")?.[0]?.split("")?.join(".");
              return `{${hashes}.data${p1?.replace(p1?.split(".")?.[0], "")}}`;
            } else {
              return `{this.data.${p1.split(".").join(".")}}`;
            }
          });
        }
      }
    });
  };
  iterateValues(app);

  app?.templateConfig?.collections?.map(collection => {
    collection?.pages?.map(page => {
      page?.views?.map(view => {
        if (view?.info) {
          view.info.viewName = view.info?.name?.replace(/[^0-9a-zA-Z\-]/g, "-");
        }
      });
    });
  });
  _.set(app, "templateConfig.appVersion", "1.0.0");
}

export function migrateTo1_0_1(app: BXApp) {
  _.set(app, "appConfig.auth.authApi", {
    method: "POST",
    headers: {
      authorization: "Bearer {this.device.accessToken}",
    },
    body: '{\n    "email": "{this.login.email}",\n    "password": "{this.login.password}",\n    "recaptcha": "{this.login.recaptcha}"\n}',
    uri: app?.appConfig?.auth?.authApi as string,
  });

  _.set(app, "appConfig.auth.deviceApi", {
    method: "POST",
    body: '{\n    "appVersion": "{this.config.appVersion}",\n    "deviceType": "{this.config.deviceType}",\n    "deviceUDID": "{this.config.deviceUDID}",\n    "osVersion": "{this.config.osVersion}",\n    "timeZone": "{this.config.timeZone}"\n}',
    uri: app?.appConfig?.auth?.deviceApi as string,
  });

  _.set(app, "templateConfig.appVersion", "1.0.1");
}

export function migrateTo1_0_2(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  traverseApp(app);
  applyMigrationsFunctionLogic(app);
  _.set(app, "templateConfig.appVersion", "1.0.2");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };

  return compressedApp;
}

function migrateBuilderDimensions(app: BXApp) {
  const changeSizeProperties = obj => {
    const populateProperties = element => {
      if (!element || !element.config) return;

      const ensureResponsiveValue = (key, defaultLg, defaultXs) => {
        const currentValue = element.config[key];

        if (typeof currentValue !== "object") {
          element.config[key] = {
            lg: currentValue ?? defaultLg,
            xs: currentValue ?? defaultXs,
          };
        } else {
          element.config[key] = {
            lg: currentValue?.lg ?? defaultLg,
            xs: currentValue?.xs ?? defaultXs,
          };
        }
      };

      ensureResponsiveValue("fixedWidth", true, true);
      ensureResponsiveValue("isDynamicWidth", false, false);
      ensureResponsiveValue("isPercentageHeight", false, false);
      ensureResponsiveValue("isDynamicHeight", false, false);

      if (Array.isArray(element.children)) {
        element.children.forEach(child => populateProperties(child));
      }
    };

    if (Array.isArray(obj)) {
      obj.forEach(item => populateProperties(item));
    } else {
      populateProperties(obj);
    }
  };

  let formBuilderViews: UIElement[] = [];

  app.templateConfig?.collections?.forEach(collection => {
    collection.pages?.forEach(page => {
      page.views?.forEach(view => {
        formBuilderViews.push(view);
      });
    });
  });

  for (const view of formBuilderViews) {
    if (view.type === "form-builder") {
      changeSizeProperties(view.dataSource?.formBuilder);
    }
  }
}

export function migrateTo1_0_3(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  const templateCollections = _.get(app, "templateConfig.collections", []);
  const upTemplateCollections = _.get(app, "upTemplateConfig.collections", []);

  migrateBuilderDimensions(app);
  stepperMigration(templateCollections);
  stepperMigration(upTemplateCollections);

  _.set(app, "templateConfig.appVersion", "1.0.3");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };
  return compressedApp;
}

function flipSyntaxByKeyword(placeholder, keyword) {
  placeholder = placeholder.replace(/[{}]/g, "");
  const keys = placeholder?.split(".");
  const keyWordIndex = keys?.findIndex(key => key === keyword);
  [keys[keyWordIndex], keys[keyWordIndex + 1]] = [keys[keyWordIndex + 1], keys[keyWordIndex]];

  return `{${keys.join(".")}}`;
}

function convertToNewSyntax(data, path) {
  if (typeof data !== "string") {
    return data;
  }

  const regex = /\{([^{}]+)\}/g;
  const placeholdersList = data.match(regex);
  if (placeholdersList === null || placeholdersList.length === 0) {
    return data;
  }

  let modifiedData = data;
  const targetPathPattern = /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.columns\[\d+\]\.source$/;
  const targetPathActions =
    /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.actionsMap\.default\[\d+\]\.actionConfig\.dialog\.message$/;
  const targetPathActionCondition =
    /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.actionsMap\.default\[\d+\]\.actionConfig\.actionCondition$/;
  const selectedItemRegex = /{([^{}]+)_selectedItem([^{}]*)}/;
  const actionConfigPathPattern = /^templateConfig(?:\.\w+|\[\d+\])*(?:\.actionConfig)?\.(linkUrl|condition)$/;
  const requestStatusCodePattern = /\{this\.response\.([^{}]+)\.requestStatusCode\}/;
  const dataCompPattern = /{this\.dataComp\.(\w+)(?:\.(\w+(?:\.\w+)*))?}/;
  const dataSourcePathPattern = /\.dataSource\./;

  // Be careful with the order of the syntax cases in the switch statement
  placeholdersList.forEach(placeholder => {
    if (targetPathActionCondition.test(path) && placeholder.startsWith("{this.data.")) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{browser.localStorage.")) {
      const remainingPath = placeholder.slice("{browser.localStorage.".length, -1);
      const newPlaceholder = `{$global.localStorage.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }

    if (targetPathPattern.test(path) && placeholder.startsWith("{this.data.")) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (targetPathActions.test(path) && placeholder.startsWith("{this.data.")) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    // <Stepper-Case>
    const stepperPattern = /{this\.state\.(\w+)\.(ref|index)}/;
    const stepperMatch = placeholder.match(stepperPattern);
    if (stepperMatch) {
      const stepperName = stepperMatch[1];
      const property = stepperMatch[2];
      const newPlaceholder = `{this.${stepperName}.state.selected.${property}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }
    // </Stepper-Case>

    // Handle {this.state.<fieldRef>.maxItems} => {this.<fieldRef>.props.repeated.maxItems}
    const maxItemsPattern = /{this\.state\.(\w+)\.maxItems}/;
    const maxItemsMatch = placeholder.match(maxItemsPattern);
    if (maxItemsMatch) {
      const fieldRef = maxItemsMatch[1];
      const newPlaceholder = `{this.${fieldRef}.props.repeated.maxItems}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }

    // Handle {this.state.<compRef>.value} => {this.<compRef>.state}
    const valuePattern = /{this\.state\.(\w+)\.value}/;
    const valueMatch = placeholder.match(valuePattern);
    if (valueMatch) {
      const compRef = valueMatch[1];
      const newPlaceholder = `{this.${compRef}.state}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }

    // <Repeated-Cases>
    if (placeholder.match(/\{this\.state\.(\w+)\[(\d+)\]\.(\w+)\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\.(\w+)\[(\d+)\]\.(\w+)\}/, "{this.$1.state[$2].$3}");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.match(/\{this\.state\.(\w+)\.(\w+)\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\.(\w+)\./, "{this.$1.state.");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.match(/\{this\.state\[\d+\]\.(\w+)\.(\w+)\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\[(\d+)\]\.(\w+)\./, "{this.$2.state[$1].");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (placeholder.match(/\{this\.state\.(\w+)-\d+\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\.(\w+)-/, "{this.$1.state-");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    // </Repeated-Cases>

    // Handle placeholders like {this.response.<compRef>.requestStatusCode} in complex expressions
    if (placeholder.match(/\{this\.response\.(\w+)\.requestStatusCode\}/)) {
      const matchResult = placeholder?.match(/\{this\.response\.(\w+)\.requestStatusCode\}/);
      const compRef = matchResult ? matchResult[1] : "";
      const newPlaceholder = `{this.${compRef}.response._status}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (actionConfigPathPattern.test(path) && placeholder.startsWith("{this.data.")) {
      if (dataSourcePathPattern.test(path)) {
        return;
      }
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (
      path.match(
        /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.actionsMap\.default\[\d+\]\.actionConfig\.source$/
      ) &&
      placeholder.startsWith("{this.data.")
    ) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (
      path.match(/^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.visibilityCondition$/) &&
      placeholder.startsWith("{this.data.")
    ) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    // {this.actionResponse.requestStatusCode} => {@._status}
    if (placeholder === "{this.actionResponse.requestStatusCode}") {
      modifiedData = modifiedData.replace(placeholder, "{@.response._status}");
      return;
    }

    if (placeholder.startsWith("{this.error.")) {
      const compKey = placeholder.slice("{this.error.".length, -1);
      const newPlaceholder = `{this.${compKey}.errors.hasError}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.includes(".state.")) {
      const newPlaceholder = flipSyntaxByKeyword(placeholder, "state");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder === "{this.dirty._page}") {
      modifiedData = modifiedData.replace(placeholder, "{$page.dirty.isDirty}");
    }

    if (placeholder.includes("this.location.")) {
      const newPlaceholder = placeholder.replace("this.location", "$global.location");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.includes(".queryStrings.")) {
      const newPlaceholder = placeholder.replace("queryStrings", "queryParams");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.includes("{#.data.")) {
      const newPlaceholder = placeholder.replace("{#.data.", "{#.");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder === "{this.login.email}") {
      modifiedData = modifiedData.replace(placeholder, "{buildxLogin.email}");
    }
    if (placeholder === "{this.login.password}") {
      modifiedData = modifiedData.replace(placeholder, "{buildxLogin.password}");
    }
    if (placeholder === "{this.error}") {
      modifiedData = modifiedData.replace(placeholder, "{this.errors.hasError}");
    }
    if (placeholder === "{this.login.recaptcha}") {
      modifiedData = modifiedData.replace(placeholder, "{buildxLogin.recaptcha}");
    }

    if (placeholder === "{this.config.appVersion}") {
      modifiedData = modifiedData.replace(placeholder, "{$buildx.version}");
    }
    if (placeholder === "{this.config.deviceType}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.browser.deviceType}");
    }
    if (placeholder === "{this.config.osVersion}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.browser.osVersion}");
    }
    if (placeholder === "{this.config.timeZone}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.browser.timeZone}");
    }
    if (placeholder === "{this.config.deviceUDID}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.localStorage.device_uuid}");
    }
    if (placeholder === "{this.device.accessToken}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.localStorage.accessToken}");
    }

    if (placeholder === "{this._page}") {
      modifiedData = modifiedData.replace(placeholder, "{$page}");
    }

    if (placeholder.includes(".state.")) {
      const newPlaceholder = flipSyntaxByKeyword(placeholder, "state");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (placeholder.includes(".response.")) {
      const newPlaceholder = flipSyntaxByKeyword(placeholder, "response");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.location.")) {
      const remainingPath = placeholder.slice("{this.location.".length, -1);
      const newPlaceholder = `{$global.location.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.localStorage.")) {
      const remainingPath = placeholder.slice("{this.localStorage.".length, -1);
      const newPlaceholder = `{$global.localStorage.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.response.")) {
      const compKey = placeholder.slice("{this.response.".length, -1);
      const newPlaceholder = `{this.${compKey}.response}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.state.")) {
      const compKey = placeholder.slice("{this.state.".length, -1);
      const newPlaceholder = `{this.${compKey}.state}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.dirty.")) {
      const compKey = placeholder.slice("{this.dirty.".length, -1);
      const newPlaceholder = `{this.${compKey}.dirty.isDirty}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    const dataCompMatch = placeholder.match(dataCompPattern);
    if (dataCompMatch) {
      const compKey = dataCompMatch[1];
      const restOfPath = dataCompMatch[2] || "_body";
      const newPlaceholder = `{this.${compKey}.data.${restOfPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (selectedItemRegex.test(placeholder)) {
      const newPlaceholder = placeholder.replace("_selectedItem", ".selectedItem").replace(/\.{2,}/g, ".");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    const requestStatusCodeMatch = placeholder.match(requestStatusCodePattern);
    if (requestStatusCodeMatch) {
      const compKey = requestStatusCodeMatch[1];
      const newPlaceholder = `{this.${compKey}.response._status}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
  });

  return modifiedData;
}

function traverseApp(obj, currentPath = "") {
  if (obj === null) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach((el, index) => {
      const path = `${currentPath}[${index}]`;
      if (typeof el === "object" || Array.isArray(el)) {
        traverseApp(el, path);
      } else {
        obj[index] = convertToNewSyntax(el, path);
      }
    });
    return;
  }

  if (typeof obj === "object") {
    Object.keys(obj).forEach(key => {
      const path = currentPath ? `${currentPath}.${key}` : key;
      if (typeof obj[key] === "object" || Array.isArray(obj[key])) {
        traverseApp(obj[key], path);
      } else {
        obj[key] = convertToNewSyntax(obj[key], path);
      }
    });
    return;
  }
}

function mappingDataValues(collections: any) {
  collections.forEach(collection => {
    collection.pages.forEach(page => {
      page.views.forEach(view => {
        if (view.type === "data-table") {
          let actions = _.get(view, "config.actions", []);
          actions?.forEach((ac, index) => (ac.actionIndex = index));
          const columns = _.get(view, "config.columns", []);
          const hasActionsColumn = columns.some(column => column.type === "Actions");
          const actionHasNonGlobal = actions.some(action => action.isGlobal === false);
          // Handle Actions column to add Action Row if there is any non-global action
          if (actionHasNonGlobal && !hasActionsColumn) {
            columns.push({
              id: uuid(),
              name: "Actions",
              type: "Actions",
              headerCellType: "string",
              customWidth: "",
              alignColumn: "left",
              autoWidth: false,
            });
          }

          // change the view type to FROM PARENT if the view source type is NONE and and the view is child to a table
          columns.forEach(column => {
            if (column.type === "View Builder" && column.viewBuilderId) {
              page.views.forEach(otherView => {
                if (otherView.id === column.viewBuilderId) {
                  if (otherView.dataSource && otherView.dataSource.sourceType === "NONE") {
                    otherView.dataSource.sourceType = "FROM PARENT";
                  }
                }
              });
            }
          });
        }
      });
    });
  });
}

function addStepperParents(collections: any): void {
  collections.forEach(collection => {
    collection.pages.forEach(page => {
      page.views.forEach(view => {
        if (view.type === "form-builder") {
          const updatedFormBuilder = setStepperParents(view.dataSource.formBuilder, view.stepperGroups);
          view.dataSource = {
            ...view.dataSource,
            formBuilder: updatedFormBuilder,
          };
        }
      });
    });
  });
}

function applyMigrationsFunctionLogic(app) {
  const templateCollections = _.get(app, "templateConfig.collections", []);
  const upTemplateCollections = _.get(app, "upTemplateConfig.collections", []);

  mappingDataValues(templateCollections);
  mappingDataValues(upTemplateCollections);

  addStepperParents(templateCollections);
  addStepperParents(upTemplateCollections);
}

const migrations: any = {
  "1.0.0": migrateTo1_0_0,
  "1.0.1": migrateTo1_0_1,
  "1.0.2": migrateTo1_0_2,
  "1.0.3": migrateTo1_0_3,
};

export const handleMigrateApp = (app: BXApp) => {
  const decompressedData = decompressData(app?.templateConfig);
  const appVersion = decompressedData?.appVersion!;

  const migrationsKeys = Object.keys(migrations);
  const versionKeys = !appVersion ? migrationsKeys : migrationsKeys.filter(version => compareVersions(version, appVersion) > 0);

  let appAfterMigration = app;
  for (let i = 0; i < versionKeys.length; i++) {
    const version = versionKeys[i];
    const migrationFunction = migrations[version];
    appAfterMigration = migrationFunction(appAfterMigration);
  }
  return appAfterMigration;
};
