/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import {
  cleanDirtyFields,
  clearErrors,
  clearTrigger,
  mergeAppValue,
  removeAppField,
  resetAppState,
  setAppError,
  setAppValue,
  traverseOnChildren,
  validationRegistry,
} from "./appStateSlice";

// Custom hook to provide state management functions
export const useAppState = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Function to get the value of a specific field
  const getValue = (name: string) => {
    const state = store.getState();
    return _.get(state.appState.values, name);
  };

  // Function to get all values from the app state
  const getValues = () => {
    const state = store.getState();
    return state.appState.values;
  };

  // Function to set the value of a specific field
  const setValue = (name: string, value: any, options?: { isDisabledDirtyField?: boolean; reEvaluateErrorsAndDirty?: boolean }) => {
    const { isDisabledDirtyField, reEvaluateErrorsAndDirty } = options || {};

    dispatch(setAppValue({ name, value, isDisabledDirtyField, reEvaluateErrorsAndDirty }));
  };

  const mergeValue = (name: string, value: any) => {
    dispatch(mergeAppValue({ name, value }));
  };

  const removeDirty = (name: string) => {
    dispatch(cleanDirtyFields({ name }));
  };

  const getDirty = (name: string) => {
    let state;
    try {
      state = useSelector((state: RootState) => state);
    } catch {
      state = store.getState();
    }

    const [pageId, viewName, fieldName, ...rest] = name.split(".");
    let dirtyResult = false;
    function checkDirty(node) {
      const isDirty = node?.dirty?.isDirty;
      if (isDirty) {
        dirtyResult = true;
        return false;
      }
      return true;
    }

    if (pageId && viewName && fieldName) {
      traverseOnChildren(state.appState, `${pageId}.${viewName}.${fieldName}`, checkDirty);
    } else if (pageId && viewName) {
      traverseOnChildren(state.appState, `${pageId}.${viewName}`, checkDirty);
    } else if (pageId) {
      traverseOnChildren(state.appState, `${pageId}`, checkDirty);
    }
    return dirtyResult;
  };

  const removeValue = (name: string) => {
    dispatch(removeAppField({ name }));
  };

  // Function to watch a specific field's value dynamically or the entire state
  const watch = (name?: string) => {
    try {
      return useSelector((state: RootState) => {
        if (name) {
          // If name is provided, return the nested value
          return _.get(state.appState.values, name);
        } else {
          // If name is not provided, return the entire values object
          return state.appState.values;
        }
      });
    } catch {
      const state = store.getState();

      if (name) {
        return _.get(state.appState.values, name);
      } else {
        return state.appState.values;
      }
    }
  };

  const runAllValidations = (name = ""): boolean => {
    const state = store.getState();
    let isValid = true;
    const [pageId, viewName = "", fieldName = "", ...rest] = name.split(".");

    const runValidation = (_node, _nodeKey, _state, nodePath) => {
      const validationFns: any = _.get(validationRegistry, nodePath);

      const traverseValidationFns = (validationFns, currentPath) => {
        if (typeof validationFns === "function") {
          const pathWithoutLastPart = currentPath.split(".").slice(0, -1).join(".");

          const valueToValidate = _.get(getValues(), `${pathWithoutLastPart}.state`);

          const result = validationFns(valueToValidate);

          if (result !== true && result !== undefined) {
            isValid = false;

            dispatch(setAppError({ name: pathWithoutLastPart, error: result }));
          }
        } else if (Array.isArray(validationFns)) {
          for (let i = 0; i < validationFns.length; i++) {
            traverseValidationFns(validationFns[i], `${currentPath}[${i}]`);
          }
        } else if (typeof validationFns === "object" && validationFns !== null) {
          for (const [key, value] of Object.entries(validationFns)) {
            traverseValidationFns(value, `${currentPath}.${key}`);
          }
        }
      };

      if (validationFns) {
        traverseValidationFns(validationFns, nodePath);
      }
    };

    if (pageId && viewName && fieldName) {
      traverseOnChildren(state.appState, `${pageId}.${viewName}.${fieldName}`, runValidation);
    } else if (pageId && viewName) {
      traverseOnChildren(state.appState, `${pageId}.${viewName}`, runValidation);
    } else if (pageId) {
      traverseOnChildren(state.appState, `${pageId}`, runValidation);
    }

    return isValid;
  };

  //Function to clear the validation on specific fields of the app hierarchy
  const clearValidation = (name: string) => {
    dispatch(clearErrors({ name }));
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // If no errors, proceed with the callback
    const values = getValues();

    return { values };
  };

  // Function to re1   the entire app state
  const resetState = (name?: string) => {
    dispatch(resetAppState({ name }));
  };

  const cleanTrigger = (name: string) => {
    dispatch(clearTrigger({ name }));
  };

  return {
    getValue,
    getValues,
    setValue,
    watch,
    handleSubmit,
    runAllValidations,
    resetState,
    removeDirty,
    removeValue,
    getDirty,
    clearValidation,
    mergeValue,
    cleanTrigger,
  };
};
