import {
  AvatarProps,
  BoxProps,
  Breakpoint,
  ButtonProps,
  CardMediaProps,
  ChipProps,
  DividerProps,
  IconButtonProps,
  RadioProps,
  TextFieldProps,
} from "@mui/material";
import { DatePickerProps, DateTimePickerProps, TimePickerProps } from "@mui/x-date-pickers";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import { UIElement } from "src/types/UIElement";
import { v4 as uuid } from "uuid";
import ColorPickerImage from "../../../../assets/images/icons/ColorPicker.svg";
import DateTimePickerImage from "../../../../assets/images/icons/Date time picker.svg";
import DividerImage from "../../../../assets/images/icons/Divider.svg";
import CustomIconButtonImage from "../../../../assets/images/icons/Icon Button.svg";
import JsonViewerImage from "../../../../assets/images/icons/JsonViewer.svg";
import QRImage from "../../../../assets/images/icons/QR.svg";
import SelectImage from "../../../../assets/images/icons/Select.svg";
import Spinner from "../../../../assets/images/icons/Spinner.svg";
import TimePickerImage from "../../../../assets/images/icons/TimePicker.svg";
import AvatarImage from "../../../../assets/images/icons/avatar.svg";
import StripePaymentElementsImage from "../../../../assets/images/icons/brand-stripe.svg";
import ButtonImage from "../../../../assets/images/icons/button.svg";
import StripeCardExpiryElementImage from "../../../../assets/images/icons/calendar-event.svg";

import { builderAdapter, removeChildFromParent, updateComponent, upsertComponents } from "src/features/builder/builderSlice";
import { selectComponentById, selectTopLevelComponents } from "src/features/builder/selectors";
import store from "src/store/store";
import { toCamelCase } from "src/utils/generalUtils";
import CustomAutoComplete from "../../../../assets/images/icons/CustomAutoComplete.svg";
import SplineImage from "../../../../assets/images/icons/chart-histogram.svg";
import CustomCheckboxImage from "../../../../assets/images/icons/checkbox.svg";
import ChipImage from "../../../../assets/images/icons/chip.svg";
import CircularChartImage from "../../../../assets/images/icons/circular-chart.svg";
import ColumnChartImage from "../../../../assets/images/icons/column-graph.svg";
import StripeCardNumberElementImage from "../../../../assets/images/icons/credit-card-pay.svg";
import CustomContainerImage from "../../../../assets/images/icons/custom.svg";
import DatePickerImage from "../../../../assets/images/icons/datePicker.svg";
import GoogleMapImage from "../../../../assets/images/icons/google-maps.svg";
import CustomHeaderContainerImage from "../../../../assets/images/icons/header.svg";
import CustomMediaCardImage from "../../../../assets/images/icons/image.svg";
import stripeAddressElementImage from "../../../../assets/images/icons/location.svg";
import GoogleMapAutocompleteImage from "../../../../assets/images/icons/map-search.svg";
import MarkdownImage from "../../../../assets/images/icons/markdown.svg";
import StripeCardCvcElementImage from "../../../../assets/images/icons/number-123.svg";
import PieChartImage from "../../../../assets/images/icons/pie-chart.svg";
import CustomRadioImage from "../../../../assets/images/icons/radio.svg";
import SwitchImage from "../../../../assets/images/icons/switcher.svg";
import TextFieldImage from "../../../../assets/images/icons/text-field.svg";
import TypographyImage from "../../../../assets/images/icons/text.svg";
import FileUploadInputImage from "../../../../assets/images/icons/upload.svg";
import { CustomAutoCompleteProps } from "./components/CustomAutoCompleteBX";
import type { CustomCheckboxProps } from "./components/CustomCheckboxGroup";
import { CustomContainerProps } from "./components/CustomContainer";
import { CustomSwitchProps } from "./components/CustomSwitch";
import { CustomTabsProps } from "./components/CustomTabs";
import { TypographyProps } from "./components/CustomTypography";
import { PaginationBarProps } from "./components/PaginationBar";
import { PieChartProps } from "./components/PieChart";
import { SplineChartProps } from "./components/SplineChart";
import { ComponentItemType, StepperComponentState } from "./types";
import { customContainerConfig, flexContainerConfig, gridContainerConfig } from "./viewBuilderComponentConfig";

export const stepperCount = 10;

export const layoutBreakPointsValues = {
  xs: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const getBreakpoint = (width): Breakpoint => {
  const breakpoints = Object.entries(layoutBreakPointsValues);

  for (const [breakpoint, value] of breakpoints) {
    if (value >= width) {
      return breakpoint as Breakpoint;
    }
  }

  return "lg";
};

export const layoutBreakPoints = [
  { id: "xl", title: "Xl", width: "100%" },
  { id: "lg", title: "Lg", width: "85%" },
  { id: "md", title: "Md", width: "70%" },
  // { id: "sm", title: "Sm", width: "60%" },
  { id: "xs", title: "Xs", width: "40%" },
];
export const typographyComponents = [
  { id: "body1", title: "body1" },
  { id: "body2", title: "body2" },
  { id: "subtitle1", title: "subtitle1" },
  { id: "subtitle2", title: "subtitle2" },
  { id: "h1", title: "h1" },
  { id: "h2", title: "h2" },
  { id: "h3", title: "h3" },
  { id: "h4", title: "h4" },
  { id: "h5", title: "h5" },
  { id: "h6", title: "h6" },
];
export const visibilityTypes = [
  { id: "Hidden", title: "Hidden" },
  { id: "None", title: "None" },
];
export const uploaderTypes = [
  { id: "Default", title: "Default" },
  { id: "TextField", title: "TextField" },
  { id: "IconButton", title: "IconButton" },
  { id: "Typography", title: "Typography" },
];

export const componentMediaTypes = [
  { id: "img", title: "image" },
  { id: "video", title: "video" },
  { id: "audio", title: "audio" },
  { id: "auto", title: "auto" },
];
export const objectFitTypes = [
  { id: "cover", title: "cover" },
  { id: "contain", title: "Contain" },
];
export const containersTypes = [
  { id: ComponentItemType.FlexContainer, title: "Flex Container" },
  { id: ComponentItemType.GridContainer, title: "Grid Container" },
  { id: ComponentItemType.CustomContainer, title: "Custom Container" },
];
export const textFieldTypes = ["Text", "Number", "Email", "Password", "URL", "UUID"];
export const typographyFormats = [
  { id: "String", title: "String" },
  { id: "Date Time", title: "Date Time" },
];

export const typographyAlign = [
  { id: "center", title: "center" },
  { id: "left", title: "left" },
  { id: "right", title: "right" },
];

export const wrapValues = ["nowrap", "wrap-reverse", "wrap"];
export const flexDirectionValues = ["row", "row-reverse", "column", "column-reverse"];
export const flexContainerIncludedProps = ["margin", "padding", "alignItems", "gap", "flexWrap", "justifyContent", "flexDirection"];
export const gridContainerIncludedProps = [
  "margin",
  "padding",
  "gap",
  "gridTemplateColumns",
  "gridTemplateRows",
  "gridTemplate",
  "gridTemplateAreas",
  "gridColumnGap",
  "gridRowGap",
  "justifyItems",
  "alignItems",
  "placeItems",
  "justifyContent",
  "alignContent",
  "placeContent",
  "extraSmall",
  "small",
  "medium",
  "large",
  "extraLarge",
];
export const selectOptions = {
  justifyItems: ["start", "end", "center", "stretch", "empty"],
  alignItems: ["start", "end", "center", "stretch", "empty"],
  placeItems: ["start", "end", "center", "stretch", "empty"],
  justifyContent: ["start", "end", "center", "space-between", "space-around", "space-evenly", "empty"],
  alignContent: ["start", "end", "center", "stretch", "space-between", "space-around", "empty"],
  placeContent: ["start", "end", "center", "stretch", "space-between", "space-around", "empty"],
};

export const defaultValues = {
  justifyItems: "stretch",
  alignItems: "stretch",
  placeItems: "stretch",
  justifyContent: "start",
  alignContent: "stretch",
  placeContent: "stretch",
};
export const RepeatedSection = ["root-response-key", "min-items", "max-items", "default-number-of-items"];
export const alignItemsValues = ["center", "start", "end", "flex-start", "flex-end", "self-start", "self-end"];
export const flexChildIncludedProps = ["flex", "flexShrink", "flexBasis", "alignSelf", "order", "minWidth", "minHeight"];
export const gridChildIncludedProps = ["gridColumn", "gridRow", "gridArea", "justifySelf", "alignSelf", "order", "minWidth", "minHeight"];
export const alignSelfValues = ["auto", "flex-start", "flex-end", "center", "baseline", "stretch"];
export const overflowValues = ["hidden", "visible", "auto", "scroll"];
export const stripeThemesOptions = ["stripe", "night", "flat"];
export const stripeIconOptions = ["default", "solid"];
export const stripeLabelsOptions = ["floating", "above"];
export const justifyContentValues = [
  "flex-start",
  "flex-end",
  "center",
  "space-between",
  "space-around",
  "space-evenly",
  "initial",
  "inherit",
];

export const componentsHaveChildren = [ComponentItemType.GridContainer, ComponentItemType.FlexContainer, ComponentItemType.CustomContainer];

export const components = [
  flexContainerConfig,
  {
    ...flexContainerConfig,
    config: {
      ...flexContainerConfig.config,
      controlledComponent: true,
      isGroup: true,
      acceptTypes: [ComponentItemType.CustomCheckbox],
      placeholderConfig: {
        ...flexContainerConfig.config.placeholderConfig,
        title: "Checkbox Group",
        image: CustomCheckboxImage,
      },
    },
  },
  {
    ...flexContainerConfig,
    config: {
      ...flexContainerConfig.config,
      controlledComponent: true,
      isGroup: true,
      acceptTypes: [ComponentItemType.CustomRadio],
      placeholderConfig: {
        ...flexContainerConfig.config.placeholderConfig,
        title: "Radio Group",
        image: CustomRadioImage,
      },
    },
  },
  gridContainerConfig,
  {
    type: ComponentItemType.StepperContainer,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        padding: "10px",
        flexDirection: "column",
      },
    } as BoxProps,

    config: {
      isStepperContainer: true,
      defaultWidth: 200,
      defaultHeight: 100,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      sideMenuSx: {
        border: "1px dashed #57585c",
      },
      placeholderConfig: {
        title: "Stepper container",
        image: CustomContainerImage,
        group: "container",
      },
      parentStyle: { backgroundColor: "transparent" },
    },
  },

  {
    type: ComponentItemType.BXSideBar,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        minHeight: "100px",
        backgroundColor: "primary.main",
      },
    },
    config: {
      defaultWidth: 275,
      defaultHeight: 800,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      hideIn: ["md", "xs"],
      sx: {
        display: {
          xs: "none",
          md: "block",
        },
      },
      placeholderConfig: {
        title: "SideBar",
        image: CustomHeaderContainerImage,
        group: "container",
      },
    },
  },
  {
    type: ComponentItemType.BXNavBar,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        backgroundColor: "primary.main",
      },
    },
    config: {
      defaultHeight: 80,
      widthPercentage: {
        xs: "100%",
      },
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "NavBar",
        image: CustomHeaderContainerImage,
        group: "container",
      },
    },
  },

  customContainerConfig,
  // {
  //   type: ComponentItemType.CustomAccordion,
  //   props: {
  //     sx: {
  //       width: "100%",
  //       height: "100%",
  //       backgroundColor: "transparent",
  //       zIndex: 10,
  //     },
  //     label: "Accordion Label",
  //     defaultExpanded: false,
  //   } as CustomAccordionProps,
  //   children: [
  //     {
  //       id: uuid(),
  //       type: ComponentItemType.CustomContainer,
  //       props: {
  //         sx: {
  //           width: "100%",
  //           height: "100%",
  //           backgroundColor: "transparent",
  //         },
  //       },
  //       config: {
  //         defaultWidth: "100%",
  //         defaultHeight: "100%",
  //         minWidth: 100,
  //         minHeight: 100,
  //         disableDrag: true,
  //         selectParent: true,
  //         fixedWidth: false,
  //         isPercentageHeight: false,
  //         sideMenuSx: {
  //           border: "1px dashed #57585c",
  //         },
  //       },
  //     },
  //   ],
  //   config: {
  //     defaultWidth: 300,
  //     defaultHeight: 300,
  //     minWidth: 100,
  //     minHeight: 100,
  //     isPercentageHeight: false,
  //     placeholderConfig: {
  //       title: "Accordion",
  //       image: CustomAccordionImage,
  //       group: "container",
  //     },
  //     parentStyle: { backgroundColor: "transparent" },
  //   },
  // },
  {
    type: ComponentItemType.TextField,
    props: {
      label: "Text Field",
      defaultValue: "",
      autoComplete: "off",
      placeholder: "Placeholder",
      type: "Text",
      InputProps: {
        sx: {
          height: "100%",
        },
      },
      sx: {
        width: "100%",
        height: "100%",
      },
    } as TextFieldProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Text Field",
        image: TextFieldImage,
        group: "inputs",
      },
      cssPaths: ["sx", "InputProps.sx"],
    },
  },
  {
    type: ComponentItemType.CustomAutoCompleteBX,
    props: {
      multiple: false,
      freeSolo: false,
      showCheckbox: false,
      placeholder: "AutoComplete",
      InputProps: {
        sx: {
          height: "100%",
          width: "100%",
        },
      },
      sx: {
        width: "100%",
        height: "100%",
      },
      iconConfig: { icon: "", visibility: "", url: "" },
    } as CustomAutoCompleteProps,
    config: {
      defaultWidth: 200,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Auto Complete",
        image: CustomAutoComplete,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.BXSelect,
    props: {
      label: "Select",
      defaultValue: "",
      autoComplete: "off",
      placeholder: "Placeholder",
      select: true,
      InputProps: {
        sx: {
          height: "100%",
          width: "100%",
        },
      },
      sx: {
        width: "100%",
        height: "100%",
      },
    } as TextFieldProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Select",
        image: SelectImage,
        group: "inputs",
      },
      cssPaths: ["sx", "InputProps.sx"],
    },
  },

  {
    type: ComponentItemType.Button,
    props: {
      variant: "contained",
      disableRipple: true,
      enableLoading: true,
      sx: {
        width: "100%",
        height: "100%",
      },
      children: "Button",
    } as ButtonProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      isAction: true,
      placeholderConfig: {
        title: "Button",
        image: ButtonImage,
        group: "buttons",
      },
    },
  },
  {
    type: ComponentItemType.SplineChart,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
    } as SplineChartProps,
    config: {
      defaultWidth: 500,
      defaultHeight: 370,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      BxComponent: true,
      placeholderConfig: {
        title: "Line Chart",
        image: SplineImage,
        group: "charts",
      },
    },
    configData: {
      title: "Chart Title",
      sourceType: "fixedData",
      subheader: "Sub Header",
      enableGroup: true,
      apiMode: {
        apiUrl: "",
        groupTitle: "",
        responseGroupKey: "",
        fixedLines: false,
        lineTitle: "",
        responseLineKey: "",
        valuesKey: "",
        payload: { uri: "", isGraphQL: "", method: "" },
        chartLineData: [],
      },
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
      series: [
        {
          id: uuid(),
          type: "2019",
          data: [
            { name: "Total Income", data: [10, 41, 35, 200, 49, 170, 69, 91, 48], dataString: "10, 41, 35, 200, 49, 170, 69, 91, 48" },
            {
              name: "Total Expenses",
              data: [350, 41, 35, 200, 439, 520, 39, 101, 5],
              dataString: "350, 41, 35, 200, 439, 520, 39, 101, 5",
            },
          ],
        },
      ],
    },
  },
  {
    type: ComponentItemType.PieChart,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        display: "flex",
      },
      // colors: [theme.palette.primary.main, theme.palette.info.main, theme.palette.error.main, theme.palette.warning.main],
    } as unknown as PieChartProps,
    config: {
      defaultWidth: 300,
      defaultHeight: 400,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      BxComponent: true,
      placeholderConfig: {
        title: "Pie Chart",
        image: PieChartImage,
        group: "charts",
      },
    },
    configData: {
      title: "Current Visits",
      subheader: "Sub Header",
      sourceType: "fixedData",
      enableGroup: false,
      apiMode: {
        apiUrl: "",
        groupTitle: "",
        responseGroupKey: "",
        fixedLines: false,
        lineTitle: "",
        responseLineKey: "",
        valuesKey: "",
        payload: { uri: "", isGraphQL: "", method: "" },
        chartLineData: [],
      },
      series: [
        { name: "America", data: 4344, dataString: "4344" },
        { name: "Asia", data: 5435, dataString: "5435" },
        { name: "Europe", data: 1443, dataString: "1443" },
        { name: "Africa", data: 4443, dataString: "1443" },
      ],
    },
  },
  {
    type: ComponentItemType.ColumnChart,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        // display: "flex",
      },
    } as unknown as PieChartProps,
    config: {
      defaultWidth: 500,
      defaultHeight: 370,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      BxComponent: true,
      placeholderConfig: {
        title: "Bar chart",
        image: ColumnChartImage,
        group: "charts",
      },
    },
    configData: {
      title: "Balance Statistics",
      subheader: "(+43% Income | +12% Expense) than last year",
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
      // colors: [theme.palette.primary.main, theme.palette.warning.main],
      series: [
        // with group
        {
          id: uuid(),
          type: "Week",
          data: [
            { name: "Income", data: [10, 41, 35, 151, 49, 62, 69, 91, 48], dataString: "10, 41, 35, 151, 49, 62, 69, 91, 48" },
            { name: "Expenses", data: [10, 34, 13, 56, 77, 88, 99, 77, 45], dataString: "10, 34, 13, 56, 77, 88, 99, 77, 45" },
          ],
        },
        {
          id: uuid(),
          type: "Month",
          data: [
            { name: "Income", data: [148, 91, 69, 62, 49, 51, 35, 41, 10], dataString: "148, 91, 69, 62, 49, 51, 35, 41, 10" },
            { name: "Expenses", data: [45, 77, 99, 88, 77, 56, 13, 34, 10], dataString: "45, 77, 99, 88, 77, 56, 13, 34, 10" },
          ],
        },
        {
          id: uuid(),
          type: "Year",
          data: [
            { name: "Income", data: [76, 42, 29, 41, 27, 138, 117, 86, 63], dataString: "76, 42, 29, 41, 27, 138, 117, 86, 63" },
            { name: "Expenses", data: [80, 55, 34, 114, 80, 130, 15, 28, 55], dataString: "80, 55, 34, 114, 80, 130, 15, 28, 55" },
          ],
        },
      ],
      sourceType: "fixedData",
      enableGroup: true,
      apiMode: {
        apiUrl: "",
        groupTitle: "",
        responseGroupKey: "",
        fixedLines: false,
        lineTitle: "",
        responseLineKey: "",
        valuesKey: "",
        payload: { uri: "", isGraphQL: "", method: "" },
        chartLineData: [],
      },
    },
  },
  {
    type: ComponentItemType.CircularChart,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        display: "flex",
      },
    } as unknown as PieChartProps,
    config: {
      defaultWidth: 300,
      defaultHeight: 400,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      BxComponent: true,
      placeholderConfig: {
        title: "Circular Chart",
        image: CircularChartImage,
        group: "charts",
      },
    },
    configData: {
      title: "Sale By Gender",
      subheader: "+43% Income | +12% Expense) than last year",
      total: "2324",
      series: [
        { name: "Men", data: 44, dataString: "44" }, // no group
        { name: "Women", data: 75, dataString: "75" },
      ],
      sourceType: "fixedData",
      enableGroup: false,
      apiMode: {
        apiUrl: "",
        groupTitle: "",
        responseGroupKey: "",
        fixedLines: false,
        lineTitle: "",
        responseLineKey: "",
        valuesKey: "",
        payload: { uri: "", isGraphQL: "", method: "" },
        chartLineData: [],
      },
    },
  },
  {
    type: ComponentItemType.CustomIconButton,
    props: {
      variant: "contained",
      disableRipple: true,
      enableLoading: true,
      size: "large",
      sx: {
        width: "100%",
        height: "100%",
      },
      iconName: "User",
      iconConfig: { icon: "", visibility: "", url: "" },
      iconSize: 30,
      style: {
        borderRadius: "",
      },
      borderColor: "",
      backgroundColor: "transparent",
      borderWidth: "1px",
      iconColor: "",
    } as IconButtonProps,
    config: {
      defaultWidth: 50,
      defaultHeight: 50,
      minWidth: 50,
      minHeight: 50,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      isAction: true,
      // disableResizeWidth: true,
      // disableResizeHeight: true,
      // isNotResizable: true,
      placeholderConfig: {
        title: "Icon Button",
        image: CustomIconButtonImage,
        group: "buttons",
      },
    },
  },
  {
    type: ComponentItemType.CustomChip,
    props: {
      src: "",
      alt: "Chip",
      labelChip: "Chip",
      backgroundColor: "",
      borderColor: "",
      labelColor: "",
      variant: "filled",
      mapValuesObject: {},
      isMapValues: false,
      sx: {
        width: 50,
        height: 50,
      },
    } as ChipProps,
    config: {
      defaultWidth: 100,
      defaultHeight: 35,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      disableResizeWidth: false,
      disableResizeHeight: false,
      isNotResizable: false,
      placeholderConfig: {
        title: "Chip",
        image: ChipImage,
        group: "media",
      },
    },
  },
  {
    type: ComponentItemType.Avatar,
    props: {
      src: "",
      iconConfig: { icon: "", visibility: "", url: "" },
      alt: "Image",
      sx: {
        width: 50,
        height: 50,
      },
    } as AvatarProps,
    config: {
      defaultWidth: 50,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      disableResizeWidth: false,
      disableResizeHeight: false,
      isNotResizable: false,
      placeholderConfig: {
        title: "Avatar",
        image: AvatarImage,
        group: "media",
      },
    },
  },
  {
    type: ComponentItemType.Typography,
    props: {
      children: "Typography",
      fontSize: "14px",
      wordwrap: "break-word",
      overflow: "hidden",
      variant: "body1",
      align: "left",
      component: "div",
      format: "String",
      formatString: "DD/MM/YYYY",
      mapValuesObject: {},
      isMapValues: false,
      sx: {
        width: "100%",
        height: "100%",
      },
    } as TypographyProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      isDynamicHeight: { lg: true },
      isDynamicWidth: { lg: true },
      isPercentageHeight: { lg: true },
      placeholderConfig: {
        title: "Typography",
        image: TypographyImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.PaginationBar,
    props: {
      currentPage: 1,
      siblingCount: 1,
      maxPrev: 5,
      hasNextPage: false,
      isFetching: false,
      fwdCursor: 2,
      prevCursor: 0,
      lastCursor: "",
      unselectedPageBackgroundColor: "",
      unselectedPageColor: "",
      unselectedPageColorHover: "",
      selectedPageBackgroundColor: "",
      selectedPageColor: "",
      selectedPageColorHover: "",
      pageType: "numbers",
      pagesOrientation: "row",
    } as PaginationBarProps,
    config: {
      dataPagination: "",
      defaultWidth: 200,
      defaultHeight: 50,
      minWidth: 100,
      minHeight: 30,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      disableResizeWidth: false,
      disableResizeHeight: false,
      isNotResizable: false,
      placeholderConfig: {
        title: "Pagination Bar",
        image: DividerImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.Divider,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
    } as DividerProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: true },
      isNotResizable: true,
      placeholderConfig: {
        title: "Divider",
        image: DividerImage,
        group: "container",
      },
    },
  },
  {
    type: ComponentItemType.CustomSwitch,
    props: {
      sx: {
        // width: "100%",
        // height: "100%",
      },
      label: "Label",
      defaultValue: true,
    } as CustomSwitchProps,
    config: {
      defaultWidth: 105,
      defaultHeight: 35,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      fixedHeight: { lg: false },
      isPercentageHeight: { lg: false },
      isNotResizable: true,
      isDynamicWidth: { lg: true },
      isDynamicHeight: { lg: true },
      controlledComponent: true,
      placeholderConfig: {
        title: "Switch",
        image: SwitchImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.CustomCheckbox,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
      label: "Label",
      groupName: "checkbox-group",
      checkboxValue: "",
      defaultChecked: true,
    } as unknown as CustomCheckboxProps,
    config: {
      defaultWidth: 85,
      defaultHeight: 45,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Checkbox",
        image: CustomCheckboxImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.CustomRadio,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
      label: "Label",
      groupName: "radio-group",
      radioValue: "",
    } as RadioProps,
    config: {
      defaultWidth: 85,
      defaultHeight: 45,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Radio",
        image: CustomRadioImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.CustomMediaCard,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      height: "100px",
      component: "img",
      controls: true,
      disabledCache: false,
      disablePlaceholder: false,
      iconConfig: { icon: "", visibility: "", url: "" },
    } as CardMediaProps,
    config: {
      defaultWidth: 180,
      defaultHeight: 100,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Media Card",
        image: CustomMediaCardImage,
        group: "media",
      },
    },
  },
  {
    ...flexContainerConfig,
    props: {
      ...flexContainerConfig.props,
      stripePublishableEndpoint: {},
      stripeSecretEndpoint: {},
    },
    config: {
      ...flexContainerConfig.config,
      isGroup: true,
      isStripeWrapper: true,
      acceptTypes: [
        ComponentItemType.StripeCardNumberElement,
        ComponentItemType.StripeCardCvcElement,
        ComponentItemType.StripeCardExpiryElement,
        ComponentItemType.StripeAddressElement,
        ComponentItemType.StripePaymentElements,
        ComponentItemType.Button,
        ComponentItemType.TextField,
      ],
      placeholderConfig: {
        ...flexContainerConfig.config.placeholderConfig,
        title: "Stripe Container",
        image: CustomContainerImage,
        group: "stripeElements",
      },
    },
  },
  {
    type: ComponentItemType.CustomGoogleMap,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      defaultValue: "",
      controls: true,
      keepPinCentered: false,
      enableMapTypeControl: false,
      enableFullscreenControl: false,
      enableZoomControl: false,
      enableStreetViewControl: false,
      referenceLatAndLngValues: "",
      googleMapApiKey: "",
      longitudeInputValue: "",
      latitudeInputValue: "",
      longitudeDefaultValue: "",
      latitudeDefaultValue: "",
    } as any,
    config: {
      defaultWidth: 500,
      defaultHeight: 400,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Google Map",
        image: GoogleMapImage,
        group: "media",
      },
    },
  },
  {
    type: ComponentItemType.GoogleMapAutocomplete,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      defaultValue: "",
      controls: true,
      googlePlacesApiKey: "",
    } as any,
    config: {
      defaultWidth: 300,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Google Places",
        image: GoogleMapAutocompleteImage,
        group: "media",
      },
    },
  },
  {
    type: ComponentItemType.StripePaymentElements,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      controls: true,
      stripeTheme: "stripe",
      stripeLabels: "floating",

      stripeSecretEndpoint: "",
      stripeVariables: {
        colorPrimary: "#0570de",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "2px",
        borderRadius: "4px",
      },
      stripeRules: {
        ".Tab": {
          border: "1px solid #E0E6EB",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
        },
        ".Tab:hover": {
          color: "var(--colorText)",
        },
        ".Tab--selected": {
          borderColor: "#E0E6EB",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
        },
        ".Input--invalid": {
          boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
        },
      },
    } as any,
    config: {
      defaultWidth: 500,
      defaultHeight: 300,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Stripe Form",
        image: StripePaymentElementsImage,
        group: "stripeElements",
      },
    },
  },
  {
    type: ComponentItemType.StripeCardNumberElement,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      controls: true,
      stripeShowIcon: true,
      stripeIconStyle: "solid",
      stripePlaceholder: "Card Number",
      stripeVariables: {
        iconColor: "#666",
        color: "#333",
        backgroundColor: "#f5f5f5",
        fontWeight: "400",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        padding: "10px 15px",
        border: "1px solid #ccc",
        borderRadius: "4px",
      },
    } as any,
    config: {
      defaultWidth: 500,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Number Card",
        image: StripeCardNumberElementImage,
        group: "stripeElements",
      },
    },
  },
  {
    type: ComponentItemType.StripeCardCvcElement,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      stripePlaceholder: "Security Code",
      stripeVariables: {
        color: "#333",
        backgroundColor: "#f5f5f5",
        fontWeight: "400",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        padding: "10px 15px",
        border: "1px solid #ccc",
        borderRadius: "4px",
      },
      controls: true,
    } as any,
    config: {
      defaultWidth: 200,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Security Code Card",
        image: StripeCardCvcElementImage,
        group: "stripeElements",
      },
    },
  },
  {
    type: ComponentItemType.StripeCardExpiryElement,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      stripePlaceholder: "Expiration Date",
      stripeVariables: {
        color: "#333",
        backgroundColor: "#f5f5f5",
        fontWeight: "400",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        padding: "10px 15px",
        border: "1px solid #ccc",
        borderRadius: "4px",
      },
      controls: true,
    } as any,
    config: {
      defaultWidth: 200,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Expiration Date Card",
        image: StripeCardExpiryElementImage,
        group: "stripeElements",
      },
    },
  },
  {
    type: ComponentItemType.StripeAddressElement,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      stripeVariables: {
        lineHeight: "50px",
        padding: "5px 10px 5px 20px",
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        backgroundColor: "#f5f5f5",
      },
      controls: true,
    } as any,
    config: {
      defaultWidth: 400,
      defaultHeight: 300,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Address Elements",
        image: stripeAddressElementImage,
        group: "stripeElements",
      },
    },
  },
  {
    type: ComponentItemType.FileUploadInput,
    props: {
      label: "Text Field",
      defaultValue: "",
      autoComplete: "off",
      placeholder: "Upload File",
      uploaderType: "Default",
      type: "file",
      InputProps: {
        sx: {
          height: "100%",
        },
      },
      InputLabelProps: {
        shrink: true,
      },
      sx: {
        width: "100%",
        height: "100%",
      },
    } as TextFieldProps,
    config: {
      defaultWidth: 250,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "File Upload",
        image: FileUploadInputImage,
        group: "media",
      },
      uploadConfig: {
        uploadUrlType: "aws",
        withPreview: true,
        widthPreview: 120,
        heightPreview: 120,
        borderRadiusPreview: 2,
      },
      cssPaths: ["sx", "InputProps.sx"],
    },
  },
  {
    type: ComponentItemType.DatePicker,
    props: {
      label: "Date Picker",
      sx: {
        height: "100%",
        width: "100%",
        "& .MuiInputBase-root": { height: "100%" },
      },
    } as unknown as DatePickerProps<any, any>,
    config: {
      defaultWidth: 250,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Date Picker",
        image: DatePickerImage,
        group: "inputs",
      },
      cssPaths: ["sx"],
      displayStyle: "DatePicker",
      startDate: null,
      isUserLocalTime: true,
      endDate: null,
    },
  },
  {
    type: ComponentItemType.DateTimePicker,
    props: {
      label: "Date&Time Picker",
      sx: {
        height: "100%",
        width: "100%",
        "& .MuiInputBase-root": { height: "100%" },
      },
    } as unknown as DateTimePickerProps<any, any>,

    config: {
      defaultWidth: 250,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Date & Time Picker",
        image: DateTimePickerImage,
        group: "inputs",
      },
      cssPaths: ["sx"],
      startDate: null,
      isUserLocalTime: true,
      endDate: null,
      hoursEnabled: true,
      minutesEnabled: true,
      secondsEnabled: true,
      hoursStep: 1,
      minutesStep: 5,
      secondsStep: 5,
      ampm: true,
    },
  },
  {
    type: ComponentItemType.TimePicker,
    props: {
      label: "Time Picker",
      sx: {
        height: "100%",
        width: "100%",
        "& .MuiInputBase-root": { height: "100%" },
      },
    } as unknown as TimePickerProps<any, any>,

    config: {
      defaultWidth: 250,
      defaultHeight: 50,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Time Picker",
        image: TimePickerImage,
        group: "inputs",
      },
      cssPaths: ["sx"],
      hoursEnabled: true,
      minutesEnabled: true,
      secondsEnabled: false,
      hoursStep: 1,
      minutesStep: 5,
      secondsStep: 5,
      ampm: true,
    },
  },
  {
    type: ComponentItemType.CustomContainer,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        minHeight: "100px",
        backgroundColor: "transparent",
      },
    } as CustomContainerProps,

    children: [
      {
        id: uuid(),
        type: ComponentItemType.CustomTabs,
        props: {
          sx: {
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            zIndex: 10,
            position: "relative",
          },
          height: "100px",
          tabs: [{ label: "default" }],
          value: 0,
        } as CustomTabsProps,
        config: {
          defaultWidth: "100%",
          controlledComponent: true,
          defaultHeight: 50,
          minWidth: 0,
          disableDrag: true,
          selectParent: true,
          minHeight: 0,
          fixedWidth: { lg: true },
          isPercentageHeight: { lg: true },
          disableResizeWidth: true,
          disableResizeHeight: true,
          isNotResizable: true,
          placeholderConfig: {
            title: "Taps",
            image: DividerImage,
          },
          parentStyle: { backgroundColor: "transparent" },
        },
      },
      {
        id: uuid(),
        type: ComponentItemType.CustomContainer,
        props: {
          sx: {
            width: "0%",
            height: "100%",
            minHeight: "100px",
          },
        },
        config: {
          tabValue: 0,
          defaultWidth: "100%",
          defaultHeight: "100%",
          minWidth: 0,
          minHeight: 0,
          disableDrag: true,
          selectParent: true,
          disableResizeWidth: true,
          disableResizeHeight: true,
          isNotResizable: true,
          fixedWidth: { lg: true },
          isPercentageHeight: { lg: false },
          sideMenuSx: {
            border: "1px dashed #57585c",
          },
          placeholderConfig: {
            title: "Container",
          },
          parentStyle: { backgroundColor: "transparent" },
          widthPx: {
            xs: "100%",
            xl: "100%",
          },
          heightPx: {
            xs: "100%",
            xl: "100%",
          },
          widthPercentage: {
            xs: "100%",
            xl: "100%",
          },
          hasCustom: false,
        },
        index: 1,
        level: null,
        hasCustom: false,
        left: {
          xs: 0,
          xl: 0,
        },
        top: {
          xs: 0,
          xl: 0,
        },
        leftPercentage: {
          xs: "0%",
          xl: "0%",
        },
      },
    ],
    config: {
      defaultWidth: 200,
      defaultHeight: 100,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      sideMenuSx: {
        border: "1px dashed #57585c",
      },
      placeholderConfig: {
        title: "Container Tab",
        image: CustomContainerImage,
        group: "inputs",
      },
      parentStyle: { backgroundColor: "transparent" },
      isContainTabs: true,
    },
  },
  {
    type: ComponentItemType.ColorPicker,
    props: {
      defaultValue: "#FFFFFF",
      placeholder: "Choose color",
      isColor: true,
      sx: {
        width: "100%",
        height: "100%",
      },
      isReadOnly: false,
      isLeft: true,
      disableAlpha: false,
    } as any,
    config: {
      defaultWidth: 250,
      defaultHeight: 75,
      minWidth: 0,
      minHeight: 70,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Color Picker",
        image: ColorPickerImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.StepperNavigator,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
    } as any,
    config: {
      defaultWidth: 60,
      defaultHeight: 25,
      minWidth: 0,
      minHeight: "fit-content",
      maxHeight: "fit-content",
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      isDynamicHeight: { lg: true },
      isDynamicWidth: { lg: true },
      controlledComponent: true,
      navigator: {
        groupReference: "",
        bulletsClickable: true,
        orientation: "horizontal",
        bulletHoverOpacity: 1,
        bulletHoverColor: "gray",
        selectedBulletShape: "50%",
        selectedBulletColor: "white",
        selectedBulletWidth: "10px",
        selectedBulletHeight: "10px",
        unselectedBulletHeight: "10px",
        unselectedBulletWidth: "10px",
        bulletDisplay: "showSelectedOnly",
        unselectedBulletOpacity: 0.5,
        unselectedBulletColor: "white",
        unselectedBulletShape: "50%",
        selectedBulletOpacity: 1,
      },
      placeholderConfig: {
        title: "Stepper Navigator",
        image: JsonViewerImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.JsonViewer,
    props: {
      defaultValue: {},
      isEditable: false,
      JsonTheme: "monokai",
      sx: {
        width: "100%",
        height: "100%",
      },
    } as any,
    config: {
      defaultWidth: 250,
      defaultHeight: 150,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "Json",
        image: JsonViewerImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.MarkdownViewer,
    props: {
      label: "# Hi, *Pluto*!",
      sx: {
        width: "100%",
        height: "100%",
      },
    } as any,
    config: {
      defaultWidth: 250,
      defaultHeight: 150,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      placeholderConfig: {
        title: "Markdown",
        image: MarkdownImage,
        group: "inputs",
      },
    },
  },
  {
    type: ComponentItemType.CustomQR,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      defaultValue: "",
      QRCodeValue: "",
    },
    config: {
      defaultWidth: 128,
      defaultHeight: 128,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      controlledComponent: true,
      placeholderConfig: {
        title: "QR Code",
        image: QRImage,
        group: "media",
      },
    },
  },
  {
    type: ComponentItemType.Spinner,
    props: {
      mapValuesObject: {},
      isMapValues: false,
      sx: {
        width: 24,
        height: 24,
      },
    } as any,
    config: {
      defaultWidth: 24,
      defaultHeight: 24,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: true,
      isPercentageHeight: false,
      disableResizeWidth: false,
      disableResizeHeight: false,
      isNotResizable: false,
      placeholderConfig: {
        title: "Spinner",
        image: Spinner,
        group: "media",
      },
    },
  },
]
  .map((c: any) =>
    joinObjects(c, {
      config: {
        ...c.config,
        fixedWidth: { lg: c.config.fixedWidth?.lg ?? true, xs: c.config.fixedWidth?.xs ?? true },
        isDynamicWidth: { lg: c.config.isDynamicWidth?.lg ?? false, xs: c.config.isDynamicWidth?.xs ?? false },
        isPercentageHeight: { lg: c.config.isPercentageHeight?.lg ?? false, xs: c.config.isPercentageHeight?.xs ?? false },
        isDynamicHeight: { lg: c.config.isDynamicHeight?.lg ?? false, xs: c.config.isDynamicHeight?.xs ?? false },
      },
    })
  )
  .map(c => joinObjects(c, { config: { ...c.config, parent: null } }));

export const getItemClosestProp = (prop: any, breakPoint: string) => {
  if (prop?.[breakPoint] !== undefined) return prop?.[breakPoint];
  const layoutBreak = layoutBreakPoints?.find(el => prop?.[el?.id]);
  return layoutBreak?.id && prop?.[layoutBreak.id];
};

export const resolveSxStylingInBuilder = (props: any, layoutBreak: any) => {
  const newProps = {
    ...props,
    sx: {
      ...props?.sx,
      fontSize: getItemClosestProp(props?.sx?.fontSize, layoutBreak) || props?.sx?.fontSize,
      padding: getItemClosestProp(props?.sx?.padding, layoutBreak) || props?.sx?.padding,
      flexDirection: getItemClosestProp(props?.sx?.flexDirection, layoutBreak) || props?.sx?.flexDirection,
      gap: getItemClosestProp(props?.sx?.gap, layoutBreak) || props?.sx?.gap,
    },
  };
  return newProps;
};

export const resolveResponsiveProperties = (item: any, breakpoint: string): void => {
  const breakpoints = ["xl", "lg", "md", "xs"];

  const resolveProperty = (prop: any): any => {
    if (typeof prop === "object" && prop !== null) {
      // If the property contains breakpoint keys, resolve the value
      if (breakpoints.some(key => key in prop)) {
        if (prop[breakpoint] !== undefined) {
          return prop[breakpoint];
        }

        if (prop.lg !== undefined) {
          return prop.lg;
        }

        return getItemClosestProp(prop, breakpoint);
      }

      // Otherwise, recursively resolve each nested property
      Object.keys(prop).forEach(key => {
        prop[key] = resolveProperty(prop[key]);
      });

      return prop;
    }

    // If the value is not an object, keep it as is
    return prop;
  };

  Object.keys(item || {}).forEach(key => {
    item[key] = resolveProperty(item[key]);
  });
};

export const getNewPosition = (key: string | undefined, step: number) => {
  switch (key) {
    case "ArrowUp":
      return { y: -step };
    case "ArrowDown":
      return { y: +step };
    case "ArrowLeft":
      return { x: -step };
    case "ArrowRight":
      return { x: +step };
    default:
      return undefined;
  }
};

export const getContainerDimensions = (items: any, isDragging: boolean, layoutBreak: any) => {
  const itemReal = document.getElementById(items?.[0]?.id)?.getBoundingClientRect();
  let newDimensions = {
    top: itemReal?.y!,
    left: itemReal?.x!,
    height: itemReal?.height!,
    width: itemReal?.width!,
  };
  items?.map((item: any) => {
    const itemReal = document.getElementById(item?.id)?.getBoundingClientRect();
    newDimensions.top = itemReal?.y! < newDimensions.top ? itemReal?.y! : newDimensions.top;
    newDimensions.left = itemReal?.x! < newDimensions.left ? itemReal?.x! : newDimensions.left;
    newDimensions.height =
      itemReal?.height! + itemReal?.y! > newDimensions.height + newDimensions.top
        ? itemReal?.y! - newDimensions.top + itemReal?.height!
        : newDimensions.height;
    newDimensions.width =
      itemReal?.width! + itemReal?.x! > newDimensions.width + newDimensions.left
        ? itemReal?.x! - newDimensions.left + itemReal?.width!
        : newDimensions.width;
  });

  return {
    ...newDimensions,
    position: "absolute",
    opacity: isDragging ? 0 : 1,
    backgroundColor: "red",
  };
};

export const getLastElementPositionY = (items: any, boxPosition: any, boxHeight: any, layoutBreak: any) => {
  let lastElementPosition = 0;
  let hasCustom = false;
  let canvasItems: any[] = Object.values(items);
  // if (items && items[0]?.config?.flexCanvas) {
  //   canvasItems = items[0].children;
  // }
  canvasItems = canvasItems?.filter(item => !item?.config.flexCanvas);

  canvasItems?.map((item: any) => {
    const itemReal = document.getElementById(item?.id)?.getBoundingClientRect();
    lastElementPosition = itemReal?.height! + itemReal?.y! > lastElementPosition ? itemReal?.y! + itemReal?.height! : lastElementPosition;
    hasCustom = item?.config?.hasCustom;
  });

  const canvasHeight = Math.round(lastElementPosition - boxPosition?.y);

  const newHeight = {
    ...boxHeight,
    [layoutBreak]: canvasHeight,
  };

  if (!hasCustom) {
    newHeight.xs = canvasHeight;
  }

  return canvasItems?.length && lastElementPosition > 0 ? newHeight : {};
};

export const getLastElementPositionYForBox = (items: any, boxPosition: any) => {
  let lastElementPosition = 0;
  let canvasItems = items;
  // if (items && items[0]?.config?.flexCanvas) {
  //   canvasItems = items[0].children;
  // }
  canvasItems = canvasItems?.filter(item => !item?.config.flexCanvas);

  canvasItems?.map((item: any) => {
    const itemReal = document.getElementById(item?.id)?.getBoundingClientRect();
    lastElementPosition = itemReal?.height! + itemReal?.y! > lastElementPosition ? itemReal?.y! + itemReal?.height! : lastElementPosition;
  });

  const canvasHeight = Math.round(lastElementPosition - boxPosition?.y);

  return canvasItems?.length ? canvasHeight : "100%";
};

export const getLastElementPositionX = (items: any, boxPosition: any, boxHeight: any, layoutBreak: any) => {
  let lastElementPosition = 0;
  let hasCustom = false;

  let canvasItems: any[] = Object.values(items);

  canvasItems?.map((item: any) => {
    const itemReal = document.getElementById(item?.id)?.getBoundingClientRect();
    lastElementPosition = itemReal?.width! + itemReal?.x! > lastElementPosition ? itemReal?.x! + itemReal?.width! : lastElementPosition;
    hasCustom = item?.config?.hasCustom;
  });

  const newHeight = {
    ...boxHeight,
    [layoutBreak]: Math.round(lastElementPosition - boxPosition?.x),
  };

  if (!hasCustom) {
    newHeight.xs = Math.round(lastElementPosition - boxPosition?.x);
  }

  return items?.length ? newHeight : {};
};

export const flattenList = (list: any[]): any[] => {
  return _.flatMap(list, item => {
    if (item?.children?.length) {
      return [item, ...flattenList(item.children)];
    }
    return item;
  });
};

export const updateStepperGroupIndex = (group: any, type: "UPDATE" | "SET", value: number): any => {
  if (!group) {
    return group;
  }

  const totalPages = _.get(group, "pages.length", 0);
  const isLoopingEnabled = _.get(group, "enableLooping", false);
  let currentPageIndex = _.toNumber(_.get(group, "selectedPage", 0));

  if (_.isNaN(currentPageIndex)) {
    currentPageIndex = _.findIndex(group.pages, (page: any) => page.pageReference === group.selectedPage);
  }

  currentPageIndex = _.clamp(currentPageIndex, 0, totalPages - 1);

  let newPageIndex = -1;

  if (type === "UPDATE") {
    newPageIndex = currentPageIndex + value;
  } else if (type === "SET") {
    let parsedValue = _.toNumber(value);

    if (_.isNaN(parsedValue)) {
      parsedValue = _.findIndex(group.pages, { pageReference: value });
    }

    newPageIndex = _.clamp(parsedValue, 0, totalPages - 1);
  }

  if (isLoopingEnabled) {
    newPageIndex = ((newPageIndex % totalPages) + totalPages) % totalPages;
  } else {
    newPageIndex = _.clamp(newPageIndex, 0, totalPages - 1);
  }

  return {
    ...group,
    selected: getSelectedPageInfo(newPageIndex, group?.pages),
    selectedPage: newPageIndex,
  };
};

export function setStepperParents(formBuilder, updatedStepperGroups) {
  if (!formBuilder) return [];

  const pageReferenceToStepperGroupKey = {};

  _.forOwn(updatedStepperGroups, stepperGroup => {
    const pages = stepperGroup.pages || [];
    const stepperKey = stepperGroup.groupName || [];
    pages.forEach(page => {
      const pageReference = page.pageReference;
      if (pageReference) {
        pageReferenceToStepperGroupKey[pageReference] = stepperKey;
      }
    });
  });

  function setStepperParent(components) {
    return components?.map(component => {
      let newComponent = { ...component };

      const stepperGroupKey = pageReferenceToStepperGroupKey?.[component?.props?.key];
      newComponent.config = {
        ...newComponent.config,
        stepperParent: stepperGroupKey ?? null,
      };

      if (component?.children && component?.children?.length > 0) {
        newComponent.children = setStepperParent(component.children);
      }

      return newComponent;
    });
  }

  return setStepperParent(formBuilder);
}

export const getSelectedPageInfo = (selectedPage: number | string, pages: any[]): { ref?: string; index: number } => {
  if (!pages) return { ref: undefined, index: 0 };
  let selectedIndex = _.toNumber(selectedPage);

  if (_.isNaN(selectedIndex)) {
    selectedIndex = _.findIndex(pages, { pageReference: selectedPage });
  }

  selectedIndex = _.clamp(selectedIndex, 0, pages.length - 1);

  const ref = _.get(pages, [selectedIndex, "pageReference"], undefined);

  return { ref, index: selectedIndex };
};

const getStepperComponentStateForGroup = (componentKey: string, group: any, isPreview?: boolean): StepperComponentState => {
  if (!group) {
    return StepperComponentState.NotFound;
  }

  const pages = _.get(group, "pages", []);
  const selectedPage = _.get(group, "selectedPage", 0);
  const previewIndex = _.get(group, "previewIndex", 0);

  let showViewIndex = _.toNumber(selectedPage);

  if (_.isNaN(showViewIndex)) {
    showViewIndex = _.findIndex(pages, (page: any) => page.pageReference === selectedPage);
  }

  showViewIndex = _.clamp(isPreview ? previewIndex : showViewIndex, 0, pages.length - 1);

  const componentIndex = _.findIndex(pages, {
    pageReference: componentKey,
  });

  if (componentIndex !== -1) {
    return showViewIndex === componentIndex ? StepperComponentState.FoundAndSelected : StepperComponentState.FoundButNotSelected;
  }

  return StepperComponentState.NotFound;
};

export const stepperGroupComponentState = (
  componentKey: string,
  groups: { [key: string]: any } | any,
  isPreview?: boolean
): StepperComponentState => {
  if (!groups) {
    return StepperComponentState.NotFound;
  }

  if (!_.isPlainObject(groups) || _.has(groups, "pages")) {
    // Single group object
    return getStepperComponentStateForGroup(componentKey, groups, isPreview);
  }

  // Multiple groups object
  for (const key in groups) {
    if (_.has(groups, key)) {
      const group = groups[key];
      const state = getStepperComponentStateForGroup(componentKey, group, isPreview);
      if (state !== StepperComponentState.NotFound) {
        return state;
      }
    }
  }

  return StepperComponentState.NotFound;
};

export function findElementInObject(object: any, childIds: any) {
  if (Array.isArray(object)) {
    // Remove childIds from array
    object = object.find(child => childIds.includes(child.id));
  }
  if (typeof object === "object") {
    // Remove childIds from children property
    if (Array.isArray(object?.children)) {
      object.children = object.children.find((child: any) => !childIds.includes(child.id));
    }

    // Recursively process nested objects
    for (const prop in object) {
      if (object.hasOwnProperty(prop) && typeof object[prop] === "object") {
        object[prop] = findElementInObject(object[prop], childIds);
      }
    }

    // Remove object itself if its ID is in childIds
    if (childIds.includes(object?.id)) {
      object = null;
    }
  }

  return object;
}
export function addChildrenToObjectId(object: any, id: any, children: any) {
  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      if (object[i].id === id && Array.isArray(object[i].children)) {
        const numberOfItems = object[i].children.length;
        const _children = children.map((el: any, i: number) => ({ ...el, index: numberOfItems + i }));
        object[i].children.push(..._children);
      } else {
        addChildrenToObjectId(object[i].children, id, children);
      }
    }
  } else if (typeof object === "object" && Array.isArray(object?.children)) {
    if (object.id === id) {
      const numberOfItems = object.children.length;
      const _children = children.map((el: any, i: number) => ({ ...el, index: numberOfItems + i }));
      object.children.push(..._children);
    } else {
      addChildrenToObjectId(object.children, id, children);
    }
  }

  return object;
}

export function findObjectsByIds(nestedObjects: any, idArray: any) {
  const matchingObjects: any = [];

  function traverseObjects(objects: any) {
    for (const obj of objects) {
      if (idArray.includes(obj.id)) {
        matchingObjects.push(obj);
      }
      if (obj.children && Array.isArray(obj.children)) {
        traverseObjects(obj.children);
      }
    }
  }

  traverseObjects(nestedObjects);
  return matchingObjects;
}

export const areEqual =
  (excludedProps: string[] = []) =>
  (prevProps: any, nextProps: any) => {
    for (const prop of Object.keys(prevProps)) {
      if (!excludedProps.includes(prop) && prevProps[prop] !== nextProps[prop]) {
        return false;
      }
    }
    return true;
  };

export function flattenItems(items: any) {
  let flattenedItems: any = [];

  function flatten(item: any) {
    flattenedItems.push(item);

    if (item.children && item.children.length > 0) {
      item.children.forEach(flatten);
    }
  }

  items?.forEach(flatten);

  return flattenedItems;
}

export function isParentFlex(item: any): boolean {
  const itemParent = getBuilderElementById(item?.parentId);
  return itemParent?.type === ComponentItemType.FlexContainer || itemParent?.type === ComponentItemType.StepperContainer;
}

export function isParentGrid(item: any): boolean {
  const itemParent = getBuilderElementById(item?.parentId);

  return itemParent?.type === ComponentItemType.GridContainer;
}

export const getRootParent = (item: any, level: number = 0): { item: any; level: number } | undefined => {
  if (!item) return undefined;
  if (!item?.config?.parent?.id) return { item, level };
  return getRootParent(item.config.parent, level + 1);
};
export const updateChildrenAtLevelN = (formBuilder: any[], itemId: string, parentId: string, level: number, newChildren: any[]): any[] => {
  return formBuilder.map(item => {
    if (item.id === parentId && item.children && level === 0) {
      return {
        ...item,
        children: newChildren,
      };
    }
    if (item.children && level > 0) {
      return {
        ...item,
        children: updateChildrenAtLevelN(item.children, itemId, parentId, level - 1, newChildren),
      };
    }
    return item;
  });
};

export function splitStringToNumbers(inputString) {
  return inputString
    .split(",")
    .map(function (item) {
      return parseFloat(item.trim());
    })
    .filter(function (number) {
      return !isNaN(number);
    });
}

export const chartsNames = [
  "Line Chart",
  "SplineChart",
  "Circular Chart",
  "CircularChart",
  "Pie Chart",
  "PieChart",
  "Bar Chart",
  "ColumnChart",
];

export const getDirectParentChildren = (formBuilder: any[], directParent: any): any[] => {
  if (!formBuilder || !directParent) return [];

  const findChildren = (items: any[], parent: any): any[] => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id === parent.id && item.type === parent.type) {
        return item.children || [];
      } else if (item.children && item.children.length) {
        const children = findChildren(item.children, parent);
        if (children.length) {
          return children;
        }
      }
    }
    return [];
  };

  return findChildren(formBuilder, directParent);
};

export function replaceOrAddObjectsById(objects: any, newObjects: any) {
  newObjects.forEach((newObj: any) => {
    let id = newObj.id;
    let found = false;

    objects = objects.map((obj: any) => {
      if (obj.id === id) {
        found = true;
        return newObj;
      } else if (Array.isArray(obj.children) && obj.children.length > 0) {
        const updatedChildren = replaceOrAddObjectsById(obj.children, [newObj]);

        found = true;
        return { ...obj, children: updatedChildren };
      }
      return obj;
    });

    if (!found && id) {
      objects.push(newObj);
    }
  });

  return objects;
}

export function findIdsInNestedArrays(items: any, ids: any) {
  const result: any = [];

  function searchIdsInArray(arr: any) {
    arr?.map((el: any) => {
      if (el.id && ids.includes(el.id)) {
        result.push(el.id);
      }
      if (el.children && Array.isArray(el.children)) {
        searchIdsInArray(el.children);
      }
    });
  }

  searchIdsInArray(items);
  return result;
}

export function isPageScrollable() {
  const body = document.body;
  const html = document.documentElement;

  // Get the full height of the page content
  const contentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

  // Get the height of the visible viewport
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  // Compare the two heights to determine scrollability
  return contentHeight > viewportHeight;
}

export function removeFlexCanvas() {
  const topLevel = selectTopLevelComponents(store.getState());
  const element = selectComponentById(store.getState(), topLevel);
  const newItem = {
    ...element,
    type: "CustomContainer",
    skipHistory: true,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        userSelect: "auto",
        pointerEvents: "auto",
      },
      id: "Canvas-Component-Parent",
      key: "Canvas-Component-Parent",
    },
  };

  store.dispatch(upsertComponents([newItem]));
}

export function addFlexCanvas() {
  const topLevel = selectTopLevelComponents(store.getState());
  const element = selectComponentById(store.getState(), topLevel);

  const newItem = {
    ...element,
    type: "FlexContainer",
    skipHistory: true,
    props: {
      ...element.props,
      sx: {
        ...element.props.sx,
        display: "flex",
        flexDirection: "column",
      },
    },
  };

  store.dispatch(upsertComponents([newItem]));
}
export function addContainerCanvas(elements: any) {
  const newId = uuid();
  const flexParent = {
    id: newId,
    type: "CustomContainer",
    children: [] as any,
    isCustomCanvas: true,
    props: {
      sx: {
        width: "100%",
        height: "100%",
        userSelect: "auto",
        pointerEvents: "auto",
      },
      id: "Canvas-Component-Parent",
      key: "Canvas-Component-Parent",
    },
    parentId: null,
    skipHistory: true,
    config: {
      defaultWidth: 200,
      defaultHeight: 100,
      minWidth: 0,
      minHeight: 0,
      fixedWidth: { lg: true },
      isPercentageHeight: { lg: false },
      isNotResizable: true,
      parent: null,
      hasCustom: true,
      widthPx: {
        xs: "100%",
        md: "100%",
        lg: "100%",
        xl: "100%",
      },
      heightPx: {
        xs: "100%",
        md: "100%",
        lg: "100%",
        xl: "100%",
      },
      widthPercentage: {
        xs: "100%",
        md: "100%",
        lg: "100%",
        xl: "100%",
      },
      flexCanvas: true,
    },
    index: 0,
    left: {
      xs: 0,
    },
    top: {
      xs: 0,
    },
    leftPercentage: {
      xs: "0%",
    },
  };

  const topLevelComponents = Object.values(elements).filter((el: any) => el.parentId === null);
  const topLevelComponentsWithNewParent = topLevelComponents.map((el: any) => ({
    ...el,
    parentId: newId, //Assign the flex canvas item new Id as the parent Id.
  }));

  flexParent.children = topLevelComponents.map((el: any) => el.id);

  store.dispatch(upsertComponents([...topLevelComponentsWithNewParent, flexParent]));
}

export function populateViews(currentAppSelected, _collectionId, _pageId) {
  //Append shared views to be accessible from any builder-operation for any page/layout/item of the app.
  const sharedCollection = currentAppSelected?.templateConfig?.collections?.find(col => col.isShared);
  const sharedViews = sharedCollection?.pages?.flatMap(page => page.views) || [];
  const _collection = currentAppSelected?.templateConfig?.collections?.find(col => col.id === _collectionId);
  const _page = _collection?.pages?.find(page => page.id === _pageId);
  return [...((_page?.views as UIElement[]) || []), ...sharedViews];
}

export function getSharedViews(currentAppSelected) {
  const sharedCollections = currentAppSelected?.templateConfig?.collections?.filter(col => col.isShared);
  const sharedViews = sharedCollections?.flatMap?.(col => col?.pages?.flatMap?.(page => page.views) || []);
  return sharedViews;
}

export function recursiveRemove(obj, keyToDelete) {
  if (Array.isArray(obj)) {
    obj.forEach(item => recursiveRemove(item, keyToDelete));
  } else if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === keyToDelete) {
          delete obj[key];
        } else {
          recursiveRemove(obj[key], keyToDelete);
        }
      }
    }
  }
}

export function snapToStepperGrid(x, y) {
  const snappedX = Math.round(x / 10) * 10;
  const snappedY = Math.round(y / 10) * 10;

  return [snappedX, snappedY];
}
export const mapValuesRecursively = (obj: any = {}) => {
  if (obj.isMapValues) {
    Object.keys(obj).forEach(propKey => {
      const condition = obj[propKey]?.mapValuesObject?.condition;

      if (typeof obj[propKey] === "object" && obj[propKey] !== null && !("mapValuesObject" in obj[propKey])) {
        obj[propKey] = mapValuesRecursively(obj[propKey]);
      } else if (obj[propKey]?.mapValuesObject?.values) {
        const mapValues = obj[propKey].mapValuesObject.values;
        const valueToMatch = obj[propKey]?.value;
        let matchedValue = mapValues["<Default>"] || mapValues.default;
        if (condition === "equal" && mapValues[valueToMatch]) {
          matchedValue = mapValues[valueToMatch];
        } else if (condition === "startWith") {
          const valueToMatchStr = String(valueToMatch);
          const matchingKey = Object.keys(mapValues).find(key => valueToMatchStr.startsWith(key));
          if (matchingKey) {
            matchedValue = mapValues[matchingKey];
          }
        }
        obj[propKey] = matchedValue;
      }
    });

    return obj;
  }
  return obj;
};

export const handleSlugChange = (newValue, prevValueRef, getValues, setValue, getSlug = "slug", addSlash = true) => {
  const toCamelCase = str => {
    return `${addSlash ? "/" : ""}${str
      .toLowerCase()
      .split(" ")
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join("")}`;
  };

  const slug = getValues(getSlug) || "";
  const camelCaseSlug = toCamelCase(newValue);
  const prevCamelCaseSlug = toCamelCase(prevValueRef.current);

  if (
    slug === prevCamelCaseSlug || (prevValueRef.current.length == 0 && newValue.length === 1 && slug.length == 0) || slug.length <= addSlash
      ? 1
      : 0
  ) {
    setValue(getSlug, camelCaseSlug);
  }

  prevValueRef.current = newValue;
};

export const getBuilderElementById = (parentId: string) => {
  return builderAdapter.getSelectors().selectById(store.getState().builder, parentId);
};

export const updateElementConfigAndProps = (el: any, containerItem: any) => {
  const isCheckboxOrRadio = el?.type === ComponentItemType.CustomCheckbox || el?.type === ComponentItemType.CustomRadio;
  const parentAcceptsType = containerItem?.config?.acceptTypes?.includes(el?.type);
  let updatedProps;
  if (isCheckboxOrRadio && parentAcceptsType) {
    updatedProps = {
      ...el?.props,
      groupName: "{$comp.parent}",
      singleValue: "{this.{$comp.parent}.props.singleValue}",
      required: "{this.{$comp.parent}.props.required}",
      customRequiredMessageContent: "{this.{$comp.parent}.props.customRequiredMessageContent}",
      defaultValue: "{this.{$comp.parent}.props.defaultValue}",
      isChildContainerGroup: true,
    };
  } else {
    updatedProps = el?.props;
  }

  return { updatedProps };
};

export const moveBoxFunction = (
  movedItem: any,
  boxItemRef: any,
  item: any,
  monitor?: any,
  newPosition?: any,
  isKeyboard: boolean = false,
  step: number = 1
) => {
  const scaleFactor = store.getState().builder.scaleFactor;
  // const scaleFactor = 1;
  const isRTL = store.getState().builder.isRTL;
  const layoutBreak = store.getState().builder.layoutBreak;

  if (!monitor?.isOver() && !isKeyboard) {
    return;
  }
  const boxSize = boxItemRef?.getBoundingClientRect();

  const delta = monitor ? monitor.getSourceClientOffset() : document.getElementById(movedItem?.id)?.getBoundingClientRect();
  if (!delta && !isKeyboard) return undefined;

  let translateX = 0;
  let translateY = 0;
  let top = 0;
  let left = 0;

  const components = movedItem?.length ? movedItem : [movedItem];
  const filterComponents = components.filter(el => {
    return el.id;
  });

  const newComponentMove = components?.map((el: any) => {
    const realItem = el?.id && document.getElementById(el?.id)?.getBoundingClientRect();

    const itemWidth = realItem?.width / scaleFactor || el?.config?.defaultWidth;
    const itemHeight = realItem?.height / scaleFactor || el?.config?.defaultHeight;

    let realItemX = realItem?.x / scaleFactor;
    let realItemY = realItem?.y / scaleFactor;

    let adjustedBoxSizeWidth = boxSize?.width / scaleFactor;
    let adjustedBoxSizeHeight = boxSize?.height / scaleFactor;
    let adjustedBoxSizeX = boxSize?.x / scaleFactor;
    let adjustedBoxSizeY = boxSize?.y / scaleFactor;

    if (movedItem?.length && !isKeyboard) {
      translateX = (monitor?.getSourceClientOffset()?.x - monitor?.getInitialSourceClientOffset()?.x) / scaleFactor;
      translateY = (monitor?.getSourceClientOffset()?.y - monitor?.getInitialSourceClientOffset()?.y) / scaleFactor;
      const leftValue = Number(translateX) - Number(adjustedBoxSizeX) + Number(realItemX);
      left = isRTL ? adjustedBoxSizeWidth - itemWidth - leftValue : leftValue;
      top = Number(translateY) - Number(adjustedBoxSizeY) + Number(realItemY);
    } else if (realItem && !isKeyboard) {
      const diffX = (monitor.getDifferenceFromInitialOffset().x + (newPosition?.x || 0)) / scaleFactor;
      const diffY = (monitor.getDifferenceFromInitialOffset().y + (newPosition?.y || 0)) / scaleFactor;

      translateX = Math.round(Number(diffX + realItemX));
      translateY = Math.round(Number(diffY + realItemY));

      const leftValue = Number(translateX) - Number(adjustedBoxSizeX);
      left = isRTL ? adjustedBoxSizeWidth - itemWidth - leftValue : leftValue;
      top = Number(translateY) - Number(adjustedBoxSizeY) + Number(el?.config?.draggingOffSet || 0);
    } else {
      const newScaleFactor = isKeyboard ? 1 : scaleFactor;
      translateX = ((Number(delta?.x) || realItemX) + (newPosition?.x || 0)) / newScaleFactor;
      translateY = ((Number(delta?.y) || realItemY) + (newPosition?.y || 0)) / newScaleFactor;
      let leftValue = Number(translateX) - Number(adjustedBoxSizeX);
      left = isRTL ? adjustedBoxSizeWidth - itemWidth - leftValue : leftValue;
      top = Number(translateY) - Number(adjustedBoxSizeY) + Number(el?.config?.draggingOffSet || 0);
    }

    // if (isStepperEnabled) {
    //   [left, top] = snapToStepperGrid(left, top);
    // }

    const realItemWidth = realItem?.width / scaleFactor || el?.config?.defaultWidth;
    const realItemHeight = realItem?.height / scaleFactor || el?.config?.defaultHeight;

    let PLeft = left < 0 ? 0 : left + realItemWidth > adjustedBoxSizeWidth ? adjustedBoxSizeWidth - realItemWidth : left;
    const PTop = top < 0 ? 0 : top + realItemHeight > adjustedBoxSizeHeight ? adjustedBoxSizeHeight - realItemHeight : top;

    let leftPercentage = ((PLeft / adjustedBoxSizeWidth) * 100 || 0).toFixed(2) + "%";
    const PWidth = (itemWidth / adjustedBoxSizeWidth) * 100;
    const percentageWidth = PWidth > 100 ? "100%" : PWidth.toFixed(2) + "%";

    const PHeight = (realItemHeight / adjustedBoxSizeHeight) * 100;
    const percentageHeight = PHeight > 100 ? "100%" : PHeight.toFixed(2) + "%";

    const hasCustom = el?.config?.hasCustom;

    const { updatedProps } = updateElementConfigAndProps(el, item);

    return {
      containerItem: el,
      hasCustom: layoutBreak === "xs",
      left: {
        ...el?.left,
        xs: hasCustom ? el.left?.xs : left,
        [layoutBreak]: PLeft,
      },
      top: {
        ...el?.top,
        xs: hasCustom ? el.top?.xs : top,
        [layoutBreak]: PTop,
      },
      leftPercentage: {
        ...el?.leftPercentage,
        xs: hasCustom ? el.leftPercentage?.xs : leftPercentage,
        [layoutBreak]: leftPercentage,
      },
      config: {
        ...el?.config,
        widthPx: {
          ...el?.config?.widthPx,
          xs: hasCustom ? el?.config?.widthPx?.xs : Math.round(realItemWidth),
          [layoutBreak]: Math.round(realItemWidth),
        },
        heightPx: {
          ...el?.config?.heightPx,
          xs: hasCustom ? el?.config?.heightPx?.xs : Math.round(realItemHeight),
          [layoutBreak]: Math.round(realItemHeight),
        },
        widthPercentage: {
          ...el?.config?.widthPercentage,
          xs: el?.config?.widthPercentage?.xs || percentageWidth,
          [layoutBreak]: percentageWidth,
        },
        heightPercentage: {
          ...el?.config?.heightPercentage,
          xs: el?.config?.heightPercentage?.xs || percentageHeight,
          [layoutBreak]: percentageHeight,
        },
      },
      props: { ...updatedProps },
    };
  });
  moveMultipleBoxes(newComponentMove, item);
  return undefined;
};
const moveMultipleBoxes = (data: any, item: any) => {
  const newElementsId: any = [];
  const newElements = data?.map((el: any) => {
    let { containerItem, ...rest } = el || {};
    const isContainer = [
      ComponentItemType.CustomContainer,
      ComponentItemType.FlexContainer,
      ComponentItemType.GridContainer,
      ComponentItemType.StepperContainer,
    ].includes(item?.type);

    if (containerItem?.parentId && containerItem?.parentId !== item?.id) {
      store.dispatch(removeChildFromParent({ parentId: containerItem?.parentId, childId: containerItem.id }));
    }
    let stepperParent = null;

    if (item?.type === ComponentItemType.StepperContainer) {
      stepperParent = item?.props?.key;

      const newPage = {
        pageReference: containerItem?.props?.key,
        componentId: containerItem?.id,
        isChildren: true,
      };
      const newPages = [...(item.config?.stepperGroup?.pages || []), newPage];
      let previewPageId = item.config?.stepperGroup?.pages?.previewPageId;

      item = {
        ...item,
        config: {
          ...item.config,
          stepperGroup: {
            ...item.config?.stepperGroup,
            previewPageId,
            pages: newPages,
          },
        },
      };
    } else if (containerItem?.config?.stepperParent) {
      const stepper = selectComponentById(store.getState(), containerItem.config.stepperParent);
      const newPages = stepper?.config?.stepperGroup?.pages?.filter(page => page.componentId !== containerItem.id) || [];
      if (stepper) {
        const updatedStepper = {
          ...stepper,
          config: {
            ...stepper.config,
            stepperGroup: {
              ...stepper.config?.stepperGroup,
              pages: newPages,
            },
          },
        };

        store.dispatch(
          updateComponent({
            id: containerItem.config.stepperParent,
            changes: updatedStepper,
          })
        );

        const nextElementIndex = stepper.config?.stepperGroup?.pages?.indexOf(page => page.componentId === containerItem.id);
        const nextElementId = newPages?.[nextElementIndex === -1 ? 0 : nextElementIndex]?.componentId;
        const nextElement = selectComponentById(store.getState(), nextElementId);
        if (containerItem.config.isVisiblePreviewByStepper && nextElement) {
          const updatedNextElement = {
            ...nextElement,
            config: {
              ...nextElement.config,
              isVisiblePreviewByStepper: true,
            },
          };
          store.dispatch(
            updateComponent({
              id: nextElementId,
              changes: updatedNextElement,
            })
          );
        }
      }
    }

    let newItem = {
      ...containerItem,
      ...rest,
      parentId: item?.id,
      config: {
        ...containerItem?.config,
        ...rest?.config,
        stepperParent,
      },
    };
    if (item.config?.stepperGroup?.pages?.length === 1 && newItem.config) {
      newItem.config.isVisiblePreviewByStepper = true;
    } else if (item?.type === ComponentItemType.StepperContainer) {
      newItem.config.isVisiblePreviewByStepper = false;
    }

    if (!isContainer) {
      newItem = { ...containerItem, ...rest, parentId: null };
      return;
    }

    if (newItem?.id) {
      newElementsId.push(newItem?.id);

      return newItem;
    } else {
      const newId = uuid();
      const camelCaseType = newItem?.type ? toCamelCase(newItem.type) : "";
      newElementsId.push(newId);

      return {
        id: newId,
        ...newItem,
        props: {
          ...newItem?.props,
          id: `${camelCaseType}${newId}-${0}`,
          key: `${camelCaseType}${newId}-${0}`,
          testId: "",
        },
      };
    }
  });

  const containerItemWithNewChildren = {
    ...item,
    children: [...Array.from(new Set([...(item?.children || []), ...newElementsId]))],
  };

  store.dispatch(upsertComponents([...newElements, containerItemWithNewChildren]));
};

export const changeChildren = item => {
  const newItem = { ...item };

  if (newItem.children && newItem.children.length > 0) {
    newItem.children = newItem.children.map(childId => {
      const childItem = selectComponentById(store.getState(), childId);
      return changeChildren(childItem);
    });
  }
  if (newItem.optionMap && typeof newItem.optionMap === "object") {
    const newOptionMap = {};
    for (const [key, value] of Object.entries(newItem.optionMap)) {
      newOptionMap[key] = changeChildren(value);
    }
    newItem.optionMap = newOptionMap;
  }

  return newItem;
};

export function preprocessView(currentApp, collectionId, pageId, viewId, layoutId, pageBuilderMode, appBuilderMode) {
  let currentView = _.cloneDeep(
    currentApp?.templateConfig?.collections
      .find(c => c.id === collectionId)
      ?.pages?.find(p => p.id === pageId)
      ?.views?.find(v => v.id === viewId)
  );

  if (pageBuilderMode) {
    currentView = _.cloneDeep(currentApp?.templateConfig?.collections.find(c => c.id === collectionId)?.pages?.find(p => p.id === pageId));
  }

  if (appBuilderMode) {
    currentView = _.cloneDeep(currentApp?.templateConfig?.layouts.find(l => l.id === layoutId));
  }

  delete currentView?.dataSource?.formBuilder;
  delete currentView?.dataSource?.formBuilderConfig;

  return currentView;
}
