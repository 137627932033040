import { removeChildFromParent, upsertComponents } from "src/features/builder/builderSlice";
import { selectComponentById, selectComponentParent } from "src/features/builder/selectors";
import store from "src/store/store";

export type DIRECTION = "top" | "bottom" | "middle";

const findBlock = (id: string) => {
  const state = store.getState();
  return selectComponentById(state, id); // Fetch block by id from normalized entities
};

const removeBlocks = (idsToRemove: string[]) => {
  const state = store.getState();

  //Remove the dragged blocks from their old parents
  idsToRemove.forEach(id => {
    const parentId = selectComponentParent(state, id);
    const childId = id;

    store.dispatch(removeChildFromParent({ parentId: parentId, childId: childId }));
  });
};

const insertBlocksAtPosition = (targetId: string, newBlocks: any[], position: DIRECTION) => {
  const state = store.getState();

  // Find the target block
  const targetBlock = findBlock(targetId);
  if (!targetBlock) return;

  const targetBlockChildren = targetBlock.children || [];
  const targetBlockParentId = selectComponentParent(state, targetBlock.id);
  const targetBlockParent = targetBlockParentId ? selectComponentById(state, targetBlockParentId) : null;

  // Extract new block IDs
  const newBlockIds = newBlocks.map(block => block.id);

  // Helpers to update parent or target blocks with new children or parent
  const createUpdatedParent = () => {
    if (!targetBlockParent) return null;
    return {
      ...targetBlockParent,
      children: [...newBlockIds, ...(targetBlockParent.children || [])],
    };
  };

  const createMovedBlocks = (parentId: string | null) => {
    return newBlocks.map(block => ({
      ...block,
      parentId,
    }));
  };

  switch (position) {
    case "top": {
      const updatedParent = createUpdatedParent();
      const movedBlocks = createMovedBlocks(targetBlockParentId);

      const dispatchPayload = updatedParent ? [...movedBlocks, updatedParent] : [...movedBlocks];
      store.dispatch(upsertComponents(dispatchPayload));
      break;
    }

    case "bottom": {
      const updatedParent = createUpdatedParent();
      const movedBlocks = createMovedBlocks(targetBlockParentId);

      const dispatchPayload = updatedParent ? [...movedBlocks, updatedParent] : [...movedBlocks];
      store.dispatch(upsertComponents(dispatchPayload));
      break;
    }

    case "middle": {
      // Determine the middle position
      const middleIndex = Math.floor(targetBlockChildren.length / 2);

      // Update target block's children to include new blocks
      const updatedChildren = [...targetBlockChildren.slice(0, middleIndex), ...newBlockIds, ...targetBlockChildren.slice(middleIndex)];

      const movedBlocks = createMovedBlocks(targetBlock.id);

      const updatedBlock = {
        ...targetBlock,
        children: updatedChildren,
      };

      store.dispatch(upsertComponents([...movedBlocks, updatedBlock]));
      break;
    }
  }
};

export const moveBlocks = (draggedIds: string[], targetId: string, direction: DIRECTION) => {
  // Find the blocks to move by their ids
  const blocksToMove = draggedIds.map(id => findBlock(id)).filter(Boolean);

  if (blocksToMove.length === 0) return;

  // Remove the dragged blocks from the current structure
  removeBlocks(draggedIds);

  // Insert the dragged blocks at the target position
  return insertBlocksAtPosition(targetId, blocksToMove, direction);
};
