import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BXEngine } from "src/BXEngine";
import { removeAppField } from "src/features/appState/appStateSlice";
import { useAppState } from "src/features/appState/hooks";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { getLastKeyFromObject } from "src/utils/generalUtils";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { BXIcon } from "../Icon";
import BXModal from "../Modal";

type Props = {
  action?: any;
  onSelectRow?: any;
  item?: any;
  actionSourceType?: any;
  selectedView: any;
  openView?: boolean;
  buttonProps?: any;
  icon?: any;
  views?: any;
  showHeader?: boolean;
  withoutLabel?: boolean;
  modalSize?: string;
  iconColor?: string;
  __data?: any;
  id?: string;
  prevVideoCardId?: string;
  parentIds?: any[];
  buttonComponent?: any;
  index?: any;
  pageId?: any;
  viewName?: string;
  isUserInput?: boolean;
  path?: string;
  clearActionView?: any;
  type?: string;
  keyComp?: string;
};

const ViewerModal = (props: Props) => {
  const {
    id,
    item,
    selectedView,
    openView,
    action,
    onSelectRow,
    actionSourceType,
    buttonProps,
    icon = null,
    withoutLabel,
    views,
    modalSize = "lg",
    showHeader = true,
    __data = {},
    iconColor,
    parentIds = [],
    prevVideoCardId,
    buttonComponent,
    index,
    pageId,
    viewName,
    isUserInput,
    path,
    clearActionView,
    keyComp,
    type, //To specify the view type when the source is not an action
  } = props;
  const { palette } = useTheme();
  const [isDirty, setIsDirty] = useState(false);
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName });
  const title = replaceDataPlaceholders({
    queryString: action?.label,
    item,
    pageId,
    __data,
    selectedView,
  });

  const dispatch = useDispatch();
  const { getDirty, setValue, getValue } = useAppState();
  const isDirtyView = getDirty(`${pageId}.${selectedView?.info?.viewName}`);

  useEffect(() => {
    setIsDirty?.(isDirtyView);
  }, [isDirtyView]);

  return (
    <BXModal
      label={title}
      open={openView}
      icon={
        icon || (
          <BXIcon
            icon={
              (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                ? action?.iconConfig?.url
                : action?.iconConfig?.icon) || action?.icon
            }
            width={16}
            height={16}
            color={iconColor || palette.text.primary}
          />
        )
      }
      buttonProps={{
        variant: "contained",
        startIcon: (
          <BXIcon
            icon={
              (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                ? action?.iconConfig?.url
                : action?.iconConfig?.icon) || action?.icon
            }
            width={20}
            height={20}
            color={iconColor || palette.text.primary}
          />
        ),
        onClick: () => onSelectRow?.(item, true),
        ...buttonProps,
      }}
      title={title}
      maxWidth={modalSize}
      showHeader={showHeader}
      withoutLabel={withoutLabel}
      isDirty={isDirty}
      onClose={() => {
        const stateView = getValue(`${pageId}.${selectedView?.info?.viewName}`);
        setValue(`${pageId}.${viewName}.${keyComp}.response`, stateView, { isDisabledDirtyField: true });
        dispatch(removeAppField({ name: `${pageId}.${selectedView?.info?.viewName}` }));
        clearActionView?.();
      }}
    >
      {(handleClose: Function) => {
        return (
          <>
            <BXEngine
              auth={{}}
              path={[path, action?.label].join(".")}
              setIsDirty={!isUserInput ? setIsDirty : undefined}
              showModalHeader={showHeader}
              page={{ views, layout: selectedView, id: pageId } as any}
              layout={[{ ...selectedView, type: actionSourceType?.type || type }]}
              isVisible
              selectedViewId={item?.id}
              index={index}
              __data={{
                ...__data,
                [(getLastKeyFromObject(__data) || "") + "#."]: viewName,
              }}
              closeModal={(data: any, _isDirty = isDirty) => {
                if (data) {
                  onSelectRow?.(data);
                }

                handleClose(null, null, _isDirty);
              }}
              prevVideoCardId={prevVideoCardId}
              parentIds={[...parentIds, id]}
            />
          </>
        );
      }}
    </BXModal>
  );
};

export default ViewerModal;
