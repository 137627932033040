import { id } from "date-fns/locale";
import _ from "lodash";
import { useEffect } from "react";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { useAppState } from "src/features/appState/hooks";
import { StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { getSelectedPageInfo, stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { RenderChildren } from "./RenderChildren";
import { getElementInit } from "./utils";

function updatePagesForGroup(stepperObject: any, pageReference: string, replicaCount: number, basePath: string) {
  if (replicaCount < 0) {
    return stepperObject;
  }

  const newStepperObject = _.cloneDeep(stepperObject);

  if (!newStepperObject?.pages) return newStepperObject;

  const referencePages = _.cloneDeep(stepperObject.pages);
  newStepperObject.pages = referencePages;

  const mainPageIndex = _.findIndex(newStepperObject.pages, (page: any) => page.pageReference === pageReference);

  if (mainPageIndex !== -1) {
    if (replicaCount === 0) {
      newStepperObject.pages.splice(mainPageIndex, 1);
    } else {
      _.assign(newStepperObject.pages[mainPageIndex], {
        pageReference: `${basePath}[0].${pageReference}`,
        repeatReference: pageReference,
      });

      const replicas = _.times(replicaCount - 1, i =>
        _.assign(_.cloneDeep(newStepperObject.pages[mainPageIndex]), { pageReference: `${basePath}[${i + 1}].${pageReference}` })
      );

      newStepperObject.pages.splice(mainPageIndex + 1, 0, ...replicas);
    }
  }

  return newStepperObject;
}

export const RepeatedItemsComponent = ({ element, item, viewsState, pageId, __data, currentApp, multiLingual, viewName, props }): any => {
  const { handleSelectRow: _handleSelectRow, ...restProps } = props;

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName: props?.info?.viewName });
  const { watch, setValue } = useAppState();

  const { setViewsState } = useBXContext();

  const queryKeys = [`${pageId}-${viewName}-${element?.props?.key}`];

  const minItem = Number(element.config.repeated.minItems) || 0;
  const maxItem = Number(element.config.repeated.maxItems) || Infinity;
  const defaultNumItems = Number(element.config.repeated.defaultItems);
  useValue({
    queryKeys,
    __data,
    viewId: id,
    limit: element?.configData?.paginationKey || 20,
    dataEntry: element?.configData?.dataEntry,
    pageId,
    options: {
      enabled: element?.configData?.sourceType == "API" || element?.configData?.sourceType == "LIST",
    },
    dataSource: element?.configData?.sourceType == "API" && element?.configData?.source,
    endpoint: element?.configData?.sourceType == "LIST" && element?.configData?.selectValues,
    viewName: viewName,
    componentKey: element?.props?.key,
    path: props?.path,
  });

  const key = `${pageId}.${viewName}.${element?.props?.key}`;

  const repeatedData: any = replaceDataPlaceholders({
    queryString: element?.config?.repeated?.data,
    item,
    viewsState,
    pageId,
    __data,
    viewName: props?.info?.viewName,
    env: currentApp?.env,
    fallback: "",
    multiLingual: multiLingual,
  });
  const stepperParentKey = `${pageId}.${viewName}.${element?.config?.stepperParent}.state`;
  const stepperGroup = element?.config?.stepperParent ? watch(stepperParentKey) : null;

  const data = Array.isArray(repeatedData) ? repeatedData : Array.from({ length: minItem }, () => ({}));
  const repeatCount = _.clamp(data.length, minItem, maxItem);
  const repeatData = Array.from(
    {
      length: defaultNumItems && !element?.config?.repeated?.data ? _.clamp(defaultNumItems, minItem, maxItem) : repeatCount,
    },
    (_, index) => data[index] || {}
  );

  function handleSelectRow(selectedRowData) {
    const key = `${pageId}-${viewName}`;
    setViewsState(prev => {
      const data = { ...prev };
      data[key] = {
        ...data?.[key],
        _selectedItem: selectedRowData,
      };

      return data;
    });
  }

  useEffect(() => {
    let referenceGroup = stepperGroup;
    let updatedStepperGroup = replaceDataPlaceholdersRecursively({
      obj: _.cloneDeep(
        updatePagesForGroup(
          referenceGroup,
          element?.props?.key,
          Math.min(maxItem, Math.max(repeatedData?.length || 0, minItem)),
          `${element?.props?.key}.state`
        )
      ),
      item,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: null,
    });
    if (updatedStepperGroup) {
      updatedStepperGroup.selected = getSelectedPageInfo(updatedStepperGroup.selectedPage, updatedStepperGroup.pages);
    }
    setValue(stepperParentKey, updatedStepperGroup, { isDisabledDirtyField: true });
  }, [repeatedData]);

  useEffect(() => {
    const value = getElementInit(
      element,
      item,
      viewsState,
      pageId,
      __data,
      currentApp,
      replaceDataPlaceholders,
      replaceDataPlaceholdersRecursively
    );

    _.set(value, "mainRepeated", true);
    _.set(value, "repeated.enabled", true);

    const collectAllChildElements = (element: any) => {
      let elements: any = [];
      if (element?.props?.key) {
        elements.push(element);
      }
      if (element?.children && Array.isArray(element.children)) {
        element.children.forEach(child => {
          elements = elements.concat(collectAllChildElements(child));
        });
      }
      return elements;
    };

    const allChildElements = collectAllChildElements(element);

    const mainRepeatedValue = _.cloneDeep(value);
    const singleRepeatedValue: any = {};
    allChildElements.forEach((childElement: any) => {
      const childKey = `${childElement.props.key}`;
      const childValue = _.cloneDeep(
        getElementInit(
          childElement,
          item,
          viewsState,
          pageId,
          __data,
          currentApp,
          replaceDataPlaceholders,
          replaceDataPlaceholdersRecursively
        )
      );
      _.set(childValue, "mainRepeated", false);
      _.set(childValue, "repeated.enabled", false);
      _.set(singleRepeatedValue, childKey, _.cloneDeep(childValue));
    });

    for (let itemIndex = 0; itemIndex < repeatData.length; itemIndex++) {
      const baseKey = `${element?.props?.key}.state[${itemIndex}]`;

      _.set(mainRepeatedValue, `state[${itemIndex}]`, _.cloneDeep(singleRepeatedValue));

      allChildElements.forEach(childElement => {
        const childKey = `${childElement.props.key}`;

        if (childKey !== element?.props?.key) {
          const childParent = _.clone(_.get(mainRepeatedValue, `state[${itemIndex}].${childKey}.parent`));
          const newParentPath = `${baseKey}.${childParent}`;
          _.set(mainRepeatedValue, `state[${itemIndex}].${childKey}.parent`, _.clone(newParentPath));
          _.set(
            mainRepeatedValue,
            `state[${itemIndex}].${childKey}.state`,
            replaceDataPlaceholders({
              queryString: childElement?.props?.defaultValue,
              item,
              pageId,
              __data,
              index: itemIndex,
            })
          );
        }
        const childPath = `state[${itemIndex}].${childKey}.props.basePath`;
        _.set(mainRepeatedValue, childPath, _.clone(baseKey));
      });
    }

    _.set(mainRepeatedValue, `baseElement`, singleRepeatedValue);
    if (pageId && viewName) {
      setValue(`${pageId}.${viewName}.${element?.props?.key}`, _.cloneDeep(mainRepeatedValue), { isDisabledDirtyField: true });
    }
  }, [repeatData?.length]);

  return watch(`${key}.state`)?.map?.((_item, itemIndex) => {
    let hiddenByStepper = !!element?.config?.stepperParent;
    if (element?.config?.stepperParent) {
      const componentStepperState = stepperGroupComponentState(
        `${element.props.key}.state[${itemIndex}].${element.props.key}`,
        stepperGroup
      );
      hiddenByStepper = componentStepperState !== StepperComponentState.FoundAndSelected;
    }

    return (
      <RenderChildren
        key={element?.id + "-" + itemIndex}
        handleSelectRow={handleSelectRow}
        {...restProps}
        element={element}
        currentRepeatedItem={repeatedData?.[itemIndex]}
        index={_item?.index ?? itemIndex}
        repeatedParentKey={element?.props?.key}
        hiddenByStepper={hiddenByStepper}
      />
    );
  });
};
