import { Box, FormHelperText, SxProps, Tooltip } from "@mui/material";
import { id } from "date-fns/locale";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import { FC, Fragment, useCallback, useRef } from "react";
import { BXEngine, KeysToComponentMap } from "src/BXEngine";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useAppState } from "src/features/appState/hooks";
import { validationRules } from "src/utils/BXValidate/validationRules";
import { getLastKeyFromObject } from "src/utils/generalUtils";
import { MUI_COMPONENTS } from "src/views/pages/BuildX/FormBuilder";
import { StripeContainer } from "src/views/pages/BuildX/FormBuilder/components/StripeComponents/StripeContainer";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";
import { mapValuesRecursively, resolveResponsiveProperties } from "src/views/pages/BuildX/FormBuilder/utils";
import { AppController } from "../AppController";
import { ActionButton, useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import ContainerChildren from "./ContainerChildren";
import OnTriggerHandler from "./OnTriggerHandler";
import { handleChange, handleClick, handleKeyDown } from "./RenderItems";
import { getControllerName, isElementParentFlex } from "./utils";

function getHierarchyName(elementKey, index, parentKey = "") {
  if (!_.isNil(index)) {
    return `${parentKey}.${elementKey}[${index}]`;
  }
  return `${parentKey}.${elementKey}`;
}
export const RenderChildren: FC<any> = ({ element: _element, index, dataEntry, viewName, hiddenByStepper, ...props }) => {
  let element = _.cloneDeep(_element);

  const {
    localViews,
    views,
    pageId,
    getValues,
    __data,
    parentIds,
    closeModal,
    info,
    item,
    currentApp,
    viewsState,
    queryKey,
    dataSource,
    disableInternalAction,
    onActionClick,
    componentData,
    setFormBuilderState,
    path,
    handleSelectRow,
    pageOutlet,
    multiLingual,
    disabled,
    parentKey,
    elements,
    stripe,
    currentRepeatedItem,
    repeatedParentKey,
  } = props;
  const metaData = {
    __data,
    item,
    queryKey,
    pageId,
    setFormBuilderState,
    path,
    viewName: info?.viewName,
  };
  const viewNameOption =
    dataSource?.sourceType !== "NONE"
      ? dataSource?.sourceType === "FROM PARENT"
        ? { viewName: viewName }
        : { viewName: info?.viewName }
      : { viewName: info?.viewName };
  const { leftDrawerOpened, setLeftDrawerOpened, currentBreakpoint } = useBXContext();

  const { replaceDataPlaceholdersRecursively, replaceDataPlaceholders } = useReplaceDataPlaceholders(viewNameOption);
  const { handleSubmit, watch } = useAppState();

  const queryKeys = [`${pageId}-${info?.viewName}-${element?.props?.key}`];

  const updateElementBasedOnMapKey = (element, item, viewsState, pageId, __data, currentAppEnv) => {
    if (element?.props?.isMapValues) {
      const mapKey = replaceDataPlaceholders({
        queryString: element?.config?.mapKey || "",
        item,
        viewsState,
        pageId,
        __data,
        env: currentAppEnv,
        fallback: "",
      });

      const condition = element?.config?.conditionMapKey || "equal";
      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.optionMap?.[mapKey];
      } else if (condition === "startWith") {
        const mapKeyStr = String(mapKey);
        const matchingKey = Object.keys(element?.optionMap || {}).find(key => mapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.optionMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...selectedOption,
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          }
        : {
            ...(element?.optionMap?.default || {}),
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          };
    } else {
      return _.cloneDeep(element);
    }
  };
  const dataSourceMapKey = replaceDataPlaceholders({
    queryString: element?.config?.dataSourceMapKey,
    item,
    viewsState,
    pageId,
    __data,
    env: currentApp?.env,
    fallback: "",
  });

  const updateDataSourceBasedOnMapKey = (element, item, viewsState, pageId, __data, currentAppEnv) => {
    if (element?.config?.isMapDataSource) {
      const dataSourceMapKey = replaceDataPlaceholders({
        queryString: element?.config?.dataSourceMapKey,
        item,
        viewsState,
        pageId,
        __data,
        env: currentAppEnv,
        fallback: "",
      });

      const condition = element?.config?.conditionDataSourceMap || "equal";

      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.dataSourceMap?.[dataSourceMapKey];
      } else if (condition === "startWith") {
        const dataSourceMapKeyStr = String(dataSourceMapKey);
        const matchingKey = Object.keys(element?.dataSourceMap || {}).find(key => dataSourceMapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.dataSourceMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...element,
            configData: { ...selectedOption },
          }
        : {
            ...element,
            configData: { ...element?.dataSourceMap?.default },
          };
    } else {
      return element;
    }
  };

  const visibilityState = (visibilityConfig, item, pageId, __data, currentAppEnv) => {
    try {
      if (!visibilityConfig?.visibilityCondition) return "visible";
      const data = replaceDataPlaceholders({
        queryString: visibilityConfig?.visibilityCondition,
        item,
        pageId,
        __data,
        env: currentAppEnv,
        index,
        fromParent: dataSource?.sourceType === "FROM PARENT",
        dataEntry,
      });
      const isConditionMet = eval(data);

      if (isConditionMet) {
        const visibilityType = visibilityConfig?.selectedVisibilityType || "Hidden";

        switch (visibilityType) {
          case "Hidden":
            return "hidden";
          case "None":
            return "none";
          default:
            return "visible";
        }
      }
    } catch (error) {}
    return "visible";
  };

  const isDisableConditionMet = (disableConfig, item, pageId, __data, currentAppEnv) => {
    try {
      if (!disableConfig?.disableCondition) return false;
      const data = replaceDataPlaceholders({
        queryString: disableConfig?.disableCondition,
        item,
        pageId,
        __data,
        env: currentAppEnv,
        index,
        fromParent: dataSource?.sourceType === "FROM PARENT",
        dataEntry,
      });
      const isConditionMet = eval(data);
      return !!isConditionMet;
    } catch (error) {
      return false;
    }
  };

  // const elementPropertiesWatcher = watch(`${pageId}.${info?.viewName}.${element?.props?.key}.props`);
  // const elementConfigWatcher = watch(`${pageId}.${info?.viewName}.${element?.props?.key}.config`);

  // let elementProperties = _.cloneDeep(elementPropertiesWatcher) || element.props;
  // let elementConfig = _.cloneDeep(elementConfigWatcher) || element.config;

  let elementProperties = element.props;
  let elementConfig = element.config;

  resolveResponsiveProperties(elementProperties, currentBreakpoint);
  resolveResponsiveProperties(elementConfig, currentBreakpoint);

  element.props = elementProperties;
  element.config = elementConfig;

  element = updateElementBasedOnMapKey(element, item, viewsState, pageId, __data, currentApp?.env);
  element = updateDataSourceBasedOnMapKey(element, item, viewsState, pageId, __data, currentApp?.env);

  const { refetchDataSource, fetchNextPage } = useValue({
    queryKeys,
    __data,
    viewId: id,
    limit: element?.configData?.paginationKey || 20,
    dataEntry: element?.configData?.dataEntry,
    pageId,
    options: {
      enabled: element?.configData?.sourceType == "API" || element?.configData?.sourceType == "LIST",
    },
    dataSource: element?.configData?.sourceType == "API" && element?.configData?.source,
    endpoint: element?.configData?.sourceType == "LIST" && element?.configData?.selectValues,
    viewName: info?.viewName,
    componentKey: element?.props?.key,
    path: path,
    dataSourceMapKey: dataSourceMapKey,
  });

  const ref = useRef(null);
  const handleLeftDrawerToggle = useCallback(() => {
    setLeftDrawerOpened(!leftDrawerOpened);
  }, [leftDrawerOpened, setLeftDrawerOpened]);

  const componentVisibility = visibilityState(element?.config?.visibility, item, pageId, __data, currentApp?.env);

  let isComponentDisabled =
    (isDisableConditionMet(element?.config?.disable, item, pageId, __data, currentApp?.env) && !element?.config?.disable?.withOverlay) ||
    element?.props?.disabled ||
    disabled;

  const overlay = element?.config?.disable?.withOverlay &&
    (isDisableConditionMet(element?.config?.disable, item, pageId, __data, currentApp?.env) || element?.props?.disabled) && (
      <Box
        sx={{
          backgroundColor: element?.config?.disable?.overlayColor || "#000",
          opacity: element?.config?.disable?.overlayOpacity || "0.5",
          position: "absolute",
          userSelect: "none",
          zIndex: 5,
          width: "100%",
          height: "100%",
        }}
      />
    );
  const viewRef = `${pageId}-${info?.viewName}`;
  const toggle = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.toggle;

  const displayRef = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.display ?? "none";
  let boxStyles: SxProps = {
    position: dataSource?.formBuilderConfig?.isDynamicHeight ? "unset" : "absolute",
    top: element?.top,
    left: element?.leftPercentage,
    width: element?.config?.isDynamicWidth
      ? "auto"
      : element?.config?.fixedWidth
      ? element?.config?.widthPx
      : element?.config?.widthPercentage,
    height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
    overflow: element?.config?.overflow || "visible",
    ...element?.config?.sx,
  };

  if (displayRef === "none") {
    (boxStyles as any).display = !toggle ? (element?.config?.hideElement ? "none" : "block") : "none";
  }

  if (displayRef === "hidden") {
    (boxStyles as any).visibility = !toggle ? (element?.config?.hideElement ? "hidden" : "visible") : "hidden";
  }

  if (
    [ComponentItemType.FlexContainer, ComponentItemType.GridContainer, ComponentItemType.StepperContainer].includes(
      element?.config?.parent?.type
    )
  ) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        ...(element?.config?.parent?.type === ComponentItemType.GridContainer && {
          width: "100%",
        }),
      },
      element?.config?.parentStyle
    )!;
  }

  if (isElementParentFlex(element, dataSource?.formBuilder)) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
        ...element?.config?.sx,
        boxSizing: "border-box",
      },
      element?.config?.parentStyle
    )!;
  }

  const view = localViews?.find((view: any) => view?.info?.name == componentData?.[element?.config?.customComponentId]?.name) || {};

  const replacePropsPlaceholders = replaceDataPlaceholdersRecursively({
    obj: _.cloneDeep(element?.props),
    item,
    viewsState,
    pageId,
    formData: getValues,
    __data,
    viewName: props?.info?.viewName,
    env: currentApp?.env,
    index,
    fallback: "",
    multiLingual: multiLingual,
    keyComp: element?.props?.key,
    dataEntry,
    fromParent: dataSource?.sourceType === "FROM PARENT",
  });

  const resolvedProps = mapValuesRecursively(replacePropsPlaceholders);
  const CheckToolTipParent = element?.actionConfig?.label || resolvedProps.toolTip?.value ? Tooltip : Fragment;
  const toolTipParentProps: any =
    element?.actionConfig?.label || resolvedProps?.toolTip ? { title: element?.actionConfig?.label || resolvedProps?.toolTip?.value } : {};

  const isContainer = [
    ComponentItemType.FlexContainer,
    ComponentItemType.CustomContainer,
    ComponentItemType.GridContainer,
    ComponentItemType.StepperContainer,
  ].includes(element.type);

  const viewKey: string = getControllerName(element, repeatedParentKey, index, pageId, info?.viewName);

  const Component = MUI_COMPONENTS[element?.type];
  const CustomComponent = KeysToComponentMap[element?.type];
  if (!Component && !CustomComponent) return <></>;
  if (componentVisibility === "none") {
    return <></>;
  }

  return (
    <PermissibleRender action={["VIEW"]} path={[path, element?.props?.key].join(".")}>
      {({ permitted }) =>
        permitted && (
          <>
            <ActionButton
              _key={element?.props?.key}
              path={[path, element?.props?.key].join(".")}
              queryKeys={queryKeys}
              pageId={pageId}
              viewName={info?.viewName}
              item={item}
              element={element}
              action={element?.actionConfig}
              interactionConfig={element?.interactionConfig}
              handleSubmit={handleSubmit}
              tableId={id}
              actionsMap={element?.actionsMap}
              views={views}
              iconButton={false}
              formData={getValues}
              __data={__data}
              closeModal={closeModal}
              parentIds={parentIds}
              disableInternalAction={disableInternalAction}
              onActionClick={onActionClick}
              disabled={!permitted}
              onSelectRow={handleSelectRow}
              index={index}
              isChainMapped={element?.config?.actionMappingConfig?.isChainMapped}
              actionsKey={element?.config?.actionMappingConfig?.actionsKey}
              isLoadingForEntireChain={element?.config?.actionMappingConfig?.isLoadingForEntireChain}
              stripe={stripe}
              elements={elements}
              currentRepeatedItem={currentRepeatedItem}
              fromParent={dataSource?.sourceType === "FROM PARENT"}
              dataEntry={dataEntry}
              viewNameOption={viewNameOption}
            >
              {({ actionHandlerMapper, isLoading }) => (
                <>
                  <OnTriggerHandler
                    element={element}
                    actionHandlerMapper={actionHandlerMapper}
                    pageId={pageId}
                    viewName={props?.info?.viewName}
                    viewsState={viewsState}
                    viewKey={viewKey}
                    refetchDataSource={refetchDataSource}
                    fetchNextPage={fetchNextPage}
                  />
                  <AppController
                    viewNameOption={viewNameOption}
                    element={element}
                    defaultValue={element?.props?.defaultValue}
                    replaceDataPlaceholdersProps={{
                      item,
                      viewsState,
                      pageId,
                      index,
                      __data,
                      env: currentApp?.env,
                      fallback: "",
                      multiLingual: multiLingual,
                      keyComp: element?.props?.key,
                      dataEntry,
                      fromParent: dataSource?.sourceType === "FROM PARENT",
                    }}
                    name={getControllerName({ ...element, props: resolvedProps }, repeatedParentKey, index, pageId, info?.viewName)}
                    isDisabledDirtyField={props?.isDisabledDirtyField || isContainer}
                    disabled={props?.disablePageDirty}
                    matchValidationField={replaceDataPlaceholders({
                      queryString: element?.props?.matchValue,
                      item,
                      viewsState,
                      pageId,
                      index,
                      __data,
                      env: currentApp?.env,
                      fallback: "",
                      multiLingual: multiLingual,
                    })}
                    validate={validationRules(element, value => {
                      return replaceDataPlaceholders({
                        queryString: value,
                        item,
                        viewsState,
                        pageId,
                        index,
                        __data,
                        env: currentApp?.env,
                        fallback: "",
                        multiLingual: multiLingual,
                      });
                    })}
                    render={({ onChange, value, error }) => (
                      <Box
                        sx={joinObjects(boxStyles, {
                          height: element?.config?.isDynamicHeight
                            ? "auto"
                            : [ComponentItemType.GridContainer].includes(element?.config?.parent?.type)
                            ? "100%"
                            : !element?.config?.isPercentageHeight
                            ? element?.config?.heightPx
                            : element?.config?.heightPercentage,
                          overflow: element?.config?.overflow || "visible",
                          ...((!leftDrawerOpened && element.type === ComponentItemType.BXSideBar) || hiddenByStepper
                            ? { display: "none" }
                            : {}),
                          contentVisibility: componentVisibility,
                          ...(element?.config?.transition?.enabled && {
                            transition: "all 0.5s ease",
                          }),
                          ...(element?.config?.sticky?.enabled
                            ? {
                                position: "sticky",
                                top: element?.config?.sticky?.top,
                                right: element?.config?.sticky?.right,
                                left: element?.config?.sticky?.left,
                                bottom: element?.config?.sticky?.bottom,
                                zIndex: element?.config?.sticky?.zIndex ?? 1200,
                              }
                            : {}),
                        })}
                        aria-invalid={!!error}
                      >
                        <CheckToolTipParent {...toolTipParentProps}>
                          <div style={{ position: "relative", width: "100%", height: "100%" }}>
                            {overlay}
                            {CustomComponent || element?.config?.BxComponent ? (
                              <BXEngine
                                path={
                                  element?.config?.BxComponent
                                    ? [path].filter(Boolean).join(".")
                                    : [path, view?.info?.name].filter(Boolean).join(".")
                                }
                                auth={{}}
                                page={
                                  element?.config?.BxComponent
                                    ? ({ views, layout: [{ id: element?.id, type: element?.type } as any], id: pageId } as any)
                                    : ({ views, layout: view, id: pageId } as any)
                                }
                                inputValidationName={`${info?.viewName || ""}.${element?.props?.key || ""}`}
                                getValues={getValues}
                                error={error}
                                componentName={
                                  !element?.config?.BxComponent ? null : componentData?.[element?.config?.customComponentId]?.name
                                }
                                componentData={
                                  element?.config?.BxComponent
                                    ? {
                                        ...element?.props,
                                        config: element?.configData,
                                        disableApis: false,
                                      }
                                    : componentData?.[element?.config?.customComponentId]
                                }
                                layout={
                                  element?.config?.BxComponent
                                    ? [{ id: element?.id, type: element?.type } as any]
                                    : [{ id: element?.id, type: element?.type } as any]
                                }
                                isVisible
                                disabled={isComponentDisabled}
                                __data={{
                                  ...__data,
                                  [(getLastKeyFromObject(__data) || "") + "#."]: info?.viewName,
                                }}
                                parentIds={[...parentIds, id]}
                                closeModal={closeModal}
                                info={info}
                              />
                            ) : (
                              <>
                                <Component
                                  {...resolvedProps}
                                  appId={currentApp?.id}
                                  config={element?.config}
                                  __config={element?.config}
                                  $views={views}
                                  $parentRef={ref}
                                  $configData={element?.configData}
                                  $pageOutlet={pageOutlet}
                                  $drawerOpen={leftDrawerOpened}
                                  $drawerToggle={handleLeftDrawerToggle}
                                  value={value}
                                  loading={element?.props?.enableLoading ? isLoading : false}
                                  disabled={isComponentDisabled}
                                  metaData={metaData}
                                  pageId={pageId}
                                  info={info}
                                  id={element?.props?.testId}
                                  renderId={"renderedView"}
                                  data-bx-key={`${pageId}.${props?.info?.viewName}.${element?.props?.key}`}
                                  data-testid={element?.props?.testId}
                                  onKeyDown={event =>
                                    handleKeyDown(event, element, actionHandlerMapper, pageId, props?.info?.viewName, viewsState)
                                  }
                                  onChange={(event: any, disabledDirty?: boolean) =>
                                    handleChange(
                                      event,
                                      element,
                                      onChange,
                                      actionHandlerMapper,
                                      pageId,
                                      props?.info?.viewName,
                                      viewsState,
                                      disabledDirty
                                    )
                                  }
                                  onClick={event => handleClick(event, element, pageId, props?.info?.viewName, viewsState)}
                                  children={
                                    isContainer ? (
                                      element?.config?.isStripeWrapper ? (
                                        <StripeContainer element={element}>
                                          <ContainerChildren
                                            props={props}
                                            data={element?.children}
                                            path={[path, element?.props?.key].join(".")}
                                            index={index}
                                            parentKey={getHierarchyName(element?.props?.key, index, parentKey)}
                                            disabled={isComponentDisabled}
                                            element={element}
                                            stripe={stripe}
                                            elements={elements}
                                            dataEntry={dataEntry}
                                            viewName={viewName}
                                          />
                                        </StripeContainer>
                                      ) : (
                                        <ContainerChildren
                                          props={props}
                                          data={element?.children}
                                          element={element}
                                          path={[path, element?.props?.key].join(".")}
                                          index={index}
                                          parentKey={getHierarchyName(element?.props?.key, index, parentKey)}
                                          disabled={isComponentDisabled}
                                          dataEntry={dataEntry}
                                          viewName={viewName}
                                        />
                                      )
                                    ) : (
                                      resolvedProps.children
                                    )
                                  }
                                  isLoadingImage
                                  error={!!error}
                                />

                                {!!error && !element?.props?.isChildContainerGroup && (element?.props?.showError ?? true) && (
                                  <FormHelperText error>
                                    {replaceDataPlaceholders({
                                      obj: _.cloneDeep(element?.props),
                                      queryString: error,
                                      validationRules: element?.props,
                                      item,
                                      viewsState,
                                      index,
                                      pageId,
                                      __data,
                                      env: currentApp?.env,
                                      fallback: "",
                                      multiLingual: multiLingual,
                                    })}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          </div>
                        </CheckToolTipParent>
                      </Box>
                    )}
                  />
                </>
              )}
            </ActionButton>
          </>
        )
      }
    </PermissibleRender>
  );
};
