import { useCallback } from "react";

// Custom hook to provide navigation functions
const useNavigationHandlers = () => {
  const openViewBuilderComponent = useCallback((appId, collectionId, pageId, viewId, componentId) => {
    const url = `form-builder/${appId}/${collectionId}/${pageId}/${viewId}/${componentId}`;
    window.open(url, "_blank");
  }, []);

  const openLayoutBuilderComponent = useCallback((appId, layoutId, componentId) => {
    const url = `/buildx/form-builder/layout/${appId}/${layoutId}/${componentId}`;
    window.open(url, "_blank");
  }, []);

  const openPageBuilderComponent = useCallback((appId, collectionId, componentId) => {
    const url = `/buildx/page-builder/${appId}/${collectionId}/${componentId}`;
    window.open(url, "_blank");
  }, []);

  const openCollectionModal = useCallback((appId, collectionId) => {
    const url = `/buildx/app?appIdModal=${appId}&collectionId=${collectionId}`;
    window.open(url, "_blank");
  }, []);

  const openPageModal = useCallback((appId, collectionId, pageId) => {
    const url = `/buildx/app?appIdModal=${appId}&collectionId=${collectionId}&pageId=${pageId}`;
    window.open(url, "_blank");
  }, []);

  const openViewModal = useCallback((appId, collectionId, pageId, viewId) => {
    const url = `/buildx/app?appIdModal=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}`;
    window.open(url, "_blank");
  }, []);

  const openTabModalComponent = useCallback((appId, collectionId, pageId, viewId, tabName) => {
    const url = `/buildx/app?appIdModal=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}&tab=${tabName}`;
    window.open(url, "_blank");
  }, []);

  const openNestedTabModal = useCallback((appId, collectionId, pageId, viewId, tabName, subTabName) => {
    const url = `/buildx/app?appIdModal=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}&tab=${tabName}&subTab=${subTabName}`;
    window.open(url, "_blank");
  }, []);

  const openSecondLevelModel = useCallback((appId, collectionId, pageId, viewId, tabName, rowConfigId) => {
    const url = `/buildx/app?appIdModal=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}&tab=${tabName}&rowConfig=${rowConfigId}`;
    window.open(url, "_blank");
  }, []);

  const openThirdLevelModalsModal = useCallback((appId, collectionId, pageId, viewId, tabName, rowConfigId, actionConfigId) => {
    const url = `/buildx/app?appIdModal=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}&tab=${tabName}&rowConfig=${rowConfigId}&actionConfig=${actionConfigId}`;
    window.open(url, "_blank");
  }, []);

  return {
    openViewBuilderComponent,
    openLayoutBuilderComponent,
    openPageBuilderComponent,
    openCollectionModal,
    openPageModal,
    openViewModal,
    openTabModalComponent,
    openNestedTabModal,
    openSecondLevelModel,
    openThirdLevelModalsModal,
  };
};

export default useNavigationHandlers;
