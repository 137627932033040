import _, { isObject } from "lodash";
import moment from "moment";
import { colorToRgbVariants, isUnixTimestamp, isValidDate } from "src/utils/generalUtils";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";
import { getSelectedPageInfo } from "src/views/pages/BuildX/FormBuilder/utils";

export const getControllerNameFallback = (element: any) => {
  switch (element?.type) {
    case ComponentItemType.CustomTabs: {
      return element?.config?.parent?.key;
    }
    default: {
      return "";
    }
  }
};

export const getControllerDefaultValue = (
  element: any,
  item?: any,
  viewsState?: any,
  pageId?: any,
  __data?: any,
  currentApp?: any,
  replaceDataPlaceholders?: any,
  replaceDataPlaceholdersRecursively?: any,
  componentValue?: any,
  skipResolving?: any
) => {
  if (element?.config?.controlledComponent && element?.props?.key) {
    let value = componentValue;

    if (!skipResolving) {
      if (!isObject(element?.props?.defaultValue)) {
        if (replaceDataPlaceholders) {
          value = replaceDataPlaceholders({
            queryString: element?.props?.defaultValue,
            item,
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            fallback: "",
          });
        } else {
          value = element?.props?.defaultValue;
        }
      } else {
        if (replaceDataPlaceholdersRecursively) {
          value = replaceDataPlaceholdersRecursively({
            obj: _.cloneDeep(element?.props?.defaultValue),
            item,
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            fallback: "",
          });
        } else {
          value = element?.props?.defaultValue;
        }
      }
    }

    // default value for color picker
    if (element?.type === ComponentItemType.ColorPicker) {
      value = colorToRgbVariants(value);
    }

    // default value for auto complete
    if (element?.type === ComponentItemType.CustomAutoCompleteBX) {
      value = { userOptions: { label: "", value: value, selectedData: null } };
    }

    //default value for date picker
    if (element?.type === ComponentItemType.DatePicker || element?.type === ComponentItemType.DateTimePicker) {
      const isUserLocalTime = element?.config?.isUserLocalTime;

      if (!value || !isValidDate(value)) {
        value = {
          utcISO: null,
          userLocalISO: null,
          epoch: null,
        };
      } else if (isUnixTimestamp(Number(value))) {
        const formattedUtcIso = moment(Number(value)).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        const date = new Date(Number(value));

        value = isUserLocalTime
          ? {
              utcISO: date.toISOString(),
              userLocalISO: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: date.valueOf(),
            }
          : {
              utcISO: formattedUtcIso,
              userLocalISO: moment(formattedUtcIso).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: moment(formattedUtcIso).valueOf(),
            };
      } else {
        let formattedUtcIso = moment.utc(value).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

        const date = new Date(value);

        value = isUserLocalTime
          ? {
              utcISO: date.toISOString(),
              userLocalISO: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: date.valueOf(),
            }
          : {
              utcISO: formattedUtcIso,
              userLocalISO: moment(formattedUtcIso).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              epoch: moment(formattedUtcIso).valueOf(),
            };
      }
    }

    // default for missing value according to the type of the component
    if (!value) {
      switch (element?.type) {
        case ComponentItemType.FileUploadInput:
          value = {
            key: null,
            url: null,
            file: null,
            ext: null,
          };
          break;
        case ComponentItemType.CustomSwitch:
          value = String(element?.props?.defaultValue) === "true" ? true : false;
          break;
        case ComponentItemType.BXSelect:
          value = element?.props?.defaultValue || element?.configData?.selectValues?.[0]?.value;
          break;
        case "formTable":
          value = [];
          break;
        case "keyValue":
          value = {};
          break;
        default:
          value = "";
          break;
      }
    }
    return value;
  }
};

export const getControllerName = (element: any, repeatedParent: any, index, pageId, viewName): string => {
  let componentKey;

  switch (element?.type) {
    case ComponentItemType.CustomRadio:
    case ComponentItemType.CustomCheckbox: {
      componentKey = element?.props?.groupName;
      break;
    }
    default: {
      componentKey = element?.props?.key;
      break;
    }
  }

  componentKey = componentKey || getControllerNameFallback(element);

  if (!_.isNil(index) && !_.isNil(repeatedParent)) {
    componentKey = `${repeatedParent}.state[${index}].${componentKey}`;
  }

  return `${pageId}.${viewName}.${componentKey}`;
};

export const getElementInit = (
  element: any,
  item: any,
  viewsState: any,
  pageId: any,
  __data: any,
  currentApp: any,
  replaceDataPlaceholders?: any,
  replaceDataPlaceholdersRecursively?: any
): any => {
  let childrenKeys: any = [];
  let parentsArray: any = [];
  let parentDirectKey = null;

  if (element?.config?.parent?.key) {
    parentDirectKey = element.config.parent.key;
  }

  const gatherParentKeys = (config: any) => {
    if (config?.parent?.key) {
      parentsArray.push(config.parent.key);
      gatherParentKeys(config.parent.config);
    }
  };

  if (element?.config?.parent) {
    gatherParentKeys(element.config);
  }

  if (element?.children && Array.isArray(element.children)) {
    childrenKeys = element.children.map((child: any) => child?.props?.key);
  }

  const elementKey = element?.props?.key;
  const defaultValue = getControllerDefaultValue(
    _.cloneDeep(element),
    item,
    viewsState,
    pageId,
    __data,
    currentApp,
    replaceDataPlaceholders,
    replaceDataPlaceholdersRecursively
  );

  let elementProps: any = {
    dirty: { isDirty: false, fields: [] as string[] },
    errors: { hasError: false, list: [], error: "" },
  };
  const isStepper = element?.type === ComponentItemType.StepperContainer;
  const stepperGroup = element?.config?.stepperGroup;
  if (isStepper && stepperGroup) {
    stepperGroup.selected = getSelectedPageInfo(stepperGroup.selectedPage, stepperGroup.pages);
  }
  if (elementKey) {
    elementProps = {
      ...elementProps,
      props: { ...element?.props },
      config: { ...element?.config },
      parent: parentDirectKey,
      parents: parentsArray,
      mainRepeated: element?.config?.repeated?.enabled,
      children: childrenKeys,
      state: element?.config?.repeated?.enabled ? [] : isStepper ? stepperGroup : defaultValue,
      initialValue: element?.config?.repeated?.enabled ? [] : isStepper ? stepperGroup : defaultValue,
      trigger: {
        type: "onLoad",
        eventPayload: {},
      },
    };
  }
  return elementProps;
};

export const buildFormBuilderInitialState = (
  formBuilder: any,
  pageId,
  viewName,
  item,
  viewsState,
  __data,
  currentApp,
  setStripeEndpoints: any,
  stripeEndpoints: any,
  replaceDataPlaceholders?: any,
  replaceDataPlaceholdersRecursively?: any
) => {
  let initialState = {};

  const buildInitialState = (formBuilder: any) => {
    if (formBuilder == 0) return;
    return formBuilder?.forEach((element: any) => {
      const elementProps = getElementInit(
        element,
        item,
        viewsState,
        pageId,
        __data,
        currentApp,
        replaceDataPlaceholders,
        replaceDataPlaceholdersRecursively
      );
      const elementKey = element?.props?.key;
      _.set(initialState, [elementKey], elementProps);

      if (element?.id && stripeEndpoints) {
        const updatedEndpoints = [...stripeEndpoints];

        const publishableSource = element?.props?.stripePublishableEndpoint?.source;
        const secretSource = element?.props?.stripeSecretEndpoint?.source;

        const existingEndpoint = updatedEndpoints.find(ep => ep.id === element?.id);

        if (publishableSource || secretSource) {
          const newEndpoint = {
            id: element?.id,
            publishableKeyEndpoint: publishableSource || (existingEndpoint?.publishableKeyEndpoint ?? null),
            clientSecretKeyEndpoint: secretSource || (existingEndpoint?.clientSecretKeyEndpoint ?? null),
          };

          if (existingEndpoint) {
            existingEndpoint.publishableKeyEndpoint = newEndpoint.publishableKeyEndpoint;
            existingEndpoint.clientSecretKeyEndpoint = newEndpoint.clientSecretKeyEndpoint;
          } else {
            updatedEndpoints.push(newEndpoint);
          }
        }

        setStripeEndpoints(updatedEndpoints);
      }
      if (element?.props?.isMapValues && element?.optionMap) {
        const componentChildren = Object.keys(element?.optionMap).map(key => {
          return element?.optionMap[key];
        });
        return buildInitialState(componentChildren);
      }
      if (!element?.config?.repeated?.enabled) {
        return buildInitialState(element?.children);
      }
    });
  };

  buildInitialState(formBuilder);
  return initialState;
};

export function isElementParentFlex(element, formBuilder): boolean {
  if (!formBuilder) return false;
  const findParent = (item, id) => {
    if (Array.isArray(item)) {
      for (let topLevelComp of item) {
        const res = findParent(topLevelComp, id);
        if (res) {
          return res;
        }
      }
    }

    if (item.id === id) {
      return item;
    }

    if (item.children) {
      for (let child of item.children) {
        const res = findParent(child, id);
        if (res) {
          return res;
        }
      }
    }
  };

  const parent = findParent(formBuilder, element.parentId);

  return parent?.type === ComponentItemType.FlexContainer || parent?.type === ComponentItemType.StepperContainer;
}
