import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useAppState } from "src/features/appState/hooks";
import { useAppTrigger } from "src/features/appState/selectors";
import { ElementBaseProps } from "src/types/UIElement";
import { UserInput } from "../UserInput";
import { RenderItems } from "./RenderItems";
import { buildFormBuilderInitialState } from "./utils";

type FormBuilderProps = {
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
    disableDirtyOption?: boolean;
  };
  views: any;
  __data?: any;
  pageId?: string;
  path?: string;
} & ElementBaseProps;

const FormBuilder: FC<FormBuilderProps> = props => {
  const {
    id = "",
    height,
    dataSource,
    info,
    views,
    __data = {},
    closeModal,
    parentIds = [],
    toolTip,
    pageId,
    disableInternalAction,
    onActionClick,
    data,
    path,
    handleSelectRow,
    pageOutlet,
    index,
    disablePageDirty,
    disabled,
    dataEntry: _dataEntry,
    viewName,
  } = props;

  const [formBuilderState, setFormBuilderState] = useState(dataSource?.formBuilder);
  const [currentActionEndpoint, setCurrentActionEndpoint] = useState("");
  const [dataEntry, setDataEntry] = useState("");
  const localViews = views?.filter((view: any) => view.id != id);
  const userInputView = views?.find((view: any) => view?.id === dataSource?.userInputFormBuilderId);
  const selectedDataEntry = dataSource?.sourceType == "USER INPUT" ? dataEntry : dataSource?.dataEntry;
  const { getValue: _getValues, setValue, watch, cleanTrigger } = useAppState();
  const { viewsState, currentApp, multiLingual, stripeEndpoints, setStripeEndpoints } = useBXContext();
  const firstLoad = useRef(true);
  const queryKeys = [`${pageId}-${info?.viewName}`];

  const {
    data: apiData,
    isFetching,
    refetchDataSource,
    fetchNextPage,
  } = useValue({
    queryKeys,
    __data,
    viewId: id,
    limit: dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    dataEntry: selectedDataEntry,
    pageId,
    endpoint: currentActionEndpoint,
    options: {
      enabled: dataSource?.sourceType == "API" || dataSource?.sourceType == "SIMPLE",
    },
    dataSource: dataSource,
    viewName: info?.viewName,
    path: path,
  });

  const viewKey: string = `${pageId}.${info?.viewName}`;
  const trigger = useAppTrigger(viewKey);

  useEffect(() => {
    // Perform an action when the event/signal/trigger is triggered and brooked
    const handleTrigger = async () => {
      if (trigger?.type === "refetch") {
        await refetchDataSource?.();
        trigger?.payload?.resolver?.();
        cleanTrigger(viewKey);
      }

      if (trigger?.type === "fetchNextPage") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        await fetchNextPage?.(cursor, true);
        trigger?.payload?.resolver?.();
        cleanTrigger(viewKey);
      }
    };

    handleTrigger();
  }, [trigger, viewKey]);

  const item = dataSource?.sourceType != "NONE" ? (selectedDataEntry ? _.get(apiData, selectedDataEntry as any) : apiData) : data;

  let code = {};
  try {
    code = JSON.parse(dataSource?.template);
  } catch (e) {}

  const findCustomComponentIds = (obj: any) => {
    const isCustomComponent = !!obj?.config?.customComponent;
    const hasChildren = obj?.children?.length > 0;

    if (hasChildren) {
      const childrenIds = obj?.children.flatMap(findCustomComponentIds).filter(Boolean);
      return isCustomComponent ? [obj?.config?.customComponentId, ...childrenIds] : childrenIds;
    }

    return isCustomComponent ? [obj?.config?.customComponentId] : [];
  };

  const customComponents = dataSource?.formBuilder?.map(findCustomComponentIds);
  const customComponentIds = _.flattenDeep(customComponents);

  const { data: componentData, isLoading } = useQuery(
    [["components", id]],
    async ({ pageParam }) => {
      const { data } = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${customComponentIds?.join(",")}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      const ids: any = {};
      data?.items?.forEach((item: any) => {
        ids[item?.id] = item;
      });
      return ids;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!customComponentIds?.length,
    }
  );
  useEffect(() => {
    if ((!isFetching && dataSource?.sourceType === "API" && item) || dataSource?.sourceType === "NONE" || !dataSource?.sourceType) {
      let initialState = buildFormBuilderInitialState(
        dataSource?.formBuilder,
        pageId,
        info?.viewName,
        item,
        viewsState,
        __data,
        currentApp,
        setStripeEndpoints,
        stripeEndpoints
      );

      const vars = dataSource?.varsConfig?.vars;
      vars?.forEach(varItem => {
        if (varItem?.varKey?.trim() !== "") {
          _.set(initialState, `vars.${varItem?.varKey}`, varItem?.value);
        }
      });
      const viewChildren = dataSource?.formBuilder?.reduce((acc, child) => {
        if (child?.props?.key) {
          return [...acc, child?.props?.key];
        }
        return acc;
      }, []);
      const mergedState = _.merge({ children: viewChildren, ...initialState }, watch(`${pageId}.${info?.viewName}`));
      if (pageId && info?.viewName) {
        setValue(`${pageId}.${info?.viewName}`, mergedState, { isDisabledDirtyField: true });
        setValue(`${pageId}.${info?.viewName}.dirty`, { isDirty: false, fields: [] as string[] }, { isDisabledDirtyField: true });
        setValue(`${pageId}.${info?.viewName}.errors`, { hasError: false, list: [], error: "" }, { isDisabledDirtyField: true });
      }
      if (pageId) {
        setValue(`${pageId}.dirty`, { isDirty: false, fields: [] as string[] }, { isDisabledDirtyField: true });
        setValue(`${pageId}.errors`, { hasError: false, list: [], error: "" }, { isDisabledDirtyField: true });
      }
    }
  }, [pageId, isFetching]);

  useEffect(() => {
    if (dataSource?.enablePageLoading && firstLoad.current) {
      if (isFetching) {
        setValue(`${pageId}.isLoading`, true, { isDisabledDirtyField: true });
        setValue(`${pageId}.${info?.viewName}.isLoading`, true, { isDisabledDirtyField: true });
      } else {
        firstLoad.current = false;
        setValue(`${pageId}.isLoading`, false, { isDisabledDirtyField: true });
        setValue(`${pageId}.${info?.viewName}.isLoading`, false, { isDisabledDirtyField: true });
      }
    }
  }, [isFetching]);

  const getValues = (path?: string) => {
    return _getValues(`${pageId}.${info?.viewName}${path ? `.${path}.` : ""}.state`);
  };

  if (isLoading) return <CircularProgress />;

  if (isFetching && dataSource?.enablePageLoading && firstLoad.current) {
    return <Box width={"100%"} height={"100vh"} />;
  }

  return (
    <PermissibleRender isAllowed path={path} action={["VIEW"]}>
      {({ permitted }) => {
        return (
          permitted && (
            <>
              {dataSource?.sourceType == "USER INPUT" && (
                <Box width='100%'>
                  <UserInput
                    queryKeys={queryKeys}
                    pageId={pageId}
                    setCurrentActionEndpoint={setCurrentActionEndpoint}
                    userInputView={userInputView}
                    __data={__data}
                    refetch={refetchDataSource}
                    views={views}
                    setDataEntry={setDataEntry}
                    viewName={info?.viewName}
                  />
                </Box>
              )}
              <Box
                sx={{
                  position: "relative",
                  height: dataSource?.formBuilderConfig?.isCanvasFullHeight
                    ? "100%"
                    : dataSource?.formBuilderConfig?.isDynamicHeight
                    ? "auto"
                    : height,
                  boxSizing: "border-box",
                }}
              >
                <RenderItems
                  data={formBuilderState}
                  setFormBuilderState={setFormBuilderState}
                  localViews={localViews}
                  views={views}
                  pageId={pageId}
                  parentKey={`${pageId}.${info?.viewName}`}
                  index={index}
                  getValues={getValues}
                  __data={__data}
                  parentIds={parentIds}
                  closeModal={closeModal}
                  toolTip={toolTip}
                  info={info}
                  item={item}
                  currentApp={currentApp}
                  multiLingual={multiLingual}
                  viewsState={viewsState}
                  queryKeys={queryKeys}
                  dataSource={dataSource}
                  dataEntry={_dataEntry}
                  viewName={viewName}
                  disableInternalAction={disableInternalAction}
                  onActionClick={onActionClick}
                  componentData={componentData}
                  path={path}
                  handleSelectRow={handleSelectRow}
                  pageOutlet={pageOutlet}
                  disabled={disabled}
                  isDisabledDirtyField={info?.disableDirtyOption}
                  disablePageDirty={disablePageDirty}
                />
              </Box>
            </>
          )
        );
      }}
    </PermissibleRender>
  );
};

export default FormBuilder;
