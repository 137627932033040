import { useTheme } from "@mui/material";
import {
  IconApi,
  IconEdit,
  IconForms,
  IconLayoutGrid,
  IconPlus,
  IconSettings,
  IconTable,
  IconTrashX,
  IconUpload,
} from "@tabler/icons-react";
import update from "immutability-helper";
import _, { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import PositionedMenu from "src/components/BXUI/FormControls/Menu";
import BXModal from "src/components/BXUI/Modal";
import { viewIcon, viewTitle } from "src/stores/constant";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { UIElement } from "src/types/UIElement";
import { v4 as uuid } from "uuid";
import { changeIdsOfObject } from "../";
import { ReactComponent as IconGrid4x4 } from "../../../../../assets/svgs/grid-4x4.svg";
import { ReactComponent as IconTools } from "../../../../../assets/svgs/tools.svg";
import { CreateViewForm } from "../forms/CreateViewForm";
import BuilderColumn from "./BuilderColumn";

export const checkIfIdExist = (data: any, idsToCheck: any, existData = [] as any) => {
  if (_.isObject(data) as any) {
    if (idsToCheck?.includes?.(data?.id)) {
      existData.push(data?.id);
    }

    _.forOwn(data, (value, key) => {
      if (_.isArray(value)) {
        value?.forEach((item, index) => {
          if (_.isObject(item) as any) {
            checkIfIdExist(data[key][index], idsToCheck, existData);
          }
        });
      } else if (_.isObject(value)) {
        checkIfIdExist(data[key], idsToCheck, existData);
      }
    });
    return existData;
  }
};

const getObjectIds = (view: any, views: any, ids = [] as any) => {
  if (_.isObject(view) as any) {
    // check if the id is for view
    const isView = views.find((_view: any) => _view?.id === view?.id);
    const filterViews = views.filter((_view: any) => _view?.id != isView?.id && !ids?.includes(_view?.id));

    if (isView) {
      ids.push(view?.id);

      getObjectIds(isView, filterViews, ids);
    }

    _.forOwn(view, (value, key) => {
      if (_.isArray(value)) {
        value?.forEach((item, index) => {
          if (_.isObject(item) as any) {
            getObjectIds(view[key][index], filterViews, ids);
          }
        });
      } else if (_.isObject(value)) {
        getObjectIds(view[key], filterViews, ids);
      }
    });
    return ids;
  }
};

type Props = {
  app?: BXApp;
  collection?: BXAppCollection;
  page?: BXPageType;
  selectedData?: any;
  onSelect: (data: any) => void;
};

let isUpdateApps = true;

const ViewColumn = (props: Props) => {
  const { app, collection, page } = props;
  const { apps, addView, editPage, deleteView, editView, addViews, deleteViews } = useBXBuilderContext();

  const [views, setViews] = useState(page?.views);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<any>(null);
  const [isTrash, setIsTrash] = useState(false);
  const [view, setView] = useState<UIElement>();
  const [addingMode, setAddingMode] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const appId = searchParams.get("appId");
  const collectionId = searchParams.get("collectionId");
  const pageId = searchParams.get("pageId");
  const viewId = searchParams.get("viewId");
  const isPageBuilder = searchParams.get("pageBuilder");
  const popoverMode = Number(searchParams.get("popoverMode"));
  const { currentApp, loadApps } = useBXContext();

  useEffect(() => {
    if (apps?.length && appId && collectionId && pageId && viewId) {
      const app = apps?.find(app => app?.id === appId);
      const collection = app?.templateConfig?.collections?.find(collection => collection?.id == collectionId);
      const page = collection?.pages?.find(page => page?.id === pageId);
      const view = page?.views?.find(view => view?.id === viewId);
      // @ts-ignore
      setSelectedIcon(viewTypesIcons[view?.type]);
      setView(view);
      if (popoverMode !== 1) {
        setIsEdit(true);
      }
      navigate(`/buildx/app`);
    } else if (apps?.length && appId && collectionId && pageId && !isPageBuilder) {
      navigate(`/buildx/app`);
      // @ts-ignore
      setAddingMode(true);
      if (popoverMode !== 1) {
        setIsEdit(true);
      }
      setSelectedIcon(<IconTools />);
      const payload = {
        id: uuid(),
        type: "form-builder",
        children: [],
      };
      setView(payload);
    }
  }, [loadApps]);

  const viewTypesIcons = {
    "data-table": <IconTable />,
    form: <IconForms />,
    upload: <IconUpload />,
    api: <IconApi />,
    "card-list": <IconLayoutGrid />,
    "image-grid": <IconLayoutGrid />,
    "form-builder": <IconTools />,
    "repeated-view": <IconGrid4x4 />,
  };

  useEffect(() => {
    if (!isUpdateApps) isUpdateApps = true;
    else setViews(page?.views);
  }, [page?.views]);

  const moveCard = (dragIndex: any, hoverIndex: number) => {
    return setViews(prev => {
      const _views = update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev?.[dragIndex]!],
        ],
      });

      return _views;
    });
  };

  const updateCardDebounce = useCallback(
    debounce((app, collection, _page, views) => {
      isUpdateApps = false;
      editPage(app?.id!, collection?.id!, _page?.id!, { ..._page, views });
    }, 800),
    []
  );

  const updateCard = () => {
    updateCardDebounce(app, collection, page, views);
  };

  const handleEditClick = (value: any) => {
    // @ts-ignore
    setSelectedIcon(viewTypesIcons[value?.type]);
    setIsEdit(true);
    setView(value);
  };
  const handleTrashClick = (value: any) => {
    setIsTrash(true);
    setView(value);
  };

  const handleViewBuilderClick = (data: any) => {
    navigate(`/buildx/form-builder/${app?.id}/${collection?.id}/${page?.id}/${data?.id}?popoverMode=1`);
  };

  const onSubmit = (data: any, openBuilder?: boolean) => {
    if (addingMode) {
      setAddingMode(false);
      addView(app?.id!, collection?.id!, page?.id!, data, () => {
        if (openBuilder) {
          navigate(`/buildx/form-builder/${app?.id}/${collection?.id}/${page?.id}/${view?.id}`);
        }
      });
    } else {
      editView?.(app?.id!, collection?.id!, page?.id!, view?.id!, data, () => {
        if (openBuilder) {
          navigate(`/buildx/form-builder/${app?.id}/${collection?.id}/${page?.id}/${view?.id}`);
        }
      });
    }
  };

  const handleDuplicateClick = (value: any) => {
    const view = { ...value, info: { ...value.info, name: value?.info?.name + " copy", viewName: value?.info?.viewName + "-copy" } };

    const oldIds = {} as any;
    view?.config?.actions?.forEach((action: any) => {
      const id = action?.actionSourceType?.id;
      if (id) {
        oldIds[id] = id;
      }
    });
    const changedTemplateConfigIds = changeIdsOfObject(_.cloneDeep(view), oldIds);

    addView(app?.id!, collection?.id!, page?.id!, changedTemplateConfigIds);
  };

  const data = views?.map((item: UIElement) => ({
    ...item,
    title: item?.info?.name || viewTitle(item?.type),
    subTitle: "",
    image: item?.icon,
    fallBack: viewIcon(item?.type),
  }));

  const handleMoveClick = (handleClose: any, appId: any, collectionId: any, pageId: any, item: any) => {
    const moveView = async (doesUsedFromOtherView?: any) => {
      handleClose?.();

      const newItem = changeIdsOfObject(_.cloneDeep(item));

      if (doesUsedFromOtherView) {
        return addView(appId, collectionId, pageId, newItem);
      }

      addView(appId, collectionId, pageId, newItem, () => {
        deleteView(app?.id!, collection?.id!, page?.id!, item?.id);
      });
    };

    let confirmationText = "";
    let doesUsedFromOtherView = false;
    let hasDependencies = false;
    let hasDependenciesWithCopy: any[] = [];

    // check if the view used as a dependency from other views
    const viewsWithoutCurrent = views?.filter(view => view?.id != item?.id);
    const result = checkIfIdExist(_.cloneDeep(viewsWithoutCurrent), [item?.id]);

    // check if the view has dependencies
    const viewDependencyIds = getObjectIds(_.cloneDeep(item), _.cloneDeep(viewsWithoutCurrent));

    if (result?.length) {
      doesUsedFromOtherView = true;

      confirmationText = `The view has been used as dependency from other views (${result?.length}) time${
        result?.length > 1 ? "s" : ""
      }, Do you want to copy and move the view`;
    }

    if (viewDependencyIds?.length) {
      const remainingViews = viewsWithoutCurrent?.filter((_view: any) => !viewDependencyIds.includes(_view?.id));
      const remainingViewsDependencies = remainingViews
        ?.map(view => {
          const array = checkIfIdExist(_.cloneDeep(view), viewDependencyIds);
          return array?.length ? array : null;
        })
        .filter(Boolean);

      hasDependencies = true;
      if (remainingViewsDependencies?.length) {
        hasDependenciesWithCopy = _.uniqBy(_.flatten(remainingViewsDependencies), d => d);

        confirmationText = doesUsedFromOtherView
          ? `The view has been used as dependency from other views (${result?.length}) time${
              result?.length > 1 ? "s" : ""
            } and it has common dependencies, Do you want to copy and move the views`
          : `The view has common dependencies, Do you want to copy and move the views`;
      } else {
        confirmationText = doesUsedFromOtherView
          ? `The view has been used as dependency from other views (${result?.length}) time${
              result?.length > 1 ? "s" : ""
            } and it has dependencies, Do you want to copy and move the view with its dependencies?`
          : "Do you want to move the view with its dependencies?";
      }
    }

    if (!doesUsedFromOtherView && !hasDependencies) {
      moveView();
      // eslint-disable-next-line no-restricted-globals
    } else if (confirm(confirmationText)) {
      if (hasDependencies) {
        let viewsIds = [...viewDependencyIds, item?.id];
        const viewsToAdd = views?.filter(view => viewsIds?.includes(view.id)) || [];

        if (doesUsedFromOtherView) {
          viewsIds = viewsIds?.filter(viewId => viewId != item?.id);
        }

        if (hasDependenciesWithCopy?.length) {
          viewsIds = viewsIds?.filter(viewId => !hasDependenciesWithCopy.includes(viewId));
        }

        const newViewsToAdd = changeIdsOfObject(_.cloneDeep(viewsToAdd));

        addViews(appId, collectionId, pageId, newViewsToAdd as any, () => {
          deleteViews(app?.id!, collection?.id!, page?.id!, viewsIds);
        });

        handleClose?.();
      } else {
        moveView(doesUsedFromOtherView);
      }
    }
  };

  const tables = views?.filter(view => view?.type == "data-table");

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const collectionId = urlParams.get("collectionId");
    const pageId = urlParams.get("pageId");
    const viewId = urlParams.get("viewId");
    const tabName = urlParams.get("tab");

    if (collectionId && (page?.views?.length ?? 0) > 0 && !closeModal && pageId && (viewId || tabName)) {
      const matchedView = page?.views?.find(view => view?.id === viewId);
      if (matchedView) {
        setView(matchedView);
        setIsEdit(true);
      }
    }
  }, [page?.views, search]);

  return (
    <>
      {app?.id && collection?.id && page?.id && view?.id && (
        <>
          <BXConfirmationDialog
            open={isTrash}
            title={"Are you sure you want to delete this item?"}
            iconButton
            buttonProps={{ color: "error", children: <IconTrashX /> }}
            onConfirm={() => {
              deleteView?.(app.id, collection.id, page.id, view.id);
              setIsTrash(false);
            }}
            onCancel={() => setIsTrash(false)}
          />

          <BXModal
            open={isEdit}
            title={`${app?.name} > ${collection?.name} > ${page?.name} ${view?.info?.name ? `> ${view?.info?.name}` : ""}`}
            icon={selectedIcon || <IconSettings />}
            buttonProps={{ startIcon: <IconEdit />, color: "secondary", size: "small" }}
            minWidth={600}
            maxWidth='lg'
            onClose={() => {
              setIsEdit(false);
              setAddingMode(false);
              setCloseModal(true);
            }}
            isDirty={isDirty}
          >
            {(handleClose: Function) => {
              return (
                <CreateViewForm
                  id={view?.id}
                  appId={app?.id}
                  collectionId={collection?.id}
                  pageId={page?.id}
                  viewId={view?.id}
                  view={view}
                  layout={page?.layout}
                  tables={tables!}
                  views={views}
                  onCancel={() => {
                    handleClose(false, () => {
                      setIsEdit(false);
                    });
                  }}
                  onSave={(data: any, openBuilder: any) => {
                    onSubmit?.(data, openBuilder);
                    setIsEdit(false);
                    handleClose?.(true);
                  }}
                  setIsDirty={setIsDirty}
                  app={app}
                  closeModal={closeModal}
                />
              );
            }}
          </BXModal>
        </>
      )}
      <BuilderColumn
        id={page?.id}
        headerName={"Views"}
        rows={data}
        type={"view"}
        withMove
        moveApp={moveCard}
        updateApp={updateCard}
        onEditClick={handleEditClick}
        onViewBuilderClick={handleViewBuilderClick}
        onTrashClick={handleTrashClick}
        onMoveClick={handleMoveClick}
        onDuplicateClick={handleDuplicateClick}
        selectedData={props.selectedData}
        onSelect={props.onSelect}
        modal={
          <PositionedMenu
            items={[
              {
                icon: <IconGrid4x4 />,
                label: "Custom Grid",
                action: () => {
                  setAddingMode(true);
                  setIsEdit(true);
                  setSelectedIcon(<IconGrid4x4 />);

                  const payload = {
                    id: uuid(),
                    type: "repeated-view",
                    children: [],
                  };
                  setView(payload);
                },
              },
              ...(process.env.REACT_APP_WITH_FORM_BUILDER == "true"
                ? [
                    {
                      isDivider: true,
                    },
                    {
                      icon: <IconTools />,
                      label: "View Builder",
                      action: () => {
                        setAddingMode(true);
                        setIsEdit(true);
                        setSelectedIcon(<IconTools />);
                        const payload = {
                          id: uuid(),
                          type: "form-builder",
                          children: [],
                        };
                        setView(payload);
                      },
                    },
                  ]
                : []),
              {
                isDivider: true,
              },
              {
                icon: <IconTable />,
                label: "Table",
                action: () => {
                  setAddingMode(true);
                  setIsEdit(true);
                  setSelectedIcon(<IconTable />);

                  const payload = {
                    id: uuid(),
                    type: "data-table",
                    children: [],
                  };
                  setView(payload);
                },
              },
              {
                isDivider: true,
              },
              // {
              //   icon: <IconForms />,
              //   label: "Form",
              //   action: () => {
              //     setAddingMode(true);
              //     setIsEdit(true);
              //     setSelectedIcon(<IconForms />);

              //     const payload = {
              //       id: uuid(),
              //       type: "form",
              //       children: [],
              //     };
              //     setView(payload);
              //   },
              // },
              // {
              //   isDivider: true,
              // },
              {
                icon: <IconUpload />,
                label: "Upload",
                action: () => {
                  setAddingMode(true);
                  setIsEdit(true);
                  setSelectedIcon(<IconUpload />);

                  const payload = {
                    id: uuid(),
                    type: "upload",
                    children: [],
                  };
                  setView(payload);
                },
              },
              {
                isDivider: true,
              },
              {
                icon: <IconApi />,
                label: "REST API",
                action: () => {
                  setAddingMode(true);
                  setIsEdit(true);
                  setSelectedIcon(<IconApi />);

                  const payload = {
                    id: uuid(),
                    type: "api",
                    children: [],
                  };
                  setView(payload);
                },
              },
            ]}
            buttonProps={{
              variant: "contained",
              style: { backgroundColor: palette.primary.main, borderRadius: 24 },
              startIcon: <IconPlus />,
              fullWidth: true,
              disabled: !page?.id,
            }}
          >
            View
          </PositionedMenu>
        }
      />
    </>
  );
};

export default ViewColumn;
